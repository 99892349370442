import axios from 'axios';

let base_url = "";
if(process.env.REACT_APP_NODE_ENV === "prod") {
    base_url = process.env.REACT_APP_BASE_URL
} else base_url = process.env.REACT_APP_BASE_URL_TEST

const axiosCall = axios.create({
    baseURL: base_url
})


export default axiosCall;