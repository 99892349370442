import 'antd/dist/antd.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../assets/css/shop.css';

import { notification } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopHeader from '../assets/images/banner-sm.jpeg';
import ShopBanner1 from '../assets/images/shop/banner_web.webp';
import MobileBanner2 from '../assets/images/strip2.png';
import Jwt_decrypt from '../common/Jwt_decrypt';
import axios from '../common/axios_call';
import { _all_product, _get_cms_page, _get_search_product } from '../common/axios_services';
import AllProducts from '../components/AllProducts';
import Category from '../components/Category';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Recommended from '../components/Recommended';
import { encrypted_token, fetchData, profileData } from '../slices/profileSlice';

const Shop = () => {
    let params = new URLSearchParams(window?.location?.search);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [categoryData, setCategoryData] = React.useState([])
    const [allImages, setAllImages] = React.useState(false)
    const [products, setProducts] = React.useState([])
    const token = useSelector(state => state.userProfile.token)

    let jwt_token = localStorage.getItem('konnect_token')
    let searchParam = params.get('search')
    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottom-right"
        });
    };

    const validate_token = async ({ token }) => {
        axios(`/auth/${token}/confirm-email`).then((res) => {
            navigate('/signin');
        }).catch(err => {
            if (err?.response?.data) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon("error", "Something went wrong", err?.message)
            }
        })
    }
    const getAllProducts = async () => {
        try {
            const products = await _all_product({ page: "1", per_page: "14", search: "" })
            setProducts(products.data.data)
        } catch (err) {
            console.log(err);
        }
    }
    const getAllImages = async () => {
        try {
            const images = await _get_cms_page({ page: "home" })
            setAllImages(images.data.data)
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        getAllImages()
    }, [])

    React.useEffect(() => {
        getAllProducts();
        const url = new URL(window.location.href);

        let verify_user = url.searchParams.get('token')

        if (verify_user) {
            validate_token({ token: verify_user })
        }
        if (jwt_token) {
            const decrypted = Jwt_decrypt({ token: jwt_token })
            dispatch(encrypted_token(decrypted))
        }

        axios('/auth/profile', { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
            if (res.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(res.data.data))
            }
        }).catch(err => {
            dispatch(fetchData(false))
        })
        axios('/category/no_pagination', { headers: { "Authorization": `Bearer ${process.env.REACT_APP_FAKE_TOKEN}` } }).then(res => {
            setCategoryData(res.data.data)
        }).catch(err => {
            console.log(err)
        })
    }, [dispatch, token, jwt_token])

    const findImageObj = ({ page }) => {
        return allImages.find(obj => obj["name"] === page)
    }

    return (
        <div>
            {/* Hero Section */}
            <Navbar noShadow={true} adjustNavSize={true} showSubNav={true} showCart={true}  />
            {!searchParam &&
                <div className="shop-hero">
                    <div className='desktop show-all' style={{ width: "100%", height: "60vh" }}>
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", background: "#0B3356" }} >
                            {allImages ?
                                <img src={findImageObj({ page: "hero image" })?.content} style={{ height: '100%' }} alt="create free account" /> :
                                <img src="" style={{ height: '100%' }} alt="create free account" />
                            }
                        </div>
                    </div>
                    <div className='mobile' style={{ width: "100%", height: "80vh" }}>
                        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <img src={ShopHeader} style={{ width: '100%', height: '100%' }} alt="create free account" />
                        </div>
                    </div>
                </div>
            }
            +
            {/* Category Section */}
            {/* <div div className="promo" >
                <div>
                    <Category categoryData={categoryData} param={'Shop by category'} />
                </div>
            </div > */}
            {!searchParam &&
                <>
                    {/* Trending Products */}
                    <div className="contain">
                        <div className='desktop show-all'>
                            {allImages ?
                                <img src={findImageObj({ page: "W9j Ads Banner" })?.content} alt="extra cash banner" className="banner" /> :
                                <img src="" alt="extra cash banner" className="banner" />
                            }
                        </div>
                        <div className='mobile'>
                            {allImages ?
                                <img src={findImageObj({ page: "W9j Ads Banner" })?.content} alt="extra cash banner" className="banner" /> :
                                <img src={ShopBanner1} alt="extra cash banner" className="banner cash" />
                            }
                        </div>
                    </div>
                    <div className="products-list">
                        <Recommended products={products} param={'Trending products'} />
                    </div>
                    <div className="banner">
                        <div className="contain">
                            <div className='desktop'>
                                {allImages ?
                                    <img src={findImageObj({ page: "Del Ads Banner" })?.content} alt="extra cash banner" className="banner" /> :
                                    <img src="" alt="extra cash banner" className="banner" />
                                }
                            </div>
                            <div className='mobile'>
                                {allImages ?
                                    <img src={findImageObj({ page: "Del Ads Banner" })?.content} alt="extra cash banner" className="banner" /> :
                                    <img src={MobileBanner2} alt="extra cash banner" className="banner" />
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="products-list">
                {searchParam ?
                    <AllProducts param={`Searched ${searchParam}`} />
                    :
                    <AllProducts param={'All products '} />
                }
            </div>
            <Footer />

        </div >
    )
}

export default Shop