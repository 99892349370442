import { Button, Card, Col, Row } from "antd"

import Footer from "../../components/Footer"
import Navbar from "../../components/Navbar"
import RestaurantSlider from "../../components/Restaurant/Slider"
import sideimage_1 from '../../assets/images/homepage/restaurant_side_image1.png';
import restaurant_food from '../../assets/images/homepage/restaurant_food.png';

const RestaurantConstruction = () => {
    return (
        <div style={{ backgroundColor: '#F2F2F2' }}>
            <Navbar noShadow={true} adjustNavSize={true} />
            {/* Slider */}
            <RestaurantSlider />

            {/* section 2 */}
            <div className='restaurant_banner2 restaurant_card'>
                {/* create 3 cards that wraps */}
                <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col xs={24} sm={12} md={8}>
                        <Card bordered={false} style={{ border: "2px solid black", borderRadius: "8px", textAlign: 'center', backgroundColor: '#FDF4D7', fontWeight: "bold", fontSize: "18px", padding: "10px" }}>
                            Continental
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Card bordered={false} style={{ border: "2px solid black", borderRadius: "8px", textAlign: 'center', backgroundColor: '#E9FBF5', fontWeight: "bold", fontSize: "18px", padding: "10px" }}>
                            African
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Card bordered={false} style={{ border: "2px solid black", borderRadius: "8px", textAlign: 'center', backgroundColor: '#F8E1E1', fontWeight: "bold", fontSize: "18px", padding: "10px" }}>
                            Buka
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* section 3 */}
            <div className='restaurant_banner3, restaurant_card'>
                {/* create 2 cards that wraps */}
                <Row gutter={[32, 32]} style={{ width: "100%", display: 'flex', alignItems: 'stretch' }}>
                    <Col xs={24} sm={12} md={12} style={{ display: 'flex' }}>
                        <Card bordered={false} style={{ border: "2px solid black", borderRadius: "32px", backgroundColor: '#FDF4D7', display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', height: '100%', margin: "auto" }}>
                                <div style={{ flex: 1, display: 'flex', alignItems: 'stretch', padding: '15px' }}>
                                    <img src={sideimage_1} alt="side image" style={{ width: '100%', borderRadius: '16px 0 0 16px', objectFit: 'cover' }} />
                                </div>
                                <div style={{ flex: 2, padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h1 style={{ fontSize: '2.5rem' }}>Delivery in no time!</h1>
                                    <p style={{ fontSize: '14px', color: '#959595' }}>No delays, no waste of time!</p>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} style={{ display: 'flex' }}>
                        <Card bordered={false} style={{ border: "2px solid black", borderRadius: "32px", backgroundColor: '#D3ECDC', display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', height: '100%' }}>
                                <div style={{ flex: 1, display: 'flex', alignItems: 'stretch', padding: '15px' }}>
                                    <img src={sideimage_1} alt="side image" style={{ width: '100%', borderRadius: '16px 0 0 16px', objectFit: 'cover' }} />
                                </div>
                                <div style={{ flex: 2, padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <h1 style={{ fontSize: '2.5rem' }}>Refer friends and earn cash monthly</h1>
                                    <p style={{ fontSize: '14px', color: '#959595' }}>Refer your friends to order from us and earn cash monthly for food</p>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* section 4 */}
            <div className="main_restaurant_container">
                <div className='restaurant-banner'>
                    <div className="align-center" style={{ marginBottom: "3rem" }}>
                        <div>
                            <h1 style={{ margin: "0px", fontSize: '3.5rem' }}>Just for you!</h1>
                            <p style={{ fontSize: '1.5rem', fontWeight: "medium", color: "#959595" }}>Based on your frequent orders</p>
                        </div>
                        <Button type="link">View all</Button>
                    </div>
                    <Row gutter={[32, 32]} style={{ width: "100%", display: 'flex', alignItems: 'stretch', margin: "0px" }}>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card'>
                                <img src={restaurant_food} alt="Restaurant 1" />
                                <div className="align-center">
                                    <h3>Kippy’s Place</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>₦500 30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card'>
                                <img src={restaurant_food} alt="Restaurant 2" />
                                <div className="align-center">
                                    <h3>Amala Buka</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>₦500 30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                            <Card className='restaurant-card'>
                                <img src={restaurant_food} alt="Restaurant 3" />
                                <div className="align-center">
                                    <h3>Nimb’s Restaurant</h3>
                                    <span>❤️</span>
                                </div>
                                <div className="align-center">
                                    <p style={{ fontSize: "12px" }}>₦500 30-50min</p>
                                    <span>⭐ 4.5</span>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RestaurantConstruction