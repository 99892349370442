import '../assets/css/landing.css';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Button, InputNumber, notification } from 'antd';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import SideImage from '../assets/images/landing/4.png';
import { _general_settings_by_id, _rate_setting_by_id } from '../common/axios_services';

const Vendor = () => {
    const [saCashSo, setSaCashSo] = useState(false)
    const [userCashFromSo, setUserCashFromSo] = useState(false)
    const [saCashUserLogin, setSaCashUserLogin] = useState(false)
    const [salesAssistantPer, setSalesAssistantPer] = useState(false)
    const [referFoodVendorVal, setReferFoodVendorVal] = useState(0)
    const [referFoodVendorVal2, setReferFoodVendorVal2] = useState(0)
    const [ancAverMonIncoMon, setAncAverMonIncoMon] = useState(0)
    const [ancAverMonIncoMon2, setAncAverMonIncoMon2] = useState(0)
    const [venRCustomer, setVenRCustomer] = useState(0)
    const [averMonInco, setAverMonInco] = useState(0)

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : "",
            description: message ? message : "",
            placement: "bottom-right"
        });
    };

    const saCashLoginSO = async () => {
        setSaCashSo(false)
        try {
            const sa_cash_so = await _general_settings_by_id({ id: "4" })
            setSaCashSo(sa_cash_so?.data?.data?.value)
        } catch (err) {
            setSaCashSo(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const soCashLogin = async () => {
        setSaCashUserLogin(false)
        try {
            const so_cash_login = await _general_settings_by_id({ id: "8" })
            setSaCashUserLogin(so_cash_login?.data?.data?.app_value)
        } catch (err) {
            setSaCashUserLogin(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const userCashSO = async () => {
        setUserCashFromSo(false)
        try {
            const user_cash_from_so = await _general_settings_by_id({ id: "12" })
            setUserCashFromSo(user_cash_from_so?.data?.data?.app_value)
        } catch (err) {
            setUserCashFromSo(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const salesAssistantl2 = async () => {
        setSalesAssistantPer(false)
        try {
            const sales_assistant_per = await _rate_setting_by_id({ id: "10" })
            setSalesAssistantPer(sales_assistant_per?.data?.data?.level_b_value)
        } catch (err) {
            setSalesAssistantPer(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        saCashLoginSO()
        userCashSO()
        soCashLogin()
        salesAssistantl2()
    }, [])

    const handleLevel1 = (value) => {
        let level = "1"
        ReferFoodVendor(value, level)
    }
    const handleLevel2 = (value) => {
        let level = "2"
        ReferFoodVendor(value, level)
    }
    const ReferFoodVendor = (value, level) => {
        if (saCashSo && level === "1") {
            setReferFoodVendorVal(+saCashSo * +value)
        } else if (saCashSo && level === "2") {
            setReferFoodVendorVal2(+saCashSo * +value)
        }
    }
    const VenRCus = (value) => {
        setVenRCustomer((+saCashUserLogin + +userCashFromSo) * +value)
    }
    const AveMonInc = (value) => {
        if (salesAssistantPer) {
            setAverMonInco((+salesAssistantPer / 100) * 3000000 * +value)
        }
    }
    const AncAveMonIncMon = (value) => {
        if (salesAssistantPer) {
            setAncAverMonIncoMon((+salesAssistantPer / 100) * 3000000 * +value)
        }
    }
    const AncAveMonIncMon2 = (value) => {
        if (salesAssistantPer) {
            setAncAverMonIncoMon2(((+salesAssistantPer / 100) * 3000000 * +value) * 0.5)
        }
    }
    return (
        <div>
            <Navbar />
            <div className='landing_hero' style={{ width: "100%", backgroundColor: "#f1fef4", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0 2%" }} className='grid_flex'>
                    <div>
                        <h3 style={{ color: "rgba(0,0,0,.85)", fontSize: "4.5rem", fontFamily: "Gilroy Bold", marginBottom: "2rem" }}> JOIN US AS A SUPPORT MANAGER & EARN WEEKLY</h3>
                    </div>
                    <div style={{ display: "block" }}>
                        <img className='sideImg' src={SideImage} alt="" style={{ margin: "0 auto", display: "block" }} />
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 style={{ textAlign: "center" }}>How Support Manager can earn weekly</h4>
                        <div className='list_container'>
                            <ul>
                                <li>Get up to NGN 1,000 when you sign-up a food vendor</li>
                                <li>Earn average of NGN 150,000 weekly as sales commission</li>
                                <li>Plus lots of other benfits</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 style={{ textAlign: "center" }}>Calculate what you can earn monthly</h4>
                        <p className='sub_title'>What you get monthly when you refer food vendors</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={handleLevel1} placeholder='XXX Food vendor' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={referFoodVendorVal} displayType={'text'} thousandSeparator={true} /></p>
                        </div>
                        <i>Note we may assign established Food Vendors to Support Manager</i>
                        <div className='divider'></div>
                        <p className='sub_title'>Your average monthly sales income</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={AveMonInc} placeholder='XXX Food vendor' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={averMonInco} displayType={'text'} thousandSeparator={true} /></p>
                        </div>
                        <i>Calculation is based on food vendors that cooks average of 7 bags of rice (50kg) weekly</i>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 style={{ textAlign: "center" }}>Check out what a Food Vendor can earn monthly</h4>
                        <p className='sub_title'>What Anchor Food Vendor gets for referring other Food Vendors</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={handleLevel2} placeholder='XXX Food vendor' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={referFoodVendorVal2} displayType={'text'} thousandSeparator={true} /></p>
                        </div>

                        <div className='divider'></div>
                        <p className='sub_title'>What anchor food vendor gets as <b>sales commission (1st month)</b></p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={AncAveMonIncMon} placeholder='XXX Food vendor' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={ancAverMonIncoMon} displayType={'text'} thousandSeparator={true} /></p>
                        </div>

                        <div className='divider'></div>
                        <p className='sub_title'>What anchor food vendor gets as <b>monthly as sales commission</b></p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={AncAveMonIncMon2} placeholder='XXX Food vendor' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={ancAverMonIncoMon2} displayType={'text'} thousandSeparator={true} /></p>
                        </div>

                        <div className='divider'></div>
                        <p className='sub_title'>What Food Vendor can gets for referring their customers</p>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <InputNumber onChange={VenRCus} placeholder='XXX Customer' className='divider1' />
                            <p className='divider2' style={{ textAlign: "right" }}>= NGN <NumberFormat value={venRCustomer} displayType={'text'} thousandSeparator={true} /></p>
                        </div>
                        <i>Customer sign-up via Food Vendor referral code & get cash to buy food</i>
                        <i style={{ color: "red" }}><Link to="/vendor">Click here for more</Link></i>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 style={{ textAlign: "center", color: "red" }}><b>NOTE TO JOIN US AS A SUPPORT MANAGER, SIGN-IN VIA THE MOBILE APP</b></h4>
                    </div>
                </div>
            </div>
            <Footer noSpace={true} />
        </div>
    )
}


export default Vendor;
