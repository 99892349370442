import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const Wise9jaSuccess = () => {
    const [wise9jaPayments, setWise9jaPayments] = useState();
    useEffect(() => {
        if (localStorage.getItem('konnect_wise9ja')) {
            setWise9jaPayments(JSON.parse(localStorage.getItem('konnect_wise9ja')));
            localStorage.removeItem('konnect_wise9ja');
        }
    }, [])
    return (
        <>
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form aut center-text wise9ja-success">
                            <h3>{wise9jaPayments?.title}</h3>
                            <p>{wise9jaPayments?.message}</p>
                            <div style={{ marginBottom: '20px' }}></div>
                            <div className="">
                                <Link to="/profile/loyalty">See Transaction History</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages">
                        <div className="div_bg_white">
                            <div className="form aut center-text wise9ja-success">
                                <h3>{wise9jaPayments?.title}</h3>
                                <p>{wise9jaPayments?.message}</p>
                                <div style={{ marginBottom: '20px' }}></div>
                                <div className="">
                                    <Link to="/profile/loyalty">See Transaction History</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Wise9jaSuccess;