import { CheckCircleFilled, LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Drawer, Empty, Form, Input, Modal, Radio, Result, Select, Space, Spin, Tag, Timeline, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { _balance_up, _change_order_status, _get_order, _get_profile, _get_sales_order, _order_status_change } from '../../common/axios_services';
import Navbar from '../../components/Navbar';
import { fetchData, profileData } from '../../slices/profileSlice';

const SalesOrder = () => {
    const param = useParams();
    const dispatch = useDispatch()
    const { Panel } = Collapse;
    const [order, setOrder] = useState(false)
    const navigate = useNavigate()
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight',
            message: title ? title : '',
            description: message ? message : "",
        });
    };

    const getOrder = async () => {
        try {
            const recent_invoice = await _get_sales_order({ trxt_ref: param.id })
            setOrder(recent_invoice?.data?.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            dispatch(profileData(user_profile.data.data))
            dispatch(fetchData(true))
        } catch (err) {
            dispatch(profileData(false))
            dispatch(fetchData(false))
            if (err.response) {
                navigate("/signin?redir=review")
                localStorage.removeItem('konnect_token')
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        getProfile();
        getOrder();
    }, [])

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <div className="form-pages">
            <Spin spinning={!order} indicator={newAntIcon}>
                <Navbar />
                {order &&
                    (
                        <div className="cart">
                            <div>
                                <div className="new_checkout_data contain">
                                    <div className="mt-5 cart-design-body">
                                        <div>
                                            {/* <Link to="/profile/order_history"> */}
                                            <LeftCircleOutlined onClick={() => handleBackClick()} style={{ fontSize: "30px", color: "black", marginBottom: "20px" }} />
                                            {/* </Link> */}
                                            <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="start">
                                                <Panel header="DELIVERY ADDRESS" key="2" extra={
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                                        <CheckCircleFilled />
                                                    </div>
                                                }>
                                                    {order?.delivery_location ?
                                                        <div className='d-flex flex-column'>
                                                            <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_opt} Delivery</span><br />
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_location?.Address}</span>
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_location?.city?.city_name}, {order?.delivery_location?.state} State</span>
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className='d-flex flex-column'>
                                                            <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                <span>No delivery address selected</span>
                                                            </span>
                                                        </div>
                                                    }
                                                </Panel>
                                                <Panel header="Tracking Status" key="3" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} ><CheckCircleFilled /></div>}>
                                                    <div className='d-flex flex-column'>
                                                        <Timeline>
                                                            <Timeline.Item color="gray">Order Requested - {order?.created_at.split('T')[0]}</Timeline.Item>
                                                            <Timeline.Item color="yellow">Order In Progress - {order?.created_at.split('T')[0]}</Timeline.Item>
                                                            <Timeline.Item color="green">Delivery Date - {order?.delivery_date}</Timeline.Item>
                                                        </Timeline>
                                                    </div>
                                                </Panel>
                                                <Panel header="ORDER DETAILS" key="1" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} > <CheckCircleFilled /> </div>}>
                                                    {/* <div className="desktop mt-5 mb-5"> */}
                                                    <div>
                                                        <div>
                                                            {order?.items?.length ?
                                                                <div className="">
                                                                    <div className="cart-design-tab new_cart">
                                                                        {order?.items.map((product, index) => (
                                                                            <div key={index}>
                                                                                <div className={`${order?.items.length - index === 1 ? 'no-border' : ''} cart-body-detail`}>
                                                                                    <div className="inner-flex new_cart">
                                                                                        <div className="cart_product_image">
                                                                                            <img src={product.product_image} alt="product" />
                                                                                        </div>
                                                                                        <div className="cart_product_text">
                                                                                            <div className="inner-flex new_cart">
                                                                                                <div>
                                                                                                    <h4>{product.product_name}</h4>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className="cart_product_description">
                                                                                                <span className="small_currency">NGN</span>
                                                                                                <NumberFormat value={product.product_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} /> each
                                                                                            </p>
                                                                                            <div className="mobile">
                                                                                                <div className="mobile-flex-cover">
                                                                                                    <div className="mobile-flex">
                                                                                                        <Select defaultValue={product.quantity}
                                                                                                            disabled
                                                                                                            className="select-quantity" style={{ width: 80 }}>
                                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                                        </Select>
                                                                                                        <p>
                                                                                                            <span className="small_currency">NGN</span>
                                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="small-flex">
                                                                                        <Select defaultValue={product.quantity}
                                                                                            disabled
                                                                                            className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                        </Select>
                                                                                    </div>
                                                                                    <div className="small-flex">
                                                                                        <p>
                                                                                            <span className="small_currency">NGN</span>
                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="empty-cart">
                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                        description={
                                                                            <span>
                                                                                You have no products in cart yet? <br />
                                                                                <Link to="/shop">Go to shop</Link>
                                                                            </span>
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </Panel>
                                            </Collapse>
                                        </div>
                                        <div>
                                            <div className="cart-brief">
                                                <div className="cart-brief-data">
                                                    <div>
                                                        <ul className="first-list">
                                                            <li>
                                                                <span className="first">Payment status </span>
                                                                <span className="second"><div style={{ textTransform: "capitalize" }}>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</div></span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Order status </span>
                                                                <span className="second"><div style={{ textTransform: "capitalize" }}>{order?.status === "success" ? <Tag color="green">{order?.status}</Tag> : order?.status === "cancel" || order?.status === "ccanceled" ? <Tag color="red">{order?.status}</Tag> : <Tag color="orange">{order?.status}</Tag>}</div></span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Order size </span>
                                                                <span className="second">{Number(order?.total_weight).toFixed(2)} kg</span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Order quantity </span>
                                                                <span className="second">{Number(order?.total_qty).toFixed(2)} Item(s)</span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Subtotal </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={(order?.sub_amount).toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Delivery Fee </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.delivery_fee.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Coupon</span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.coupon_amount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Discount</span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.discount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Total </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.transaction?.total_amount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            {order?.payment_opt === "pay_on_delivery" && <>
                                                                <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                <li>
                                                                    <span className="first">Paid</span>
                                                                    <span className="second">
                                                                        <span className="small_currency">NGN </span>
                                                                        <NumberFormat prefix="" value={Number(order?.transaction?.total_amount_paid).toFixed(2)} className="foo"
                                                                            displayType={'text'} thousandSeparator={true} />
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="first">Balance</span>
                                                                    <span className="second">
                                                                        <span className="small_currency">NGN </span>
                                                                        <NumberFormat prefix="" value={Number(order?.transaction?.balance).toFixed(2)} className="foo"
                                                                            displayType={'text'} thousandSeparator={true} />
                                                                    </span>
                                                                </li>
                                                            </>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Spin >
        </div >
    )
}

export default SalesOrder