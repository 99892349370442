import { Button, Collapse, DatePicker, Divider, Modal, Pagination, Spin, Table, Tabs, Tooltip, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import ProfileNav from '../../components/dashboard/side_nav';

import { InfoCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Wallet from '../../assets/images/icons/cil_wallet.png';
import GiftCard from '../../assets/images/icons/gift_card.png';
import TablerCash from '../../assets/images/icons/tabler_cash.png';
import Wise9ja from '../../assets/images/icons/wise9ja.png';
import { _check_ref, _get_all_referrals, _get_all_sa_so, _get_all_sl_sa, _get_bonus_history, _get_profile, _get_sa_report, _invite_recuit, _sales_matrix, _update_so_nod, _user_dashboard_referrals } from '../../common/axios_services';
import InfoModal from '../../components/InfoModal';
import { fetchData, profileData } from '../../slices/profileSlice';

const Sl = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.userProfile.dataFetched);

    const [total_so_users, setTotal_so_users] = useState(0);
    const [total_sa_users, setTotal_sa_users] = useState(0);
    const [saPage, setSaPage] = useState(1);
    const [updateCount, setUpdateCount] = useState(0);
    const [sa_page_size, setSa_Page_size] = useState(10);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [allSaFetched, setAllSaFetched] = useState(false);
    const [allSoFetched, setAllSoFetched] = useState(false);
    const [allSo, setAllSo] = useState([]);
    const [allSa, setAllSa] = useState([]);
    const [loadingSummary, setLoadingSummary] = useState(true);
    const [nextOD, setNextOD] = useState(false);
    const [selectedSo, setSelectedSo] = useState("");
    const [salesMatrix, setSalesMatrix] = useState({});
    const [selectedDate, setSelectedDate] = useState(selectedSo?.nod || null);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', desc: '' });

    const openModal = (title, desc) => {
        setModalContent({ title, desc });
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    };
    const soColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Name of Food Vendor',
            dataIndex: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: 'Next order date',
            dataIndex: 'nod',
        },
        {
            title: 'Order (NGN)',
            className: 'column-number',
            dataIndex: 'orderN'
        },
        {
            title: 'Pending income (NGN)',
            className: 'column-number',
            dataIndex: 'pendingIncomeN'
        },
        {
            title: 'My income (NGN)',
            className: 'column-number',
            dataIndex: 'incomeN'
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action'
        },
    ];
    const saColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Sales Agents',
            dataIndex: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'phone',
        },
        {
            title: 'Sales outlet',
            dataIndex: 'so_no',
        },
        {
            title: 'Order (NGN)',
            className: 'column-number',
            dataIndex: 'orderN'
        },
        {
            title: 'My income (NGN)',
            className: 'column-number',
            dataIndex: 'incomeN'
        },
        // {
        //     title: 'Action',
        //     className: 'column-number',
        //     dataIndex: 'action'
        // },
    ];

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottom-right',
            message: title ? title : '',
            description: message ? message : "",
        });
    };
    const getAllSlSa = async () => {
        setAllSaFetched(true);
        try {
            const all_sl_so = await _get_all_sl_sa({ page, page_size, search: "", from: "", to: moment().format('YYYY-MM-DD') });
            setAllSa(all_sl_so?.data?.data);
            setTotal_sa_users(all_sl_so?.data?.meta?.total);
            setAllSaFetched(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
            setAllSaFetched(false);
        }
    }
    const getAllSaSo = async () => {
        setAllSoFetched(true);
        try {
            const all_so = await _get_all_sa_so({ page, page_size, search: "", from: "", to: moment().format('YYYY-MM-DD') });
            setAllSo(all_so?.data?.data);
            setTotal_so_users(all_so?.data?.meta?.total);
            setAllSoFetched(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
            setAllSoFetched(false);
        }
    }
    const getSalesMatrix = async () => {
        setLoadingSummary(true);
        try {
            const matrix = await _sales_matrix();
            setLoadingSummary(false);
            setSalesMatrix(matrix.data.data);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllSoFetched(false);
        setAllSo([]);
    }
    const change_sa_page = (selected_page, pageSize) => {
        setSaPage(selected_page);
        setSa_Page_size(pageSize);
        setAllSaFetched(false);
        setAllSa([]);
    }
    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            dispatch(profileData(profile.data.data))
            if (!profile?.data?.data?.is_sl && !profile?.data?.data?.is_sa) {
                navigate('/profile/loyalty')
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                    localStorage.removeItem('konnect_token')
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        getSalesMatrix();
        getProfile()
    }, [])

    useEffect(() => {
        if (userProfile?.is_sl) {
            getAllSlSa()
        }
        if (userProfile?.is_sa) {
            getAllSaSo()
        }
    }, [page, page_size, saPage, sa_page_size, updateCount])

    let so_data = allSo.map((sales, index) => {
        return {
            key: index,
            sn: index + 1,
            date: new Date(sales.timestamp).toISOString().split('T')[0],
            name: `${sales?.fullname}`,
            phone: `${sales.phone_number}`,
            sa: sales.sa_no,
            address: sales?.new_user_address,
            nod: sales?.next_order_date,
            so: sales.so_no,
            order: sales.so_order_no,
            orderN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_amount).toFixed(2)} />,
            incomeN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_income).toFixed(2)} />,
            pendingIncomeN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.pending_order_income).toFixed(2)} />,
            action: <p><Link to={`/sales/${sales?.so_user_id}`}>View</Link> | <Button type="link" onClick={() => { setNextOD(true); setSelectedSo(sales) }}>Update</Button></p>
        }
    });
    let sa_data = allSa.map((sales, index) => {
        return {
            key: index,
            sn: index + 1,
            date: new Date(sales.timestamp).toISOString().split('T')[0],
            name: `${sales?.fullname}`,
            phone: `${sales.phone_number}`,
            so_no: sales.so_no,
            orderN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_amount).toFixed(2)} />,
            incomeN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_income).toFixed(2)} />,
        }
    });

    const handleOk = async () => {
        setConfirmLoading(true);
        try {
            const update_so_nod = await _update_so_nod({ id: selectedSo?.id, selectedDate: selectedDate ? selectedDate.format('YYYY-MM-DD') : null })
            setConfirmLoading(false);
            openNotificationWithIcon('success', update_so_nod?.data?.message)
            setNextOD(false)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    return (
        <div className="userprofile">
            <Spin spinning={loadingSummary}>
                <div className="profile-content">
                    <Navbar />
                    <div className="gray_bg_profile">
                        <div className="profile_to_left">
                            <div className="">
                                <div className="profile_nav">
                                    <ProfileNav />
                                </div>
                            </div>
                        </div>
                        <div className="profile_to_right">
                            <div className="desktop show-all">
                                <div className="contain">
                                    <div className="main_container">
                                        <div className="main_container_title_div">
                                            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: "5px" }}>
                                                <h5>Sales {userProfile?.is_sl && !userProfile?.is_sa ? "Lead Dashboard" : userProfile?.is_sa && !userProfile?.is_sl ? "Assistant Dashboard" : userProfile?.is_sa && userProfile?.is_sl ? "Lead And Sales Assistant (SL & SA)" : ""} </h5>
                                            </div>
                                            <Divider style={{ margin: '0px 0 5px' }} />
                                        </div>
                                        <div className="main_container_inner_div pb-2">
                                            <div className="profile_container">
                                                <div className="slider_div_to_top referral-data">
                                                    <Slider {...settings}>
                                                        {userProfile?.is_sl &&
                                                            <div key={1} className="item grid-4-box colored color_1">
                                                                <div className="slick-div">
                                                                    <div className="slick-box">
                                                                        <div className="icon-box">
                                                                            <img src={Wallet} alt="tabler cash" />
                                                                        </div>
                                                                        <div className="slider-main-content">
                                                                            <p>Sales Assistant</p>
                                                                            <h2>{loadingSummary ? '0' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.sa_no)} />}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div key={2} className="item grid-4-box colored color_2" onClick={() => openModal('Food Vendor', 'List of assigned or referred food vendors.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Food Vendor</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2>{loadingSummary ? '0' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_no)} />}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(userProfile?.is_sa && !userProfile?.is_sl) &&
                                                            <div key={3} className="item grid-4-box colored color_2" onClick={() => openModal('Order', 'Numbers of orders made by your assigned food vendors.')}>
                                                                <div className="slick-div">
                                                                    <div className="slick-box">
                                                                        <div className="icon-box">
                                                                            <img src={TablerCash} alt="tabler cash" />
                                                                        </div>
                                                                        <div className="slider-main-content">
                                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                                <p>Order</p>
                                                                                <Tooltip title='Click the box for more info'>
                                                                                    <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <h2>{loadingSummary ? '0' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_order_no)} />}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div key={4} className="item grid-4-box colored color_3" onClick={() => openModal('Order (NGN)', 'Sum of orders made by your assigned food vendors.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={Wise9ja} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Order</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(salesMatrix.so_order_amount).toFixed(2)} />}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={5} className="item grid-4-box colored color_5" onClick={() => openModal('My income', 'Cash earned from successful orders made by your assigned food vendors.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={GiftCard} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>My Income</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(salesMatrix.so_order_income).toFixed(2)} />}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile px-3 referal-info">
                                <div>
                                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", marginTop: "15px", marginBottom: "15px" }}>
                                        <h5 className="profile_mobile_title" style={{ marginTop: "0px", marginBottom: "0px", fontSize: "1.7rem", marginRight: "10px" }}>Sales {userProfile?.is_sl && !userProfile?.is_sa ? "Lead Dashboard" : userProfile?.is_sa && !userProfile?.is_sl ? "Assistant Dashboard" : userProfile?.is_sa && userProfile?.is_sl ? "Lead And Sales Assistant (SL & SA)" : ""} </h5>
                                    </div>
                                    <div className="slider_div_to_top">
                                        <Slider {...settings}>
                                            {userProfile?.is_sl &&
                                                <div key={6} className="item grid-4-box colored color_1" >
                                                    <div className="slick-div">
                                                        <div className="slick-box">
                                                            <div className="icon-box">
                                                                <img src={Wallet} alt="tabler cash" />
                                                            </div>
                                                            <div className="slider-main-content">
                                                                <p>Sales Assistant</p>
                                                                <h2>{loadingSummary ? '-' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.sa_no)} />}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div key={7} className="item grid-4-box colored color_2" onClick={() => openModal('Food Vendor', 'List of assigned or referred food vendors.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={TablerCash} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>Food Vendor</p>
                                                                <Tooltip title='ClickTooltip the box for more info'>
                                                                    <InfoCircleOutlined styleInfoCircleOutlined={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2>{loadingSummary ? '0' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_no)} />}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {(userProfile?.is_sa && !userProfile?.is_sl) &&
                                                <div key={8} className="item grid-4-box colored color_1" onClick={() => openModal('Order', 'Numbers of orders made by your assigned food vendors.')}>
                                                    <div className="slick-div">
                                                        <div className="slick-box">
                                                            <div className="icon-box">
                                                                <img src={Wallet} alt="tabler cash" />
                                                            </div>
                                                            <div className="slider-main-content">
                                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                    <p>Order</p>
                                                                    <Tooltip titleTooltip='Click the box for more info'>
                                                                        <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                    </Tooltip>
                                                                </div>
                                                                <h2>{loadingSummary ? '-' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_order_no).toFixed(2)} />}</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div key={9} className="item grid-4-box colored color_3" onClick={() => openModal('Order (NGN)', 'Sum of orders made by your assigned food vendors.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={Wise9ja} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>Order NGN</p>
                                                                <Tooltip title='TooltipClick the box for more info'>
                                                                    <InfoCircleOutlined styleInfoCircleOutlined={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2>{loadingSummary ? '-' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_order_amount).toFixed(2)} />}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={10} className="item grid-4-box colored color_5" onClick={() => openModal('My income', 'Cash earned from successful orders made by your assigned food vendors.')}>
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={GiftCard} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                <p>My Income NGN</p>
                                                                <Tooltip title='ClickTooltip the box for more info'>
                                                                    <InfoCircleOutlined styleInfoCircleOutlined={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                </Tooltip>
                                                            </div>
                                                            <h2><span className="currency">NGN</span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true} displayType={'text'} value={+(salesMatrix.so_order_income)} />}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className="contain">
                                <Tabs type='card'>
                                    {userProfile?.is_sl &&
                                        <Tabs.TabPane tab="Sales Assistant List" key="1">
                                            <div className="px-4 table-responsive">
                                                {!allSaFetched ?
                                                    <>
                                                        <div className="desktop show-all">
                                                            <div className="show-all">
                                                                <Table columns={saColumns} pagination={false} dataSource={sa_data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={saPage} total={total_sa_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={sa_page_size} onChange={change_sa_page} />
                                                            </div>
                                                        </div>
                                                        <div className="mobile">
                                                            <Collapse>
                                                                {
                                                                    allSa.map((sales, index) => (
                                                                        <Collapse.Panel showArrow={false} key={index}
                                                                            header={<p style={{ display: 'inline' }}>{sales.fullname} on {new Date(sales.timestamp).toISOString().split('T')[0]}</p>}>
                                                                            <div>
                                                                                <ul className="mobile-list-flex">
                                                                                    <li>
                                                                                        <span>Date:</span><span>{new Date(sales.timestamp).toISOString().split('T')[0]}</span>
                                                                                    </li>
                                                                                    <li><span>Sales Agent:</span><span>{sales.fullname}</span></li>
                                                                                    <li><span>Mobile:</span><span>{sales.phone_number}</span></li>
                                                                                    {/* <li><span>Address:</span><span>{sales?.address}</span></li> */}
                                                                                    <li><span>Sales Outlet:</span><span><NumberFormat thousandSeparator={true} displayType={'text'} value={+sales?.so_no} /> </span></li>
                                                                                    <li><span>Order (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_amount).toFixed(2)} /> </span></li>
                                                                                    <li><span>My Income (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_income).toFixed(2)} /> </span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    ))}
                                                            </Collapse>
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={saPage} total={total_sa_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={sa_page_size} onChange={change_sa_page} />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </Tabs.TabPane>
                                    }
                                    {userProfile?.is_sa &&
                                        <Tabs.TabPane tab="Food Vendor List" key="2">
                                            <div className="px-4 table-responsive">
                                                {!allSoFetched ?
                                                    <>
                                                        <div className="desktop show-all">
                                                            <div className="show-all">
                                                                <Table columns={soColumns} pagination={false} dataSource={so_data} bordered />
                                                                <div style={{ marginTop: "2%" }}></div>
                                                                <Pagination showSizeChanger current={page} total={total_so_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                            </div>
                                                        </div>
                                                        <div className="mobile">
                                                            <Collapse>
                                                                {
                                                                    allSo.map((sales, index) => (
                                                                        <Collapse.Panel showArrow={false} key={index}
                                                                            header={<p style={{ display: 'inline' }}>{sales.fullname} on {new Date(sales.timestamp).toISOString().split('T')[0]}</p>}>
                                                                            <div>
                                                                                <ul className="mobile-list-flex">
                                                                                    <li>
                                                                                        <span>Date:</span><span>{new Date(sales.timestamp).toISOString().split('T')[0]}</span>
                                                                                    </li>
                                                                                    <li><span>Name of Food Vendor:</span><span>{sales.fullname}</span></li>
                                                                                    <li><span>SO Phone:</span><span>{sales.phone_number}</span></li>
                                                                                    <li><span>Address:</span><span>{sales?.new_user_address}</span></li>
                                                                                    <li><span>Next order date:</span><span>{sales?.next_order_date}</span></li>
                                                                                    <li><span>Order:</span><span>{sales.so_order_no}</span></li>
                                                                                    <li><span>Order (NGN):</span><span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_amount).toFixed(2)} /></span></li>
                                                                                    <li><span>My Income (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_income).toFixed(2)} /> </span></li>
                                                                                    <li><span>Pending Income (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.pending_order_income).toFixed(2)} /> </span></li>
                                                                                    <li><span>Action:</span><span><Link to={`/sales/${sales?.so_user_id}`}>View</Link> | <Button type="link" onClick={() => { setNextOD(true); setSelectedSo(sales) }}>Update</Button></span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    ))}
                                                            </Collapse>
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_so_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="">
                                                        <Spin />
                                                    </div>
                                                }
                                            </div>
                                        </Tabs.TabPane>
                                    }
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title={`Update Next Order Date for ${selectedSo?.fullname}`} centered open={nextOD} confirmLoading={confirmLoading} onOk={handleOk} onCancel={() => setNextOD(false)}>
                    <label>Select a Next Order Date:</label>
                    <br />
                    <DatePicker value={selectedDate} onChange={(date) => setSelectedDate(date)} format="YYYY-MM-DD" />
                </Modal>
                <div className="profile mobile">
                    <Footer />
                </div>
                <InfoModal
                    title={modalContent.title}
                    desc={modalContent.desc}
                    visible={modalVisible}
                    onClose={closeModal}
                />
            </Spin>
        </div >
    )
}

export default Sl