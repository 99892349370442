import { CheckCircleFilled, LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Drawer, Empty, Form, Input, Modal, Radio, Result, Select, Space, Spin, Tag, Timeline, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { _balance_up, _cancelation_fee, _change_order_status, _general_settings_by_id, _get_order, _get_profile, _order_status_change } from '../../common/axios_services';
import Navbar from '../../components/Navbar';
import { fetchData, profileData } from '../../slices/profileSlice';

const ReviewOrder = () => {
    const profileInfo = useSelector(state => state.userProfile.dataFetched);
    const currentDate = moment();
    const [hideCancel, setHideCancel] = useState(false);
    const [hideDeliveryDate, setHideDeliveryDate] = useState(false);
    const param = useParams();
    const dispatch = useDispatch()
    const { Panel } = Collapse;
    const [order, setOrder] = useState(false)
    const navigate = useNavigate()
    const [cancellingOrder, setCancellingOrder] = useState(false)
    const [cancelModal, setCancelOrderModal] = useState(false)
    const [orderId, setOrderId] = useState('')
    const [updateCount, setUpdateCount] = useState(0)
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [balanceUpModal, setBalanceUpModal] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [cancelationCharge, setCancelationCharge] = useState(false);
    const [errPayment, setErrPayment] = useState(false);
    const [hideCancelDays, setHideCancelDays] = useState(false);
    const [form] = Form.useForm();
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight',
            message: title ? title : '',
            description: message ? message : "",
        });
    };


    const cancelationCharges = async () => {
        setCancelationCharge(false)
        try {
            const cancelation_fee = await _cancelation_fee({ id: param.trans_id })
            setCancelationCharge(cancelation_fee?.data?.data)
        } catch (err) {
            setCancelationCharge(false)
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const getOrder = async () => {
        setSpinnerLoading(true)
        try {
            setSpinnerLoading(false)
            const recent_invoice = await _get_order({ trxt_ref: param.trans_id })
            setOrder(recent_invoice.data.data)
            if (currentDate.isSame(moment(formatDate(recent_invoice?.data?.data?.delivery_date)), 'day') && currentDate.isSameOrAfter(moment(formatDate(recent_invoice?.data?.data?.delivery_date)).clone().startOf('day').add(1, 'hours'))) {
                setHideDeliveryDate(true)
            }
            const interval = setInterval(() => {
                const currentDate = moment();
                if (currentDate.isSame(moment(formatDate(recent_invoice?.data?.data?.delivery_date)), 'day') && currentDate.isSameOrAfter(moment(formatDate(recent_invoice?.data?.data?.delivery_date)).clone().startOf('day').add(1, 'hours'))) {
                    setHideDeliveryDate(true)
                    clearInterval(interval);
                }
            }, 60000);
            return () => clearInterval(interval);
        } catch (err) {
            setSpinnerLoading(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            dispatch(profileData(user_profile.data.data))
            dispatch(fetchData(true))
        } catch (err) {
            dispatch(profileData(false))
            dispatch(fetchData(false))
            if (err.response) {
                navigate("/signin?redir=review")
                localStorage.removeItem('konnect_token')
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const cancelOrder = async ({ id, stat }) => {
        setCancellingOrder(true)
        try {
            if (stat === "success" || stat === "pending" || stat === "confirmed") {
                const confirm_order = await _order_status_change({ id, status: "canceled" })
                setCancellingOrder(false)
                setCancelOrderModal(false)
                setOrderId('')
                openNotificationWithIcon('success', confirm_order?.data?.title, confirm_order?.data?.message)
                setUpdateCount(updateCount + 1)
            } else if (stat === "inbound") {
                const confirm_order = await _order_status_change({ id, status: "terminate" })
                setCancellingOrder(false)
                setCancelOrderModal(false)
                setOrderId('')
                openNotificationWithIcon('success', confirm_order?.data?.title, confirm_order?.data?.message)
                setUpdateCount(updateCount + 1)
            }
        } catch (err) {
            setCancellingOrder(false)
            if (err.response) {
                localStorage.removeItem('konnect_token')
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const finishPayment = async (values) => {
        setProcessingPayment(true);
        try {
            const balance_up = await _balance_up({ payment_mode: values.payment_mode, transaction_id: orderId })
            setProcessingPayment(false);
            setErrPayment(false)
            window.open(balance_up.data.data.link, "_self")
        } catch (err) {
            setProcessingPayment(false);
            if (err.response) {
                setErrPayment(err?.response?.data?.message)
            } else {
                setErrPayment(err?.message)
            }
        }
    }

    const getHideCancel = async () => {
        setHideCancelDays(false)
        try {
            const hide_cancel_days = await _general_settings_by_id({ id: "3" })
            setHideCancelDays(hide_cancel_days?.data?.data?.value)
        } catch (err) {
            setHideCancelDays(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        getProfile();
        getOrder();
        getHideCancel()
    }, [updateCount])

    const formatDate = (dateString) => {
        return moment(dateString, 'dddd, MMMM Do YYYY').format('YYYY-MM-DD');
    };

    return (
        <div className="form-pages">
            <Spin spinning={!order} indicator={newAntIcon}>
                <Navbar />
                {order &&
                    (
                        <div className="cart">
                            <div>
                                <div className="new_checkout_data contain">
                                    <div className="mt-5 cart-design-body">
                                        <div>
                                            <Link to="/profile/order_history">
                                                <LeftCircleOutlined style={{ fontSize: "30px", color: "black", marginBottom: "20px" }} />
                                            </Link>
                                            <Collapse defaultActiveKey={['1', '2', '3']} expandIconPosition="start">
                                                <Panel header="DELIVERY ADDRESS" key="2" extra={
                                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} >
                                                        <CheckCircleFilled />
                                                    </div>
                                                }>
                                                    {order?.delivery_location ?
                                                        <div className='d-flex flex-column'>
                                                            <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_opt} Delivery</span><br />
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_location?.Address}</span>
                                                                <span style={{ textTransform: "capitalize" }}>{order?.delivery_location?.city?.city_name}, {order?.delivery_location?.state} State</span>
                                                            </span>
                                                        </div>
                                                        :
                                                        <div className='d-flex flex-column'>
                                                            <span style={{ marginLeft: "auto", marginBottom: "10px" }} className="col-12">
                                                                <span>No delivery address selected</span>
                                                            </span>
                                                        </div>
                                                    }
                                                </Panel>
                                                <Panel header="Tracking Status" key="3" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} ><CheckCircleFilled /></div>}>
                                                    <div className='d-flex flex-column'>
                                                        <Timeline>
                                                            <Timeline.Item color="gray">Order Requested - {moment(order?.created_at.split('T')[0], 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')}</Timeline.Item>
                                                            <Timeline.Item color="yellow">Tracking status - {
                                                                order?.status === "cancelled"
                                                                    ? "Order Cancelled"
                                                                    : currentDate.isBefore(moment(moment(order?.delivery_date, 'dddd, MMMM Do YYYY').format('YYYY-MM-DD')).clone().subtract(1, 'day'), 'day') || order?.delivery_status === "pending"
                                                                        ? "Order Recieved"
                                                                        : currentDate.isSame(moment(formatDate(order?.delivery_date)).clone().subtract(1, 'day'), 'day')
                                                                            ? "Order Processed"
                                                                            : currentDate.isSame(moment(formatDate(order?.delivery_date)), 'day')
                                                                                ? "Order in Transit"
                                                                                : currentDate.isAfter(moment(formatDate(order?.delivery_date)), 'day') && order?.delivery_status === "delivered"
                                                                                    ? "Order Delivered"
                                                                                    : currentDate.isAfter(moment(formatDate(order?.delivery_date)), 'day') && order?.delivery_status === "cancelled"
                                                                                        ? "Order Returned"
                                                                                        : ""
                                                            }
                                                            </Timeline.Item>
                                                            {!hideDeliveryDate &&
                                                                <Timeline.Item color="green">Delivery Date - {moment(order?.delivery_date).format('dddd, MMMM Do YYYY')}</Timeline.Item>
                                                            }
                                                        </Timeline>
                                                    </div>
                                                </Panel>
                                                <Panel header="ORDER DETAILS" key="1" extra={<div style={{ display: "flex", gap: "10px", alignItems: "center" }} > <CheckCircleFilled /> </div>}>
                                                    {/* <div className="desktop mt-5 mb-5"> */}
                                                    <div>
                                                        <div>
                                                            {order?.items?.length ?
                                                                <div className="">
                                                                    <div className="cart-design-tab new_cart">
                                                                        {order?.items.map((product, index) => (
                                                                            <div key={index}>
                                                                                <div className={`${order?.items.length - index === 1 ? 'no-border' : ''} cart-body-detail`}>
                                                                                    <div className="inner-flex new_cart">
                                                                                        <div className="cart_product_image">
                                                                                            <img src={product.product_image} alt="product" />
                                                                                        </div>
                                                                                        <div className="cart_product_text">
                                                                                            <div className="inner-flex new_cart">
                                                                                                <div>
                                                                                                    <h4>{product.product_name}</h4>
                                                                                                </div>
                                                                                            </div>
                                                                                            <p className="cart_product_description">
                                                                                                <span className="small_currency">NGN</span>
                                                                                                <NumberFormat value={product.product_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} /> each
                                                                                            </p>
                                                                                            <div className="mobile">
                                                                                                <div className="mobile-flex-cover">
                                                                                                    <div className="mobile-flex">
                                                                                                        <Select defaultValue={product.quantity}
                                                                                                            disabled
                                                                                                            className="select-quantity" style={{ width: 80 }}>
                                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                                        </Select>
                                                                                                        <p>
                                                                                                            <span className="small_currency">NGN</span>
                                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="small-flex">
                                                                                        <Select defaultValue={product.quantity}
                                                                                            disabled
                                                                                            className="select-quantity" style={{ width: 80, margin: '0% auto' }}>
                                                                                            <Select.Option value={product.quantity}>{product.quantity}</Select.Option>
                                                                                        </Select>
                                                                                    </div>
                                                                                    <div className="small-flex">
                                                                                        <p>
                                                                                            <span className="small_currency">NGN</span>
                                                                                            <NumberFormat value={product.total_price} className="product-price" displayType={'text'} prefix={""} thousandSeparator={true} />
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="empty-cart">
                                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                                        description={
                                                                            <span>
                                                                                You have no products in cart yet? <br />
                                                                                <Link to="/shop">Go to shop</Link>
                                                                            </span>
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </Panel>
                                            </Collapse>
                                        </div>
                                        <div>
                                            <div className="cart-brief">
                                                <div className="cart-brief-data">
                                                    <div>
                                                        <ul className="first-list">
                                                            <li>
                                                                <span className="first">Payment status </span>
                                                                <span className="second"><div style={{ textTransform: "capitalize" }}>{order?.transaction?.payment_status === "full-payment" ? <Tag color="green">{order?.transaction?.payment_status}</Tag> : order?.transaction?.payment_status === "no-payment" ? <Tag color="red">{order?.transaction?.payment_status}</Tag> : <Tag color="orange">{order?.transaction?.payment_status}</Tag>}</div></span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Order status </span>
                                                                <span className="second"><div style={{ textTransform: "capitalize" }}>{order?.status === "success" ? <Tag color="green">{order?.status}</Tag> : order?.status === "cancel" || order?.status === "ccanceled" ? <Tag color="red">{order?.status}</Tag> : <Tag color="orange">{order?.status}</Tag>}</div></span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Order size </span>
                                                                <span className="second">{Number(order?.total_weight).toFixed(2)} kg</span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Order quantity </span>
                                                                <span className="second">{Number(order?.total_qty).toFixed(2)} Item(s)</span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Subtotal </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={(order?.sub_amount).toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Delivery Fee </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.delivery_fee.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Coupon</span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.coupon_amount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="first">Discount</span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.discount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                            <li>
                                                                <span className="first">Total </span>
                                                                <span className="second">
                                                                    <span className="small_currency">{order?.currency} </span>
                                                                    <NumberFormat prefix="" value={order?.total_amount.toFixed(2)} className="foo"
                                                                        displayType={'text'} thousandSeparator={true} />
                                                                </span>
                                                            </li>
                                                            {order?.payment_opt === "pay_on_delivery" && <>
                                                                <Divider style={{ clear: 'both', width: '100%', margin: '5px 0' }} />
                                                                <li>
                                                                    <span className="first">Paid</span>
                                                                    <span className="second">
                                                                        <span className="small_currency">NGN </span>
                                                                        <NumberFormat prefix="" value={Number(order?.transaction?.total_amount_paid).toFixed(2)} className="foo"
                                                                            displayType={'text'} thousandSeparator={true} />
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="first">Balance</span>
                                                                    <span className="second">
                                                                        <span className="small_currency">NGN </span>
                                                                        <NumberFormat prefix="" value={Number(order?.transaction?.balance).toFixed(2)} className="foo"
                                                                            displayType={'text'} thousandSeparator={true} />
                                                                    </span>
                                                                </li>
                                                            </>
                                                            }
                                                        </ul>
                                                        {(order?.transaction?.payment_status === "full-payment" || order?.transaction?.payment_status === "no-payment" || order?.transaction?.payment_status === "pending-payment") ?
                                                            <Button disabled style={{ width: "100%" }}>Paid</Button>
                                                            :
                                                            <>
                                                                {order?.status === "closed" || order?.status === "canceled" || order?.status === "outbound" ?
                                                                    <></> :
                                                                    <>
                                                                        {profileInfo?.kyc_status !== "verified" ?
                                                                            <Button style={{ width: "100%" }} onClick={() => { setOrderId(order?.transaction_id); finishPayment({ payment_mode: "gateway" }); }}>Balance Up</Button>
                                                                            :
                                                                            <Button style={{ width: "100%" }} onClick={() => { setBalanceUpModal(true); setOrderId(order?.transaction_id) }}>Balance Up</Button>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        {cancellingOrder ?
                                                            <Button className="mt-5" disabled loading style={{ width: "100%", height: "4.5rem", background: "green", fontWeight: "semibold", color: "white" }}>Cancelling Order</Button> :
                                                            (order?.status === "closed" || order?.status === "canceled" || order?.status === "outbound" || !hideCancelDays || moment(order?.delivery_date).clone().subtract(+hideCancelDays, 'day').isSameOrBefore(currentDate, 'day')) ?
                                                                <Button className="mt-5" disabled style={{ width: "100%", height: "4.5rem", fontWeight: "semibold", }}>Cancel Request</Button>
                                                                :
                                                                <Button className="mt-5" onClick={() => { setCancelOrderModal(true); setOrderId(order?.id); cancelationCharges() }} style={{ width: "100%", height: "4.5rem", background: "#990000", fontWeight: "semibold", color: "white" }}>Cancel Request</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Spin >
            <Modal centered={true} title="" visible={cancelModal} footer={null} onCancel={() => { setCancelOrderModal(false); setOrderId('') }} className="pb-5 pt-5">
                <h2>Cancel Order Request</h2>
                {cancelationCharge ?
                    <p>If you cancel this order,  {cancelationCharge?.cancelation ? (<span><b>we will deduct a penalty fee of NGN <NumberFormat prefix="" value={cancelationCharge?.calcellation_fee} className="foo" displayType={'text'} thousandSeparator={true} /> from your payment</b></span>) : <b>it will not attract any penalty fee</b>}, and refund the balance of {cancelationCharge?.cancelation && <b>NGN <NumberFormat prefix="" value={cancelationCharge?.amount} className="foo" displayType={'text'} thousandSeparator={true} /></b>} to your konnect wallet.</p> :
                    <Spin />
                }
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={() => { setCancelOrderModal(false); setOrderId('') }}>Cancel</Button>
                    {cancellingOrder ?
                        <Button disabled loading >Proceed</Button> :
                        cancelationCharge ?
                            <Button type="primary" onClick={() => { cancelOrder({ id: orderId, stat: order?.status }) }}>Proceed</Button> :
                            <Button disabled >Proceed</Button>
                    }
                </div>
            </Modal>
            <Modal centered={true} title="" visible={balanceUpModal} footer={null} onCancel={() => { setBalanceUpModal(false); setOrderId('') }} className="pb-5 pt-5">
                <h2>Balance Up Payment</h2>
                <p style={{ color: "red" }}>{errPayment ? errPayment : ""}</p>
                <Form layout="vertical" form={form} onFinish={finishPayment}>
                    <Form.Item label="Select Payment mode" name="payment_mode">
                        <Select placeholder="Select Payment Mode">
                            <Select.Option value="wallet">Wallet</Select.Option>
                            <Select.Option value="gateway">Card</Select.Option>
                        </Select>
                    </Form.Item>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button onClick={() => { setBalanceUpModal(false); setErrPayment(false); form.resetFields() }}>Close</Button>
                        <Form.Item>
                            {processingPayment ?
                                <Button type="primary" loading>Loading</Button>
                                :
                                <button style={{ borderRadius: '4px', fontSize: '1.4rem', background: "green", color: "white" }} htmlType="submit" className="bg_blank">Proceed</button>
                            }
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div >
    )
}

export default ReviewOrder
