import '../assets/css/landing.css';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Link } from 'react-router-dom';
import Apple from '../assets/images/apple_store.png';
import play from '../assets/images/play_store.png';

import SideImage from '../assets/images/landing/3.png';

const SalesLanding = () => {
    return (
        <>
            <Navbar disapear={true} />
            <div className='landing_hero' style={{ width: "100%", backgroundColor: "#f1fef4", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0 2%" }} className='grid_flex'>
                    <div>
                        <h3 style={{ color: "rgba(0,0,0,.85)", fontSize: "4.5rem", fontFamily: "Gilroy Bold", marginBottom: "2rem" }}>SIGN UP TODAY & GET UP TO NGN 1,000 TO BUY FOOD</h3>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '10px' }}>
                            <a target="_blank" href="https://2ly.link/1xurp">
                                <img style={{ height: "5rem", width: "auto" }} src={Apple} className="download" alt="download on apple store" />
                            </a>
                            <a target="_blank" href="https://2ly.link/1xurp">
                                <img style={{ height: "5rem", width: "auto" }} src={play} className="download" alt="download on google play store" />
                            </a>
                        </div>
                    </div>
                    <div style={{ display: "block" }}>
                        <img src={SideImage} alt="" style={{ margin: "0 auto", display: "block" }} />
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div style={{ padding: "50px" }} className='grid_card'>
                    <div className='card_info'>
                        <h4>How to get the cash to buy food</h4>
                        <div className='list_container'>
                            <ul>
                                <li>Create a free account as a user or as a food vendor using the food vendor code</li>
                                <li>Verify you account and login via our mobile app (see icon below)</li>
                                <li>Visit your WALLET page and click transfer to wallet</li>
                                <li>To buy food, transfer you cash to your food vendor using their code</li>
                            </ul>
                        </div>
                        <Link className='joinus_button' to="/signup">Sign-Up</Link>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div style={{ padding: "50px" }} className='grid_card'>
                    <div className='card_info'>
                        <h4>Join us as a Sales Agent or Become a Food Vendor and earn weekly</h4>
                        <i>Food vendor is a business user that buys food stuffs & sell cooked food online or onsite</i>
                        <i>Sales agent is a user that manages & can refer food vendors (a food vendor can also become a sales agent)</i>
                        <Link className='joinus_button' to="/joinus">Join Us</Link>
                    </div>
                </div>
            </div>
            <Footer noSpace={true} />
        </>
    )
}

export default SalesLanding;