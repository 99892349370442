import React from 'react'

const AlertModal = ({ children, title, desc }) => {
    return (
        <div>
            <div className="container form_design_pages" style={{ maxWidth: "420px" }}>
                <div className="">
                    <div className="form aut center-text wise9ja-success">
                        <div>
                            <h3>{title}</h3>
                            <p>{desc}</p>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertModal