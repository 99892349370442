import React from 'react'
import Navbar from '../../components/Navbar'
import { LocationWrapper } from '../../components/LocationWrapper'
import { Col, Row } from 'antd'
import { ImageTray } from '../../components/Restaurant/ImageTray'
import { SinglePageContent } from '../../components/Restaurant/SinglePageContent'; 
import briefCase from '../../assets/images/restaurant/suitcase_outline.svg'
import { ClockCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import { Footer } from 'antd/lib/layout/layout'
import { UnderConstructionModal } from '../../components/UnderConstructionModal'


const SinglePage = () => {
  return (
    <>
          {/*  UNDER CONSTRUCTION POPUP */}
          <UnderConstructionModal />
      <div className=" h-auto">
        <Navbar noShadow={true} adjustNavSize={true} showCart={true}  restaurantCart={true}  />
        <LocationWrapper />
        <div className=" singlePageHeader ">
          <Row justify={'center'} >
            <Col xs={22} lg={24} >
              <div className="flex items-center flex-wrap justify-between gap-2 lg:px-20 py-5">
                <h1 className="text-[#44843F] text-5xl" >Havey Restaurant</h1>
                <div className="flex gap-10">
                  <div className="flex flex-col">
                    <p className='text-[#959595]  '>Profile</p>
                    <div className="flex gap-3 items-center ">
                      <img src={briefCase} className='w-6 '  alt="" />
                      <p className='text-[12px] md:text-[16px] font-semibold' >About Us</p>
                    </div>
                    
                  </div>
                  <div className="flex flex-col">
                    <p  className='text-[#959595]' >Time</p>
                    <div className="flex gap-3 items-center ">
                      <ClockCircleOutlined  style={{ fontSize: '15px'}} />
                      <p className='text-[12px] md:text-[16px] font-semibold' >20-30min</p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className='text-[#959595]' >Rating</p>
                    <div className="flex gap-3 items-center ">
                    <StarFilled  style={{ fontSize: '15px', color: '#FF8900'}} />
                      <p className='text-[12px] md:text-[16px] font-semibold' >4.2 <span className='text-[#44843F] '>(217)</span></p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Restaurant Img */}
              <div className="lg:px-20">
                <div className="singleRestaurant_img flex mx-auto mt-2 ">
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="lg:px-20 pt-20">
          <SinglePageContent />
        </div>
        {/* <Footer /> */}
      </div>
    </>
  )
}

export default SinglePage