import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { _activate_sa } from "../../common/axios_services";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const SaEmail = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const refId = searchParams.get('ref_id');
    const status = searchParams.get('status');
    const [userResponse, setUserResponse] = useState('')
    const [responseTitle, setResponseTitle] = useState('')
    const [checkingToken, setCheckingToken] = useState(true)

    const activate = async ({ status, refId }) => {
        try {
            const activate_sa = await _activate_sa({ status, refId })
            setUserResponse(activate_sa?.data?.message);
            setResponseTitle(activate_sa?.data?.title);
            setCheckingToken(false)
        } catch (err) {
            if (err?.response?.data?.message) {
                setUserResponse(err?.response?.data?.message);
                setResponseTitle(err?.response?.data?.title);
            } else if (err?.code === "ERR_NETWORK") {
                setUserResponse('An error occurred while activating user as SA. Please check network and try again');
                setResponseTitle("Error")
            } else {
                setResponseTitle("Error")
                setUserResponse('An error occurred while activating user as SA. Please try again');
            }
            setCheckingToken(false)
        }
    }
    useEffect(() => {
        activate({ status: status === "true" ? true : false, refId })
    }, [status, refId])
    return (
        <>
            {status ?
                <>
                    <div className="mobile">
                        <Navbar />
                        <div className="container form_design_pages">
                            <div className="">
                                {!checkingToken ?
                                    <div className="form aut center-text wise9ja-success">
                                        <h3>{responseTitle}</h3>
                                        <p>{userResponse}</p>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="">
                                            <Link to="/profile/loyalty">Proceed to dashboard</Link>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ display: "flex", flexDirection: "", alignItems: "center", justifyContent: "center" }}>
                                        <Spin style={{ fontSize: 40 }} />
                                        <p style={{ fontSize: '1.4rem' }}>Processing your request. Please wait...</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="desktop show-all">
                        <Navbar />
                        <div className="bg_grey">
                            <div className="container form_design_pages">
                                <div className="div_bg_white">
                                    {!checkingToken ?
                                        <div className="form aut center-text wise9ja-success">
                                            <h3>{responseTitle}</h3>
                                            <p>{userResponse}</p>
                                            <div style={{ marginBottom: '20px' }}></div>
                                            <div className="">
                                                <Link to="/profile/loyalty">Proceed to dashboard</Link>
                                            </div>
                                        </div> :
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Spin style={{ fontSize: 40 }} />
                                            <p style={{ fontSize: '1.4rem' }}>Processing your request. Please wait...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div className="mobile">
                        <Navbar />
                        <div className="container form_design_pages">
                            <div className="">
                                {!checkingToken ?
                                    <div className="form aut center-text wise9ja-success">
                                        <h3>Reject Sales Assistance Request</h3>
                                        <p>{userResponse}</p>
                                        <div style={{ marginBottom: '20px' }}></div>
                                        <div className="">
                                            <Link to="/profile/loyalty">Proceed to dashboard</Link>
                                        </div>
                                    </div> :
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                        <Spin style={{ fontSize: 40 }} />
                                        <p style={{ fontSize: '1.4rem' }}>Processing your request. Please wait...</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="desktop show-all">
                        <Navbar />
                        <div className="bg_grey">
                            <div className="container form_design_pages">
                                <div className="div_bg_white">
                                    {!checkingToken ?
                                        <div className="form aut center-text wise9ja-success">
                                            <h3>Reject Sales Assistance Request</h3>
                                            <p>{userResponse}</p>
                                            <div style={{ marginBottom: '20px' }}></div>
                                            <div className="">
                                                <Link to="/profile/loyalty">Proceed to dashboard</Link>
                                            </div>
                                        </div> :
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                            <Spin style={{ fontSize: 40 }} />
                                            <p style={{ fontSize: '1.4rem' }}>Processing your request. Please wait...</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <Footer />
        </>
    )
}

export default SaEmail;