import React, { useState } from 'react';
import '../assets/css/_1.css';
import '../assets/css/about.css';
import '../assets/css/bills.css';
import '../assets/css/wisenija.css';

import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Spin, notification } from 'antd';
import { DateTime } from 'luxon';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';

import Health from '../assets/images/homepage/health.svg';
import Home from '../assets/images/homepage/home.svg';
import Invite from '../assets/images/homepage/invite_friend.svg';
import Pathway from '../assets/images/homepage/pathway.svg';

import { useEffect } from 'react';
import Wise9ja_1 from '../assets/images/homepage/wise9ja1.png';
import Wise9ja_2 from '../assets/images/homepage/wise9ja2.png';
import Wise9ja_3 from '../assets/images/homepage/wise9ja3.png';
import { _complete_wise9ja_payment, _fetch_user_wallet_data, _fetch_wise9ja_plans, _get_all_wise9ja, _get_cms_page, _get_profile, _verify_pin, _wise9ja_topup } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { fetchData, profileData } from '../slices/profileSlice';

const Wise9ja = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    const fetchDataStatus = useSelector(state => state.userProfile.fetch)
    const userData = useSelector(state => state.userProfile.dataFetched)
    const [errMsg, setErrMsg] = useState(false);
    const [visible, setVisible] = useState(false);
    let jwt_token = localStorage.getItem('konnect_token');
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const secAntIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const navigate = useNavigate();
    const { handleSubmit, control, watch, setValue } = useForm({});
    const [wise9jaPeriods, setWise9jaPeriods] = useState([]);
    const [walletData, setWalletData] = useState({});
    const [wise9jaPeriodData, setWise9jaPeriodData] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState({});
    const [wise9jaRespData, setWise9jaRespData] = useState('');
    const [loadingData, setLoadingData] = useState(false)
    const [gettingStarted, setGettingStarted] = useState(false)
    const [amount, setAmount] = useState(0);
    const [displayToUse, setDisplayToUse] = useState(0);
    const [userPeriod, setUserPeriod] = useState('');
    const [loadingWise9jaFeatures, setLoadingWise9jaFeatures] = useState(false);
    const [verifiedPin, setVerifiedPin] = useState(false);
    const [revealPinMessage, setRevealPinMessage] = useState(false);
    const [verifingPin, setVerifingPin] = useState(false);
    const [userPeriodDateSchedules, setUserPeriodDateSchedules] = useState([]);
    const [allImages, setAllImages] = React.useState(false)

    const handleOk = () => {
        setVisible(false);
    };

    const getWise9ja = async () => {
        try {
            const wise9ja = await _get_all_wise9ja();
            let newArray = [];
            wise9ja.data.data.forEach(wise => {
                newArray[wise.plan_name] = wise;
            })
            setWise9jaPeriods(wise9ja.data.data);
            setWise9jaPeriodData(newArray);
        } catch (err) {
            console.log(err)
        }
    }
    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    const fetchUserWalletBalance = async e => {
        try {
            let fetchUserAccountBalance = await _fetch_user_wallet_data();
            if (fetchUserAccountBalance.data.status === "success") {
                setWalletData(fetchUserAccountBalance.data.data);
            }
        } catch (err) {
            console.log(err)
        }
    }

    const getAllImages = async () => {
        try {
            const images = await _get_cms_page({ page: "wise9ja" })
            setAllImages(images.data.data)
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        getAllImages()
    }, [])

    const findImageObj = ({ page }) => {
        return allImages.find(obj => obj["name"] === page)
    }

    React.useEffect(() => {
        getWise9ja();
        getProfile();
        fetchUserWalletBalance();
    }, [dispatch, token, jwt_token])

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottomRight"
        });
    };

    const completeWise9jaPayment = async e => {
        setLoadingData(true);
        let paymentInfo = {
            plan_id: selectedPeriod.id,
            description: selectedPeriod.description,
            amount: amount,
            currency: "NGN"
        }
        try {
            let wise9ja_payment = await _complete_wise9ja_payment(paymentInfo);
            navigate('/wise9ja-success');
            const wise9ja_data = { title: wise9ja_payment?.data?.title, message: wise9ja_payment?.data?.message }
            localStorage.setItem('konnect_wise9ja', JSON.stringify(wise9ja_data))
        } catch (err) {
            if (err?.response?.data?.message) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                setErrMsg(err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err?.message)
                setErrMsg(err.message);
            }
            setLoadingData(false);
        }
    }

    const fetchSelectedPeriodPlan = async e => {
        setErrMsg('');
        setLoadingWise9jaFeatures(true);
        let newData = {
            plan_id: userPeriod.id,
            amount
        }
        try {
            let getWise9jaInfo = await _fetch_wise9ja_plans(newData);
            if (getWise9jaInfo.data.status === "success") {
                setWise9jaRespData(getWise9jaInfo.data.data);
                let dateBox = [];
                let duration = getWise9jaInfo.data.data.durations;
                for (let i = 1; i <= +duration; i++) {
                    let currIndex = 30 * i;
                    let newDate = DateTime.now().plus({ days: currIndex }).toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' });
                    dateBox.push(newDate);
                }
                setUserPeriodDateSchedules(dateBox);
            } else {
                setErrMsg(getWise9jaInfo.data.message);
                openNotificationWithIcon(getWise9jaInfo?.data?.status, getWise9jaInfo?.data?.title, getWise9jaInfo?.data?.message)
            }
            setLoadingWise9jaFeatures(false);
            setDisplayToUse(1);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                    setErrMsg(err?.response?.data?.message);
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
                setErrMsg(err.message);
            }
            setLoadingWise9jaFeatures(false);
        }
    }

    useEffect(() => {
        setErrMsg('');
        setWise9jaRespData({});
        if (amount && selectedPeriod.min_amount) {
            if (amount >= selectedPeriod.min_amount && amount <= selectedPeriod.max_amount) {
                setLoadingWise9jaFeatures(true);
                let periodPlanId = setTimeout(fetchSelectedPeriodPlan, 4000);
                return () => clearTimeout(periodPlanId);
            }
        }
    }, [amount, selectedPeriod])

    const onChangeValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setAmount(amount);
    }

    const fetchPeriodData = async e => {
        let selectId = wise9jaPeriodData[e];
        setUserPeriod(selectId);
    }

    const VerifyPin = async (values) => {
        setVerifingPin(true)
        try {
            const verify_pin = await _verify_pin({ pin: values.pin });
            setVerifingPin(false);
            openNotificationWithIcon(verify_pin?.data?.status, verify_pin?.data?.title, verify_pin?.data?.message);
            setRevealPinMessage(false)
            setVerifiedPin(true);
        } catch (err) {
            setVerifingPin(false)
            setVerifiedPin(false);
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                    setRevealPinMessage(false)
                } else {
                    setRevealPinMessage(err?.response?.data?.message);
                }
            } else {
                setRevealPinMessage(false)
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const closeWise9jaModal = () => {
        setVisible(false);
        setSelectedPeriod({});
        setErrMsg('');
        setUserPeriod('');
        setValue('amount', '');
        setVerifiedPin(false);
        setGettingStarted(false);
    }


    return (
        <div className="bill wise9ja minor">
            <Navbar />
            <div className="hero">
                <div className="contain">
                    <div className="grid_2">
                        <div>
                            <h1><span>Wise9ja,</span><br /> The wealthy place<br />Get richer each day.</h1>
                            <p> Let Wise9ja spoil you a bit to live life on your own terms! You get richer each day – as you refer, shop & keep cash aside for the future.</p>
                            {/* {
                                fetchDataStatus ?
                                    userData.user ?
                                        <Link className="bg_side_green" to="/signin?redir=wise9ja">
                                            Start Here & Earn
                                        </Link>
                                        :
                                        <button
                                            onClick={() => setVisible(true)}
                                            className="bg_side_green">Start Here & Earn</button>
                                    :
                                    <Link className="bg_side_green" to="/signin?redir=wise9ja">
                                        Start Here & Earn
                                    </Link>
                            } */}
                        </div>
                        <div>
                            {allImages ?
                                <img src={findImageObj({ page: "wise9ja hero" })?.content} className="wise9ja_header" alt="header" /> :
                                <img src="" className="wise9ja_header" alt="header" />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="wise9ja-props">
                <div className="contain">
                    <div className="card-props-width">
                        <div className="card-list">
                            <div className="first-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Your health, your wealth</p>
                                    <h3>Enhance your health & wellness.</h3>
                                    <p className="card-story">
                                        We support your heath & wellness with free HMO subscriptions, access
                                        to wellness platforms and incentives.
                                    </p>
                                </div>
                                <div className="props-img">
                                    <img src={Health} className="prop-img" alt="" />
                                </div>
                            </div>
                            <div className="second-card card-prop-data long-one">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Refer & Earn</p>
                                    <h3>Invite friends & earn each day.</h3>
                                    <p className="card-story">We pay you when your friends sign-up for free using your code You earn unlimited cash discount and monthly  from their transactions.</p>
                                    <Link to="/profile/referral">Share now <ion-icon name="arrow-forward-circle-outline"></ion-icon></Link>
                                </div>
                                <div className="props-img">
                                    <img src={Invite} className="prop-img" alt="" />
                                </div>
                            </div>
                            <div className="third-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Get cash daily.</p>
                                    <h3>Discount cash, pathway to wealth</h3>
                                    <p className="card-story">We share our wealth with our loyal users. You earn cash to discount your purchases and boost your wallet with instant & daily cash. Make konnect your go to app.</p>
                                </div>
                                <div className="props-img">
                                    <img src={Pathway} className="prop-img" alt="" />
                                </div>
                            </div>
                            <div className="fourth-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Be wise & get richer.</p>
                                    <h3>Spread you cash, buy the future!</h3>
                                    <p className="card-story">Work smart, enjoy better, you deserve that good life! Let your money do much more for you with our wallet spread approach. Fund your wallet & get richer.</p>
                                    <Link to="/profile/loyalty">Start here <ion-icon name="arrow-forward-circle-outline"></ion-icon></Link>
                                </div>
                                <div className="props-img">
                                    <img src={Invite} className="prop-img" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="long-card">
                            <div className="grid-2">
                                <div>
                                    <div>
                                        <p className="prop-title">Smart home ownership.</p>
                                        <h3>Affordable & smart, more than home ownership.</h3>
                                        <p className="card-story">Own or move into a smart home with a friendly approach. Wise9ja helps you to own & increase in wealth. A dream to own a home could be easier than you think.</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="props-img">
                                        <img src={Home} className="prop-img" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="final-wise">
                <div className="contain">
                    <div className="grid">
                        <div>
                            <h3>Ensuring safe and seamless transaction is our priority</h3>
                            <p className="card-story">
                                The app is layered with authentication methods: user ID and strong passwords, 4-digit
                                PIN, fingerprint scanning, etc. Strong authentication allows users to manage assets
                                within the app securely.
                            </p>
                        </div>
                        <div className="grid-3">
                            <div>
                                <img src={Wise9ja_1} alt="signup" />
                                <h4>Quick signup</h4>
                                <p>Easily open a free account in few minutes.</p>
                            </div>
                            <div>
                                <img src={Wise9ja_2} alt="security" />
                                <h4>Top-tier security</h4>
                                <p>Depend on top-notch bank-level security for all your transactions.</p>
                            </div>
                            <div>
                                <img src={Wise9ja_3} alt="support" />
                                <h4>Reliable support</h4>
                                <p>Get access to reliable support and resources at your convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {specPlan && */}
            <Modal centered open={visible} onOk={handleOk} maskClosable={false} onCancel={() => closeWise9jaModal()} footer={null} >
                {gettingStarted ?
                    <>
                        {verifiedPin ?
                            <>
                                <h4 className="modal-title">Save and enjoy instant cash returns</h4>
                                <p className="modal-des">You have a wallet balance of <span className="currency">NGN</span><b>
                                    <NumberFormat thousandSeparator={true} value={walletData.wallet_bal}
                                        displayType="text" className="numeric" /></b>
                                    . Wise9ja user earn instant voucher to pay for any transaction plus monthly cash returns.</p>
                                <div style={{ marginTop: '5%' }}></div>
                                {errMsg && <p style={{ color: "red", fontSize: "12px" }}>{errMsg}</p>}
                                <form onSubmit={handleSubmit(completeWise9jaPayment)}>
                                    <div className="form-group">
                                        <label htmlFor="amount" style={{ width: '100%' }}>Amount</label>
                                        <Controller control={control} defaultValue="" name="amount"
                                            render={({ field }) => (
                                                <NumberFormat thousandSeparator={true}
                                                    prefix={'NGN '}
                                                    className="numeric"
                                                    onInput={e => onChangeValue(e)}
                                                    {...field} style={{ height: '5rem', width: '100%' }} />
                                            )
                                            } />
                                        {
                                            amount && ((amount < selectedPeriod.min_amount) || (amount > selectedPeriod.max_amount)) ?
                                                <p className="form-error">Amount can not be less than <span
                                                    style={{ fontSize: '1.1rem' }}
                                                    className="currency">NGN</span><NumberFormat thousandSeparator={true}
                                                        value={selectedPeriod.min_amount}
                                                        className="numeric" displayType="text"
                                                    /> or more than <span
                                                        style={{ fontSize: '1.1rem' }}
                                                        className="currency">NGN</span><NumberFormat thousandSeparator={true}
                                                            value={selectedPeriod.max_amount}
                                                            className="numeric" displayType="text"
                                                    /></p> : ''
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="period" style={{ width: '100%' }}>Period</label>
                                        <Controller control={control} defaultValue="" name="period"
                                            render={({ field }) => (
                                                <Select id="period"
                                                    onChange={e => {
                                                        setSelectedPeriod(wise9jaPeriodData[e]);
                                                        fetchPeriodData(e);
                                                    }}
                                                    style={{ height: '5rem' }}>
                                                    {
                                                        wise9jaPeriods.map((wisePeriods, index) => (
                                                            <Select.Option
                                                                value={wisePeriods.plan_name}
                                                                key={index}>
                                                                {wisePeriods.plan_name} - {wisePeriods.description}
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            )} />
                                    </div>
                                    <div style={{ marginTop: '5%' }}></div>
                                    {
                                        loadingWise9jaFeatures ?
                                            <Spin indicator={secAntIcon} />
                                            : ''}
                                    {!loadingData ?
                                        <button type="submit" style={{ display: 'block', width: "100%", height: '5rem', borderRadius: '2px', margin: "10px 0px" }}
                                            disabled={wise9jaRespData.amount ? false : true}
                                            className="bg_green stretch">Complete Transaction</button>
                                        :
                                        <button disabled loading style={{ display: 'block', width: "100%", height: '5rem', borderRadius: '2px', margin: "10px 0px", opacity: "0.5" }} className="bg_green stretch" ><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                    }
                                </form>
                                <button
                                    style={{
                                        width: '100%', border: 'none', textAlign: 'center', background: 'transparent',
                                        outline: 'none', paddingTop: 10
                                    }}
                                    onClick={() => closeWise9jaModal()}>Cancel</button>
                                {
                                    wise9jaRespData.amount ?
                                        <div>
                                            <h5 style={{ fontSize: '1.7rem', marginTop: "10px" }}>What you earn into your wallet.</h5>
                                            <ul>
                                                <li style={{ lineHeight: '2.1' }}><span className="currency">NGN</span><NumberFormat thousandSeparator={true} value={wise9jaRespData.instance_commission}
                                                    displayType="text" className="numeric" /> will be paid today as instant cash</li>
                                                {
                                                    wise9jaRespData.voucher_bonus ?
                                                        <li><span className="currency">NGN</span><NumberFormat thousandSeparator={true} value={wise9jaRespData.voucher_bonus}
                                                            displayType="text" className="numeric" /> will be paid today as extra cash (from referral bonus)</li>
                                                        : ''
                                                }
                                                <li style={{ lineHeight: '2.1' }}>
                                                    <span className="currency">NGN</span><NumberFormat thousandSeparator={true} value={wise9jaRespData.monthly_payment}
                                                        displayType="text" className="numeric" /> what we pay you monthly & in {userPeriodDateSchedules.length} installments to repay your subscription (payment dates below)
                                                    <ul style={{ display: 'flex', columnGap: 20, rowGap: 5, flexDirection: 'row', flexWrap: 'wrap' }}>
                                                        {
                                                            userPeriodDateSchedules.map((periodData, index) => (
                                                                <li style={{ lineHeight: '2.1' }} key={index}>{periodData};</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                        : ''
                                }
                            </>
                            :
                            <>
                                <div className="">
                                    <h4 className="modal-title">Transaction Pin</h4>
                                    {userData?.pin === "0000" ?
                                        <p>Activate 2fa and create transaction pin</p> :
                                        <p>Enter your transaction pin.</p>
                                    }
                                    <div style={{ marginTop: '5%' }}></div>
                                    {revealPinMessage ? <p className="error-message">{revealPinMessage}</p> : ''}
                                    <Form layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyPin} autoComplete="off">
                                        <Form.Item label="Pin" name="pin">
                                            {userData?.pin === "0000" ?
                                                <Input placeholder="Enter Pin" disabled />
                                                :
                                                <Input placeholder="Enter Pin" />
                                            }
                                        </Form.Item>
                                        <>
                                            {userData?.pin === "0000" ?
                                                <>
                                                    <Button type='primary' onClick={() => { navigate(`/profile/overview?pin=${true}`) }}>Create Transaction Pin</Button>
                                                </>
                                                :
                                                <>
                                                    {verifingPin ?
                                                        <Button type="primary" loading disabled>Verifing Pin</Button> :
                                                        <Button type="primary" style={{ background: "#008060" }} htmlType="submit">Verify Pin</Button>
                                                    }
                                                </>
                                            }
                                            <button className="bg_white" style={{ outline: 'none' }} onClick={() => { closeWise9jaModal(); setGettingStarted(false) }}>Cancel</button>
                                        </>
                                    </Form>
                                </div>
                            </>}
                    </> :
                    <div>
                        {allImages ?
                            <img src={findImageObj({ page: "alert image" })?.content} alt="" style={{ width: '100%', height: '100%' }} /> :
                            <img src="" alt="" style={{ width: '100%', height: '100%' }} />
                        }
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: '100%', margin: '20px auto' }}>
                            <button style={{ display: 'block' }} className="bg_green" onClick={() => setGettingStarted(true)}>Get Started</button>
                            <button className="bg_white" style={{ outline: 'none' }} onClick={() => { closeWise9jaModal(); setGettingStarted(false) }}>Cancel</button>
                        </div>
                    </div>
                }
            </Modal>
            {/* } */}
            <div className="mobile">
                <Footer noSpace={true} />
            </div>
            <div className="desktop show-all">
                <Footer />
            </div>
        </div>
    )
}

export default Wise9ja