import React from 'react';
import google_play from '../assets/images/homepage/google-play.png';
import apple from '../assets/images/homepage/apple.png';

const MobileDownloadStores = () => {
  return (
    <div className="flex max-sm:items-center max-sm:justify-center gap-5 mt-3">
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wnapp.id1695026422582" rel='noreferrer' >
            <div className="flex gap-5 rounded-xl  items-center  bg-black text-white py-3 px-3  ">
                <span>
                    <img className=' h-8 w-8 lg:w-12 lg:h-12 ' src={google_play} alt="download on google play store" />
                </span>
                <div>
                <p   className='text-base ' >Andriod app on</p> 
                <p className='lg:text-3xl text-lg  ' >Google Play</p>
                </div>
            </div>
        </a>
        <a target="_blank" href="https://apps.apple.com/app/konnect-ws/id6504823567" rel='noreferrer' >
            <div className="flex gap-5 rounded-xl  items-center  bg-black text-white py-3 px-3  ">
                <span>
                    <img className=' h-10 w-10 lg:w-12 lg:h-12' src={apple} alt="download on apple store" />
                </span>
                <div>
                <p className='text-base ' >Available on the iPhone</p> 
                <p className='lg:text-3xl text-lg ' >Apple Store</p>
                </div>
            </div>
        </a>
    </div>
  )
}

export default MobileDownloadStores;