import 'antd/dist/antd.css';
import '../assets/css/form.css';

import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Spin, notification } from 'antd';
import NaijaStates from 'naija-state-local-government';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../common/axios_call';
import { _get_all_state, _validate_referral_code } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import PhoneInput from 'react-phone-input-2';

const Signup2 = () => {
    const [form] = Form.useForm()
    let url = new URLSearchParams(window.location.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const [errorMessage, setErrorMessage] = useState('');
    const [appLoading, setAppLoading] = useState(false);
    const [referralCode,] = useState(url.get('konnectrfc') ? url.get('konnectrfc') : '');
    const [redirectUrl,] = useState(url.get('konnectrd') ? url.get('konnectrd') : '');
    const [referralDetails, setReferralDetails] = useState(false);
    const [loadingReferralDetails, setLoadingReferralDetails] = useState(false);
    const [allStates,] = useState(NaijaStates.states());
    const [allLgas, setAllLgas] = useState([]);
    const [isSo, setIsSo] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottomRight', message: title ? title : '', description: message ? message : "",
        });
    };
    const updateReferralCode = async (values) => {
        if (values?.referralCode?.length === 6) {
            setLoadingReferralDetails(true)
            try {
                const validate_referral_code = await _validate_referral_code({ code: `${values.referralCode}` })
                setLoadingReferralDetails(false)
                setErrorMessage(false)
                if (validate_referral_code?.data?.data?.is_sl) {
                    setReferralDetails(validate_referral_code?.data?.data)
                } else {
                    setReferralDetails(false)
                    form.setFieldsValue({
                        state: "",
                        lga: "",
                        home_address: "",
                    });
                }
            } catch (err) {
                form.setFieldsValue({
                    referralCode: "",
                });
                setReferralDetails(false)
                setLoadingReferralDetails(false)
                if (err.response) {
                    setErrorMessage(err?.response?.data?.message)
                } else {
                    openNotificationWithIcon('error', "Something went wrong", err.message)
                }
            }
        } else if (values?.referralCode?.length < 6) {
            setLoadingReferralDetails(false)
            setReferralDetails(false)
            form.setFieldsValue({
                state: "",
                lga: "",
                home_address: "",
            });
        }
    }
    const signupUser = (values) => {
        setAppLoading(true);
        setErrorMessage('');
        let { first_name, last_name, email, password, referralCode, home_address, lga, state } = values;
        axios.post('/auth/sign-up/user', { lga, state, first_name, last_name, email, password, confirm_password: password, referral_code: referralCode, image: "", home_address, phone_code: countryCode, phone_number: phoneNumber, role_id: 1, is_ra: false, app: "web", is_so: isSo }).then((res) => {
            setAppLoading(false);
            if (res?.data?.status === "error") {
                return setErrorMessage(res.data.message)
            }

            localStorage.setItem('konnectnewaccountcreated', 'true');
            navigate('/verify-email');
        }).catch((err) => {
            if (err.response) {
                if (err?.response?.data?.message === "Unauthorized") {
                    setErrorMessage(err?.response?.data?.message)
                } else {
                    setErrorMessage(err?.response?.data?.message)
                }
            } else {
                setErrorMessage(false)
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
            setAppLoading(false);
        })
    }
    const onChangeState = (value) => {
        if (value) {
            const allLGAsinAState = NaijaStates.lgas(`${value}`);
            setAllLgas(allLGAsinAState.lgas);
        }
    }
    const handlePhoneChange = (phone, country) => {
        const dialCode = `${country.dialCode}`;
        setCountryCode(dialCode);

        // Remove the country code from the start of the phone number
        const phoneWithoutCode = phone.startsWith(dialCode)
            ? phone.slice(dialCode.length).trim()
            : phone;
        setPhoneNumber(phoneWithoutCode);
    };
    return (
        <div className="form-pages signup-data-page">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form auth">
                            <div>
                                <div className="form-text">
                                    <h3>Create a free Konnect Account!</h3>
                                    <p>Fill in your details with your preferred email address that can be verified, thereafter check your email to claim your sign-up bonus!</p>
                                    {errorMessage ?
                                        <p className="form-error">{errorMessage}</p> : ''
                                    }
                                </div>
                                <Form form={form} onValuesChange={updateReferralCode} onFinish={signupUser} initialValues={{ home_address: "", state: "", lga: "" }} layout="vertical">
                                    <div className="form-flex-2">
                                        <div className="form-group space">
                                            <label htmlFor="first_name">First name</label>
                                            <Form.Item name="first_name">
                                                <Input placeholder='First Name' style={{ height: '5rem' }} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="last_name">Last name (Surname)</label>
                                            <Form.Item name="last_name">
                                                <Input placeholder='Last Name' style={{ height: '5rem' }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-flex-2">
                                        <div className="form-group space">
                                            <label htmlFor="email">Email Address</label>
                                            <Form.Item name="email">
                                                <Input type="email" placeholder='Email Address' style={{ height: '5rem' }} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone_number">Phone Number</label>
                                            <Form.Item name="phone_number">
                                                <PhoneInput
                                                    country={'ng'}
                                                    value={phoneNumber}
                                                    onChange={handlePhoneChange}
                                                    containerStyle={{ width: '100%' }}
                                                    inputStyle={{ height: '5rem', width: '100%' }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <Form.Item name="password">
                                            <Input.Password placeholder='Password' style={{ height: '5rem' }} />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <label htmlFor="referralCode">Referral code (If referred by a friend)</label>
                                            {loadingReferralDetails ?
                                                <Button disabled loading type='text'>Loading</Button> :
                                                referralDetails &&
                                                <span style={{ margi: "0px", textTransform: "capitalize" }}>{referralDetails?.name}</span>
                                            }
                                        </div>
                                        <Form.Item name="referralCode">
                                            <Input placeholder='Referral code' maxLength={6} style={{ height: '5rem' }} />
                                        </Form.Item>
                                    </div>
                                    {isSo &&
                                        <>
                                            <div className="form-group">
                                                <label htmlFor="home_address">Vendor Address</label>
                                                <Form.Item name="home_address" rules={[{ required: isSo ? true : false, message: 'Please input Vendor Address!' }]}>
                                                    <Input placeholder='Vendor Address' style={{ height: '5rem' }} />
                                                </Form.Item>

                                            </div>
                                            <div className="form-flex-2">
                                                <div className="form-group space">
                                                    <label htmlFor="state">State</label>
                                                    <Form.Item name="state" rules={[{ required: isSo ? true : false, message: 'Please input your state!' }]} >
                                                        <Select placeholder="Select State" onChange={onChangeState} name="state" style={{ width: '100%', height: '5rem' }}>
                                                            {/* {allStates.map((state, index) => (
                                                                <Select.Option key={index} value={state}>{state}</Select.Option>
                                                            ))} */}
                                                            <Select.Option key={0} value="lagos">Lagos</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="lga">LGA</label>
                                                    <Form.Item name="lga" rules={[{ required: isSo ? true : false, message: 'Please input your LGA!' }]}>
                                                        {allStates ?
                                                            <Select placeholder="Select LGA" name="lga" style={{ width: '100%', height: '5rem' }}>
                                                                {allLgas.map((lga, index) => (
                                                                    <Select.Option key={index} value={lga}>{lga}</Select.Option>
                                                                ))}
                                                            </Select>
                                                            :
                                                            <Select name="lga" placeholder="Select LGA" style={{ width: '100%', height: '5rem' }}></Select>
                                                        }
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div style={{ marginTop: '5%' }}></div>
                                    <label style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
                                        <b style={{ fontSize: "14px", color: "red" }}>Become a food vendor? Click here</b> <input type="checkbox" style={{ width: "20px", height: "20px" }} checked={isSo} onChange={() => { setIsSo(!isSo) }} />
                                    </label>
                                    <p style={{ fontSize: '1.3rem', display: 'block' }}> By clicking Create Account, you acknowledge you have read and agreed to our <Link to={`/terms?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>Terms of Use</Link> and <Link to={`/privacy-policy/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>Privacy Policy</Link>.
                                    </p>
                                    {appLoading || loadingReferralDetails ?
                                        <Button loading disabled style={{ height: '5.5rem', width: '100%', borderRadius: '3px', color: "red" }}>
                                            Please be patient, sign-up in  progress
                                        </Button>
                                        :
                                        <Button htmlType="submit" className="bg_green" style={{ height: '5.5rem', width: '100%', borderRadius: '3px' }}>Create account</Button>
                                    }
                                </Form>
                                <div style={{ marginBottom: '20px' }}></div>
                                <Link style={{ display: 'block' }} to={`/signin/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}> Have an account? <span>Sign in here</span></Link> <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages">
                        <div className="div_bg_white">
                            <div className="form aut">
                                <div>
                                    <div className="form-text">
                                        <h3 className="noSpace">Create a free Konnect Account!</h3>
                                        <p>Fill in your details with your preferred email address that can be verified, thereafter check your email to claim your sign-up bonus!</p>
                                        {errorMessage ?
                                            <p className="form-error">{errorMessage}</p> : ''
                                        }
                                    </div>
                                    <Form form={form} onValuesChange={updateReferralCode} onFinish={signupUser} initialValues={{ home_address: "", state: "", lga: "" }} layout="vertical">
                                        <div className="form-flex-2">
                                            <div className="form-group space">
                                                <label htmlFor="first_name">First name</label>
                                                <Form.Item name="first_name">
                                                    <Input placeholder='First Name' style={{ height: '5rem' }} />
                                                </Form.Item>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="last_name">Last name (Surname)</label>
                                                <Form.Item name="last_name">
                                                    <Input placeholder='Last Name' style={{ height: '5rem' }} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="form-flex-2">
                                            <div className="form-group space">
                                                <label htmlFor="email">Email Address</label>
                                                <Form.Item name="email">
                                                    <Input type="email" placeholder='Email Address' style={{ height: '5rem' }} />
                                                </Form.Item>
                                            </div>
                                            <div className="form-group space">
                                                <label htmlFor="phone_number">Phone Number</label>
                                                <Form.Item name="phone_number">
                                                    <PhoneInput
                                                        country={'ng'}
                                                        value={phoneNumber}
                                                        onChange={handlePhoneChange}
                                                        containerStyle={{ width: '100%' }}
                                                        inputStyle={{ height: '5rem', width: '100%' }}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Password</label>
                                            <Form.Item name="password">
                                                <Input.Password placeholder='Password' style={{ height: '5rem' }} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <label htmlFor="referralCode">Referral code (If referred by a friend)</label>
                                                {loadingReferralDetails ?
                                                    <Button disabled loading type='text'>Loading</Button> :
                                                    referralDetails &&
                                                    <span style={{ margi: "0px", textTransform: "capitalize" }}>{referralDetails?.name}</span>
                                                }
                                            </div>
                                            <Form.Item name="referralCode">
                                                <Input placeholder='Referral code' maxLength={6} style={{ height: '5rem' }} />
                                            </Form.Item>
                                        </div>
                                        {isSo &&
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="home_address">Vendor Address</label>
                                                    <Form.Item name="home_address" rules={[{ required: isSo ? true : false, message: 'Please input Vendor Address!' }]}>
                                                        <Input placeholder='Vendor Address' style={{ height: '5rem' }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="form-flex-2">
                                                    <div className="form-group space">
                                                        <label htmlFor="state">State</label>
                                                        <Form.Item name="state" rules={[{ required: isSo ? true : false, message: 'Please input your state!' }]}>
                                                            <Select placeholder="Select State" onChange={onChangeState} name="state" style={{ width: '100%', height: '5rem' }}>
                                                                {/* {allStates.map((state, index) => (
                                                                    <Select.Option key={index} value={state}>{state}</Select.Option>
                                                                ))} */}
                                                                <Select.Option key={0} value="lagos">Lagos</Select.Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lga">LGA</label>
                                                        <Form.Item name="lga" rules={[{ required: isSo ? true : false, message: 'Please input your LGA!' }]}>
                                                            {allStates ?
                                                                <Select placeholder="Select LGA" name="lga" style={{ width: '100%', height: '5rem' }}>
                                                                    {allLgas.map((lga, index) => (
                                                                        <Select.Option key={index} value={lga}>{lga}</Select.Option>
                                                                    ))}
                                                                </Select>
                                                                :
                                                                <Select name="lga" placeholder="Select LGA" style={{ width: '100%', height: '5rem' }}></Select>
                                                            }
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div style={{ marginTop: '5%' }}></div>
                                        <label style={{ marginBottom: "10px", display: "flex", alignItems: "center", gap: "10px" }}>
                                            <b style={{ fontSize: "14px", color: "red" }}>Become a food vendor? Click here</b> <input type="checkbox" style={{ width: "20px", height: "20px" }} checked={isSo} onChange={() => { setIsSo(!isSo) }} />
                                        </label>
                                        <p style={{ fontSize: '1.3rem', display: 'block' }}> By clicking Create Account, you acknowledge you have read and agreed to our <Link to={`/terms?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>Terms of Use</Link> and <Link to={`/privacy-policy/?konnectrfc=${referralCode}&konnectr {redirectUrl}`}>Privacy Policy</Link>.</p>
                                        {
                                            appLoading || loadingReferralDetails ?
                                                <Button loading disabled style={{ height: '5.5rem', width: '100%', borderRadius: '3px', color: "red" }}>
                                                    Please be patient, sign-up in  progress
                                                </Button>
                                                :
                                                <Button htmlType="submit" className="bg_green" style={{ height: '5.5rem', width: '100%', borderRadius: '3px' }}>Create account</Button>
                                        }
                                    </Form>
                                    <div style={{ marginBottom: '20px' }}></div>
                                    <div className="">
                                        <Link style={{ fontSize: '1.3rem', display: 'block' }} to={`/signin/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}> Have an account already? <span>Sign in here</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signup2