/* eslint-disable */

import { LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Collapse, Divider, Pagination, Spin, Table, Tag, notification } from 'antd';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { _get_all_sales_orders } from '../../common/axios_services';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import Profile from '../../components/dashboard/side_nav';


const SoOrder = () => {
    const navigate = useNavigate()
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const params = new URLSearchParams(window?.location?.search);
    const [tx_ref,] = useState(params.get('tx_ref') ? params.get('tx_ref') : '');
    const [status,] = useState(params.get('status') ? params.get('status') : '');
    const [transaction_id,] = useState(params.get('transaction_id') ? params.get('transaction_id') : '');

    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const [order_his, setOrder_his] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const param = useParams()

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };

    const allTransOrders = async () => {
        setOrder_his([]);
        setLoadingData(true);
        try {
            const allOrders = await _get_all_sales_orders({ page, page_size: per_page, ref_id: param.id })
            if (allOrders.data.status === "success") {
                setOrder_his(allOrders.data.data);
                setTotal_trans(allOrders.data.meta.total);
                setLoadingData(false);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading data')
                setLoadingData(false);
            }
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setLoadingData(false);
        }
    }

    useEffect(() => {
        allTransOrders();
    }, [per_page, page, tx_ref, status, transaction_id])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPer_page(pageSize);
    }

    const order_col = [
        {
            title: 'S/N',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Delivery Date',
            dataIndex: 'date',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Items',
            className: 'column-number',
            dataIndex: 'quantity',
            align: 'center',
        },
        {
            title: 'Order (NGN)',
            className: 'column-number',
            dataIndex: 'total_amount',
            align: 'center',
        },
        {
            title: 'My Income (NGN)',
            className: 'column-number',
            dataIndex: 'my_income',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'order_status'
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center',
        },
    ]

    const shortenText = ({ text, maxLength }) => {
        return text.length <= maxLength ? text : text.slice(0, maxLength / 2 - 1) + "..." + text.slice(text.length - maxLength / 2 + 2);
    }

    let order_history_data = order_his.map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order?.delivery_date,
            quantity: order?.total_items,
            my_income: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.order_income) * 1)} />,
            total_amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.order_amount) * 1)} />,
            order_status: <div style={{ textTransform: "capitalize" }}>{order?.status === "pending" ? <Tag color="orange">{order?.status}</Tag> : <Tag color="green">{order?.status}</Tag>}</div>,
            action: <Link to={`/sales-review/${order?.transaction_ref}`}>View</Link>,
            // ref: shortenText({ text: order?_ref, maxLength: order?_ref.length }),
            // weight: order.total_weight,
            // deliveryopt: <p style={{ textTransform: "capitalize" }}>{order.delivery_opt}</p>,
            // pendingIncomeN: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.pending_order_income) * 1)} />,
            // status: <div style={{ textTransform: "capitalize" }}>{order?.payment_status === "full-payment" ? <Tag color="green">{order?.payment_status}</Tag> : order?.payment_status === "no-payment" ? <Tag color="red">{order?.payment_status}</Tag> : <Tag color="orange">{order?.payment_status}</Tag>}</div>,
        }
    });

    return (
        <>
            <div className="userprofile">
                <div className="profile-content">
                    <Spin spinning={spinnerLoading} indicator={newAntIcon}>
                        <Navbar />
                        <div className="gray_bg_profile">
                            <div className="profile_to_left">
                                <div className="">
                                    <div className="profile_nav">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            <div className="profile_to_right">
                                <div className="">
                                    <div className="contain">
                                        <div className="main_container">
                                            <div className="main_container_title_div">
                                                <h5>
                                                    <Link to="/profile/sl" style={{ marginRight: "20px" }}>
                                                        <LeftCircleOutlined style={{ fontSize: "30px", color: "black" }} />
                                                    </Link>
                                                    SO Order History
                                                </h5>
                                                <Divider style={{ margin: '0px 0 5px' }} />
                                            </div>
                                            {/* Tables */}
                                            <div>
                                                <div className="mt-2">
                                                    {loadingData ?
                                                        <div>
                                                            <Spin indicator={newAntIcon} />
                                                        </div> :
                                                        <>
                                                            <div>
                                                                <div className="desktop show-all">
                                                                    <Table scroll={{ x: true }} pagination={false} bordered columns={order_col} dataSource={order_history_data} />
                                                                    <div style={{ marginTop: "2%" }}></div>
                                                                    <Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`} defaultPageSize={per_page} onChange={change_page} />
                                                                </div>
                                                                <div className="mobile topup-data">
                                                                    <Collapse defaultActiveKey={[0]}>
                                                                        {
                                                                            order_his.map((order, index) => (
                                                                                <Collapse.Panel showArrow={false} key={index}
                                                                                    header={<p style={{ display: 'inline' }}><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.total_amount) * 1)} /></span> ordered on {order?.delivery_date}</p>}>
                                                                                    <div>
                                                                                        <ul className="mobile-list-flex">
                                                                                            <li><span>Delivery Date:</span><span>{order?.delivery_date}</span></li>
                                                                                            <li><span>Items:</span><span>{order?.total_items}</span></li>
                                                                                            <li><span>Order (NGN) <span className='currency'></span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.order_amount) * 1)} /></span></li>
                                                                                            <li><span>My Income (NGN) <span className='currency'></span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.order_income) * 1)} /></span></li>
                                                                                            <li><span>Status:</span><span>{order?.status === "pending" ? <Tag color="orange">{order?.status}</Tag> : <Tag color="green">{order?.status}</Tag>}</span></li>
                                                                                            <li><span>Action:</span><span><Link to={`/sales-review/${order?.transaction_ref}`}>View Order</Link></span></li>
                                                                                            {/* <li><span>Reference:</span><span>{shortenText({ text: order?_ref, maxLength: order?_ref.length })}</span></li> */}
                                                                                            {/* <li><span>Weight <span className='currency'>(kg)</span>:</span><span>{order.total_weight}</span></li> */}
                                                                                            {/* <li><span>Pending Income (NGN) <span className='currency'></span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.pending_order_income) * 1)} /></span></li> */}
                                                                                            {/* <li><span>Amount paid <span className='currency'></span>:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order?.total_amount_paid) * 1)} /></span></li> */}
                                                                                            {/* <li><span>Delivery option:</span><span style={{ textTransform: "capitalize" }}>{order.delivery_opt}</span></li> */}
                                                                                            {/* <li><span>Payment Status:</span><span>{order?.payment_status === "full-payment" ? <Tag color="green">{order?.payment_status}</Tag> : order?.payment_status === "no-payment" ? <Tag color="red">{order?.payment_status}</Tag> : <Tag color="orange">{order?.payment_status}</Tag>}</span></li> */}
                                                                                        </ul>
                                                                                    </div>
                                                                                </Collapse.Panel>
                                                                            ))}
                                                                    </Collapse>
                                                                    <div style={{ marginTop: "2%" }}></div>
                                                                    <Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`} defaultPageSize={per_page} onChange={change_page} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <div className="profile mobile">
                <Footer />
            </div>
        </>
    )
}

export default SoOrder;