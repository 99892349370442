import { Button, Collapse, Divider, Form, Input, Modal, Pagination, Spin, Table, Tabs, notification } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import ProfileNav from '../../components/dashboard/side_nav';

import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import GiftCard from '../../assets/images/icons/gift_card.png';
import TablerCash from '../../assets/images/icons/tabler_cash.png';
import Wise9ja from '../../assets/images/icons/wise9ja.png';
import { _check_ref, _get_all_referrals, _get_all_sa_so, _get_bonus_history, _get_profile, _get_sa_report, _invite_recuit, _sales_matrix, _user_dashboard_referrals } from '../../common/axios_services';
import { fetchData, profileData } from '../../slices/profileSlice';

const Sl = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userProfile = useSelector(state => state.userProfile.dataFetched);
    const [form] = Form.useForm();
    const [total_users, setTotal_users] = useState(0);
    const [page, setPage] = useState(1);
    const [page_size, setPage_size] = useState(10);
    const [allSaFetched, setAllSaFetched] = useState(false);
    const [allSa, setAllSa] = useState([]);
    const [recuitingSa, setRecuitingSa] = useState(false);
    const [loadingSummary, setLoadingSummary] = useState(true);
    const [salesMatrix, setSalesMatrix] = useState({});
    const [recuitSa, setRecuitSa] = useState(false)
    const [referralMessageModal, setReferralMessageModal] = useState(false);
    const [errRecuit, setErrRecuit] = useState(false);
    const [refChecked, setRefChecked] = useState(false);
    const [confirmSa, setConfirmSa] = useState(false);
    const [saRefcode, setSaRefcode] = useState(false);
    const [saReport, setSaReport] = useState(false);
    const [updateCount, setUpdateCount] = useState(0);
    const [totalOrderData, setTotalOrderData] = useState(0);

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    };
    const soColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Name of Food Vendor',
            dataIndex: 'name',
        },
        {
            title: 'Mobile',
            dataIndex: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: 'Next order date',
            dataIndex: 'nod',
        },
        {
            title: 'Order (NGN)',
            className: 'column-number',
            dataIndex: 'orderN'
        },
        {
            title: 'My income (NGN)',
            className: 'column-number',
            dataIndex: 'incomeN'
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action'
        },
    ];
    const incomeSaColumn = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Date of Sales',
            dataIndex: 'date',
        },
        {
            title: 'Name of Food Vendor',
            dataIndex: 'name',
        },
        {
            title: 'Order (NGN)',
            className: 'column-number',
            dataIndex: 'orderN',
        },
        {
            title: 'My Income (NGN)',
            className: 'column-number',
            dataIndex: 'incomeN',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action'
        },
    ];
    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            placement: 'bottom-right',
            message: title ? title : '',
            description: message ? message : "",
        });
    };
    const getAllSo = async () => {
        setAllSaFetched(true);
        try {
            const all_sa = await _get_all_sa_so({ page, page_size, search: "", from: 1, to: '' });
            setAllSaFetched(false);
            setAllSa(all_sa?.data?.data);
            setTotal_users(all_sa?.data?.meta?.total);
            setTotalOrderData(all_sa?.data?.meta?.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
            setAllSaFetched(false);
        }
    }
    const getSalesMatrix = async () => {
        setLoadingSummary(true);
        try {
            const matrix = await _sales_matrix();
            setLoadingSummary(false);
            setSalesMatrix(matrix.data.data);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllSaFetched(false);
        setAllSa([]);
    }

    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            dispatch(profileData(profile.data.data))
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/signin')
                    localStorage.removeItem('konnect_token')
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                }
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }

    useEffect(() => {
        getSalesMatrix();
        getProfile();
    }, [])
    useEffect(() => {
        getAllSo()
    }, [page, page_size, updateCount])

    let data = allSa.map((sales, index) => {
        return {
            key: index,
            sn: index + 1,
            date: new Date(sales.timestamp).toISOString().split('T')[0],
            name: `${sales?.first_name} ${sales?.last_name}`,
            phone: `${sales.phone_number}`,
            sa: sales.sa_no,
            addres: sales?.home_address,
            nod: sales?.nod,
            so: sales.so_no,
            order: sales.so_order_no,
            orderN: sales.so_order_amount,
            incomeN: sales.so_order_income,
            action: <Link to={`/sales/${sales?.id}`}>View</Link>
        }
    });
    const inviteRecuit = async () => {
        try {
            const invite_recuit = await _invite_recuit({ refCode: saRefcode })
            setRecuitingSa(false);
            setRecuitSa(false)
            form.resetFields()
            setConfirmSa(false)
            setRefChecked(false)
            openNotificationWithIcon('success', invite_recuit?.data?.title, invite_recuit?.data?.message);
        } catch (err) {
            setRecuitingSa(false);
            setErrRecuit(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    const finishSa = async (values) => {
        setRecuitingSa(true);
        try {
            const check_ref = await _check_ref({ refCode: values.refCode })
            setRecuitingSa(false);
            if (check_ref.data.status === "success") {
                setRefChecked(check_ref.data.data)
                setConfirmSa(true)
                setSaRefcode(values.refCode)
            } else {
                setErrRecuit(check_ref.data.message)
                setSaRefcode(false)
            }
        } catch (err) {
            setSaRefcode(false)
            setRecuitingSa(false);
            setRefChecked(false)
            setErrRecuit(false)
            if (err.response) {
                openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    return (
        <div className="userprofile">
            <Spin spinning={loadingSummary}>
                <div className="profile-content">
                    <Navbar />
                    <div className="gray_bg_profile">
                        <div className="profile_to_left">
                            <div className="">
                                <div className="profile_nav">
                                    <ProfileNav />
                                </div>
                            </div>
                        </div>
                        <div className="profile_to_right">
                            <div className="desktop show-all">
                                <div className="contain">
                                    <div className="main_container">
                                        <div className="main_container_title_div">
                                            <h5>Sales Report</h5>
                                            <Divider style={{ margin: '0px 0 5px' }} />
                                        </div>
                                        <div className="main_container_inner_div pb-2">
                                            <div className="profile_container">
                                                <div className="slider_div_to_top referral-data">
                                                    <Slider {...settings}>
                                                        <div key={1} className="item grid-4-box colored color_2">
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <p>Food Vendor</p>
                                                                        {/* <h2>{loadingSummary ? '0' : salesMatrix.so_no}</h2> */}
                                                                        <h2>{loadingSummary ? '0' : totalOrderData}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={2} className="item grid-4-box colored color_2">
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <p>Order</p>
                                                                        <h2>{loadingSummary ? '0' : salesMatrix.so_order_no}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={3} className="item grid-4-box colored color_3">
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={Wise9ja} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <p>Order <span className="currency">(NGN)</span></p>
                                                                        <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(salesMatrix.so_order_amount).toFixed(2)} />}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={4} className="item grid-4-box colored color_5">
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={GiftCard} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <p>My Income</p>
                                                                        <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                            displayType={'text'} value={+(salesMatrix.so_order_income)} />}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mobile px-3 referal-info">
                                <div>
                                    <h5 className="profile_mobile_title">Sales Report </h5>
                                    <div className="slider_div_to_top">
                                        <Slider {...settings}>
                                            <div key={1} className="item grid-4-box colored color_2">
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={TablerCash} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <p>Sales Outlet</p>
                                                            <h2>{loadingSummary ? '0' : totalOrderData}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={2} className="item grid-4-box colored color_2">
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={TablerCash} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <p>Order</p>
                                                            <h2>{loadingSummary ? '0' : salesMatrix.so_order_no}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={3} className="item grid-4-box colored color_3">
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={Wise9ja} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <p>Order <span className="currency">(NGN)</span></p>
                                                            <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                displayType={'text'} value={+(salesMatrix.so_order_amount).toFixed(2)} />}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={4} className="item grid-4-box colored color_5">
                                                <div className="slick-div">
                                                    <div className="slick-box">
                                                        <div className="icon-box">
                                                            <img src={GiftCard} alt="tabler cash" />
                                                        </div>
                                                        <div className="slider-main-content">
                                                            <p>My Income</p>
                                                            <h2><span className="currency">NGN </span>{loadingSummary ? '0.00' : <NumberFormat thousandSeparator={true}
                                                                displayType={'text'} value={+(salesMatrix.so_order_income)} />}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                    {/* <div className="">
                                        <div className="referral_message">
                                            <div className="mt-3">
                                                <button onClick={() => setRecuitSa(true)} className="bg_green_long no_border">Copy Referral Message</button>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {userProfile?.is_sl &&
                                <div className="main_container_inner_div">
                                    <div className="contain">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <div className="referral_message">
                                                <div className="">
                                                    {/* <button onClick={() => { setSaReport(true) }} className="bg_side_green">View my Sales Lead Dashboard</button> */}
                                                    <Link to="/profile/sl" onClick={() => { setSaReport(true) }} className="bg_side_green">View my Sales Lead Dashboard</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="contain">
                                <Tabs type='card'>
                                    <Tabs.TabPane tab="Food Vendor List" key="1">
                                        <div className="px-4 table-responsive">
                                            {!allSaFetched ?
                                                <>
                                                    <>
                                                        <div className="desktop show-all">
                                                            <Table columns={soColumns} pagination={false} dataSource={data} bordered />
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                        <div className="mobile">
                                                            <Collapse>
                                                                {
                                                                    allSa.map((sales, index) => (
                                                                        <Collapse.Panel showArrow={false} key={index}
                                                                            header={<p style={{ display: 'inline' }}>{sales?.first_name + " " + sales?.last_name} on {new Date(sales?.timestamp).toISOString().split('T')[0]}</p>}>
                                                                            <div>
                                                                                <ul className="mobile-list-flex">
                                                                                    <li>
                                                                                        <span>Date:</span><span>{new Date(sales?.timestamp).toISOString().split('T')[0]}</span>
                                                                                    </li>
                                                                                    <li><span>Name of Food Vendor:</span><span>{sales?.first_name + " " + sales?.last_name}</span></li>
                                                                                    <li><span>Mobile:</span><span>{sales?.phone_number}</span></li>
                                                                                    <li><span>Address:</span><span>{sales?.home_address}</span></li>
                                                                                    <li><span>Next order date:</span><span>{sales?.nod}</span></li>
                                                                                    {/* <li><span>Order:</span><span>{sales.so_order_no}</span></li> */}
                                                                                    <li><span>Order (NGN):</span><span>{sales.so_order_amount}</span></li>
                                                                                    <li><span>My Income (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_income).toFixed(2)} /> </span></li>
                                                                                    <li><span>Action:</span><span><Link to={`/sales/${sales?.id}`}>View</Link></span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    ))}
                                                            </Collapse>
                                                            {/* <Table columns={columns} pagination={false} dataSource={data} bordered /> */}
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger currentp={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Income statement" key="2">
                                        <div className="px-4 table-responsive">
                                            {!allSaFetched ?
                                                <>
                                                    <div>
                                                        <div className="desktop show-all">
                                                            <Table columns={incomeSaColumn} pagination={false} dataSource={data} bordered />
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>

                                                        <div className="mobile topup-data">
                                                            <Collapse defaultActiveKey={[0]}>
                                                                {
                                                                    allSa.map((sales, index) => (
                                                                        <Collapse.Panel showArrow={false} key={index}
                                                                            header={<p style={{ display: 'inline' }}>{sales?.first_name + " " + sales?.last_name} on {new Date(sales?.timestamp).toISOString().split('T')[0]}</p>}>
                                                                            <div>
                                                                                <ul className="mobile-list-flex">
                                                                                    <li>
                                                                                        <span>Date of sales:</span><span>{new Date(sales?.timestamp).toISOString().split('T')[0]}</span>
                                                                                    </li>
                                                                                    <li><span>Name of Food Vendor:</span><span>{sales?.first_name + " " + sales?.last_name}</span></li>
                                                                                    <li><span>Order (NGN):</span> <span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+sales?.so_order_no).toFixed(2)} /></span></li>
                                                                                    <li><span>My Income (NGN):</span><span><span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'}
                                                                                        value={Math.trunc(+sales?.so_order_income).toFixed(2)} /> </span></li>
                                                                                    <li><span>Action:</span><span><Link to={`/sales/${sales?.id}`}>View</Link></span></li>
                                                                                </ul>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    ))}
                                                            </Collapse>
                                                            <div style={{ marginTop: "2%" }}></div>
                                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} referrals`} defaultPageSize={page_size} onChange={change_page} />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <div className="">
                                                    <Spin />
                                                </div>
                                            }
                                        </div>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile mobile">
                    <Footer />
                </div>
            </Spin>
            <Modal centered open={recuitSa} footer={null}>
                {confirmSa && refChecked ?
                    <>
                        <div className="ref-modal">
                            <h3 className="header_summary">Verify Recuit</h3>
                            <small style={{ color: "red" }}>{errRecuit ? errRecuit : ""}</small>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h5>Account Name</h5>
                                <p>{refChecked.Account_name}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h5>Gender</h5>
                                <p>{refChecked.gender}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Button onClick={() => { setRecuitingSa(false);; setRecuitSa(false); form.resetFields(); setConfirmSa(false); setRefChecked(false) }}>Close</Button>
                                {recuitingSa ?
                                    <Button type="primary" loading>Loading</Button>
                                    :
                                    <button style={{ borderRadius: '4px', fontSize: '1.4rem', background: "green", color: "white" }} onClick={() => inviteRecuit()} className="bg_blank">Proceed</button>
                                }
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="center-text ref-modal">
                            <h3 className="header_summary">Recuit an SA</h3>
                            <small style={{ color: "red" }}>{errRecuit ? errRecuit : ""}</small>
                            <Form layout="vertical" form={form} onFinish={finishSa}>
                                <Form.Item label="SA Ref Code" name="refCode">
                                    <Input style={{ padding: "10px 20px" }} placeholder="Ref Code" />
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button onClick={() => setRecuitSa(false)}>Close</Button>
                                    <Form.Item>
                                        {recuitingSa ?
                                            <Button type="primary" loading>Loading</Button>
                                            :
                                            <button style={{ borderRadius: '4px', fontSize: '1.4rem', background: "green", color: "white" }} htmlType="submit" className="bg_blank">Proceed</button>
                                        }
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </>
                }
            </Modal>
        </div >
    )
}

export default Sl
