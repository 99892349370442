export const suggestedBanners = [
	'https://konnect-minio-api.konnectbd.com/samples/menus/%20C%2015.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%201.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%202.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%203.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%204.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%205.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%206.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%207.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%208.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%209.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2010.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2011.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2012.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2013.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2014.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2015.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2016.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2017.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2018.png',
	'https://konnect-minio-api.konnectbd.com/samples/menus/C%2019.png'
];
