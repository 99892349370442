import '../assets/css/mini.css';

import React, { useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, notification, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import * as yup from "yup";
import axios from '../common/axios_call';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const SoContact = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff', marginLeft: 20 }} spin />;

    const params = new URLSearchParams(window?.location?.search);
    const [first_name,] = useState(params.get('first_name') ? params.get('first_name') : '');
    const [last_name,] = useState(params.get('last_name') ? params.get('last_name') : '');
    const [phone,] = useState(params.get('phone') ? params.get('phone') : '');
    const [account_officer,] = useState(params.get('account_officer') ? params.get('account_officer') : '');
    const [loadMessageButton, setLoadMessageButton] = useState(false);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: '',
            placement: 'bottomRight',
            description: message,
        });
    };

    const joinUsVerification = yup.object().shape({
        firstName: yup.string().required('Please enter your first name').min(2, 'First name can not be less than 2 characters'),
        lastName: yup.string().required('Please enter your last name').min(2, 'Last name can not be less than 2 characters'),
        emailAddress: yup.string().email().required('Please enter your email address').min(5, 'Email address can not be less than 5 characters'),
        phoneNumber: yup.string().required('Please enter your phone number').min(11, 'Phone number can not be less than 11 characters'),
    })


    const { handleSubmit, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(joinUsVerification)
    });

    const handleContact = e => {
        setLoadMessageButton(true);
        let { firstName, lastName, emailAddress, phoneNumber, message, account_officer } = e;
        let joinUsMssg = {
            first_name: firstName,
            last_name: lastName,
            email: emailAddress,
            phone_number: phoneNumber,
            interrest: '',
            account_officer: account_officer,
            message
        }
        axios.post('/feed-back/contactus', joinUsMssg).then(res => {
            setLoadMessageButton(false);
            if (res.data.statusMessage === "success") {
                setValue('firstName', '');
                setValue('lastName', '');
                setValue('emailAddress', '');
                setValue('phoneNumber', '');
                setValue('message', '');
                openNotificationWithIcon('success', res.data.message);
            } else {
                openNotificationWithIcon('error', res.data.message);
            }
        }).catch(err => {
            setLoadMessageButton(false);
            openNotificationWithIcon('error', err.response.data.message);
        })
    }
    return (
        <div>
            <Navbar />
            <div className="mini_header top_header">
                <div className="mini_header_content">
                    <div className="new_flex contain contactus">
                        <div className="flex_div">
                            <div className="new_header_div_inside">
                                <div>
                                    <h3>Need help, <br />Reach out now, <br /> Click button below, or Chat with us.</h3>
                                    <p>Our team will engage with you and endeavour to resolve your request within
                                        the shortest period. Konnect is everything you need to shop, build cash & much more</p>
                                    {/* <button
                                        onClick={() => setMessageModal(true)}
                                        className="bg-green">Send us a mail</button> */}
                                    <div className="contact_page_list">
                                        <br /><p>Address: 1 Adekunle Owobiyi Close, Ikeja, Lagos, Nigeria</p>
                                        <a target='_blank' href="tel:+2348073376943">Telephone: 08073376943</a> <br />
                                        <a target='_blank' href="https://wa.me/message/VDOXKM2HBTWXF1">Whatsapp: 09071184141</a> <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex_div">
                            <div className="bg_white">
                                <form autoComplete="off" onSubmit={handleSubmit(handleContact)}>
                                    <div className="form-flex-2">
                                        <div className="form-group space">
                                            <label htmlFor="firstName">First name</label>
                                            <Controller name="firstName" defaultValue={first_name} control={control}
                                                render={
                                                    ({ field }) => (
                                                        <Input {...field} id="firstName" disabled type="text" style={{ height: '5rem' }} />
                                                    )
                                                } />
                                            {errors.firstName && <p className="error-message">{errors.firstName.message}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastName">Last name</label>
                                            <Controller name="lastName" defaultValue={last_name} control={control}
                                                render={
                                                    ({ field }) => (
                                                        <Input {...field} id="lastName" type="text" disabled style={{ height: '5rem' }} />
                                                    )
                                                } />
                                            {errors.lastName && <p className="error-message">{errors.lastName.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-flex-2">
                                        <div className="form-group">
                                            <label htmlFor="emailAddress">Email address</label>
                                            <Controller name="emailAddress"
                                                defaultValue={''} control={control}
                                                render={
                                                    ({ field }) => (
                                                        <Input type="email" id="emailAddress" {...field} style={{ height: '5rem' }} />
                                                    )} />
                                            {errors.emailAddress && <p className="error-message">{errors.emailAddress.message}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phoneNumber">Phone number</label>
                                            <Controller name="phoneNumber" defaultValue={phone} control={control}
                                                render={
                                                    ({ field }) => (
                                                        <Input type="tel" id="phoneNumber" disabled {...field} style={{ height: '5rem' }} />
                                                    )} />
                                            {errors.phoneNumber && <p className="error-message">{errors.phoneNumber.message}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="account_officer">Account Officer</label>
                                        <Controller name="account_officer" defaultValue={account_officer} control={control}
                                            render={
                                                ({ field }) => (
                                                    <Input {...field} id="account_officer" disabled style={{ height: '5rem' }} />
                                                )
                                            } />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Message</label>
                                        <Controller name="message" defaultValue="" control={control}
                                            render={
                                                ({ field }) => (
                                                    <Input.TextArea rows={4} {...field} id="message" />
                                                )
                                            } />
                                        {errors.message && <p className="error-message">{errors.message.message}</p>}
                                    </div>
                                    <div style={{ marginTop: '7%' }}></div>
                                    <div className="">
                                        {
                                            !loadMessageButton ?
                                                <button style={{ display: 'block', width: "100%", height: '5rem' }} className="bg_green_long no_border">Send message</button>
                                                :
                                                <button disabled style={{ display: 'block', width: "100%", height: '5rem' }} className="bg_green_long no_border" id="modal-button">Sending message. Please wait.<Spin indicator={antIcon} /></button>
                                        }
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer noSpace={true} />
        </div>
    )
}

export default SoContact;