import '../../assets/css/landing.css';


import { Link } from 'react-router-dom';
import SideImage from '../../assets/images/kyc.png';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const KYCLanding = () => {
    return (
        <>
            <Navbar disapear={true} />
            {/* Hero Section */}
            <div className='landing_hero' style={{ width: "100%", backgroundColor: "#f1fef4", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0 2%" }} className='grid_flex'>
                    <div>
                        <h3 style={{ color: "rgba(0,0,0,.85)", fontSize: "5rem", fontFamily: "Gilroy Bold", marginBottom: "2rem" }}>Upgrade to Tier 2 and enjoy the following.</h3>
                    </div>
                    <div style={{ display: "block" }}>
                        <img className='sideImg' src={SideImage} alt="" style={{ margin: "0 auto", display: "block" }} />
                    </div>
                </div>
            </div>
            {/* Main section */}
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Tier 2 wallet is a dedicated NUBAN account.</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Same day settlement when you fund your wallet.</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>0% transfer charges to other wallet.</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Recharge your bills and get 8% cash bonus.</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Extra cash discount to buy food stuffs (food vendors only).</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <Link className='joinus_button' style={{ margin: "auto" }} to="/profile/complete-kyc">CONTINUE</Link>
                    </div>
                </div>
            </div>
            <Footer noSpace={true} />
        </>
    )
}

export default KYCLanding;