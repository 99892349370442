import React, {useEffect} from 'react'
import {Button, Col, Divider, Drawer, Empty, Form, Image, Input, InputNumber, message, notification, Pagination, Row, Select, Space, Spin, Table, Tabs, Tag, Upload} from 'antd'
import {CloseOutlined, LoadingOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons'

import RestaurantSideNav from '../../components/dashboard/restaurant_side_nav'
import Navbar from '../../components/Navbar'
import FoodCard from '../../components/Restaurant/Menu/FoodCard'
import {useNavigate} from 'react-router-dom'
import {_add_extra, _add_menu, _delete_menu_image, _get_extras, _get_menu, _update_extra, _update_menu} from '../../common/axios_services'
import MobileDashTabs from '../../components/dashboard/MobileDashTabs'
import {useDispatch, useSelector} from 'react-redux'
import {update_selected_menu, update_visibility} from '../../slices/restaurantSlice'

const RestaurantMenu = () => {
	const {TabPane} = Tabs;
	const [form] = Form.useForm();
	const [extraForm] = Form.useForm();
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const newAntIcon = <LoadingOutlined style={{fontSize: 24, color: '#008060'}} spin />;
	const openNotificationWithIcon = (type, title, message) => {
		notification[type]({message: title, description: message, placement: "bottom-right"});
	};

	// States
	const selectedMenu = useSelector(state => state.restaurant.selectedMenu)
	const visible = useSelector(state => state.restaurant.visible)
	const [spinnerLoading, setSpinnerLoading] = React.useState(false);
	const [openExtraDrawer, setOpenExtraDrawer] = React.useState(false);
	const [previewOpen, setPreviewOpen] = React.useState(false);
	const [addingExtra, setAddingExtra] = React.useState(false);
	const [addingMenu, setAddingMenu] = React.useState(false);
	const [previewImage, setPreviewImage] = React.useState('');
	const [price, setPrice] = React.useState('');
	const [extraPrice, setExtraPrice] = React.useState('');
	const [discount, setDiscount] = React.useState('');
	const [extraImage, setExtraImage] = React.useState(null);
	const [selectedCategoriesTags, setSelectedCategoriesTags] = React.useState([]);
	const [selectedExtras, setSelectedExtras] = React.useState([]);
	const [extras, setExtras] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [per_page, setPerPage] = React.useState(10)
	const [menuTotal, setMenuTotal] = React.useState(0)
	const [extraPage, setExtraPage] = React.useState(1);
	const [extraPageSize, setExtraPageSize] = React.useState(10);
	const [totalExtra, setTotalExtra] = React.useState(0);
	const [search, setSearch] = React.useState('')
	const [availabilityFilter, setAvailabilityFilter] = React.useState('all');
	const [selectedExtra, setSelectedExtra] = React.useState(null);
	const [updateCount, setUpdateCount] = React.useState(1)
	const [menus, setMenus] = React.useState([
		{
			name: 'Fried Rice & Chicken',
			description: '1 portion Fried Rice and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: false,
			imageUrl: 'https://via.placeholder.com/300x200?text=Fried+Rice+%26+Chicken',
		},
		{
			name: 'Jollof Rice & Chicken',
			description: '1 portion Jollof Rice and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: true,
			imageUrl: 'https://via.placeholder.com/300x200?text=Jollof+Rice+%26+Chicken',
		},
		{
			name: 'Pasta & Chicken',
			description: '1 portion Pasta and Chicken',
			price: '₦1000',
			originalPrice: '₦1200',
			available: true,
			imageUrl: 'https://via.placeholder.com/300x200?text=Pasta+%26+Chicken',
		},
		{
			name: 'Spaghetti Bolognese',
			description: '1 portion Spaghetti Bolognese',
			price: '₦1000',
			originalPrice: '₦1200',
			available: false,
			imageUrl: 'https://via.placeholder.com/300x200?text=Spaghetti+Bolognese',
		}
	])
	const [fileList, setFileList] = React.useState([
		{
			uid: '-1',
			name: 'image.png',
			status: 'done',
			thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		},
		{
			uid: '-2',
			name: 'image.png',
			status: 'done',
			thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
		}
	]);
	const menuUrls = fileList.filter(file => file.thumbUrl).map(file => file.thumbUrl);
	const categoriesTags = ['Appetiser', 'Rice dishes', 'Swallow', 'Bean dishes', 'Soups', 'Confectioneries', 'Specials', 'Extras', 'Drinks'];

	// Extra column
	const extraColumns = [
		{
			title: 'Image',
			dataIndex: 'image',
			key: 'image',
			render: (text) => <img src={text} alt="extra" style={{width: 50, height: 50}} />,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Description',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Price',
			dataIndex: 'price',
			key: 'price',
		},
		{
			title: 'Quantity Available',
			dataIndex: 'qty_available',
			key: 'qty_available',
		},
		{
			title: 'Quantity Sold',
			dataIndex: 'qty_sold',
			key: 'qty_sold',
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Button type="link" onClick={() => editExtra(record.id)}>Edit</Button>
			),
		},
	];

	// TODO: Implement the logic to edit the extra
	const editExtra = (extraId) => {
		const selected = extras.find((extra) => extra.id === extraId);  // Find the selected extra by ID
		setSelectedExtra(selected);  // Set the selected extra for editing

		// Load the form with the extra details
		extraForm.setFieldsValue({
			name: selected.name,
			description: selected.description,
			price: selected.price,
			qty_available: selected.qty_available,
		});

		// Set the image and open the drawer
		setExtraImage(selected.image);
		setOpenExtraDrawer(true);
	};

	// Functions
	const drawerVisibility = () => {
		dispatch(update_visibility(!visible));
		form.resetFields();
		if (selectedMenu) {
			dispatch(update_selected_menu(null));
			setFileList([]);
			setSelectedExtras([]);
		};
	};

	const extraDrawerVisibility = () => {
		setOpenExtraDrawer(!openExtraDrawer);
		extraForm.resetFields();
		if (selectedExtra) {
			setSelectedExtra(null);
			setExtraImage(null)
		};
	}

	const getExtraBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.readAsDataURL(img);
		reader.addEventListener('load', () => callback(reader.result));
	}

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	const changeImage = ({fileList: newFileList}) => setFileList(newFileList);

	const changeExtra = info => {
		getExtraBase64(info.file.originFileObj, imageUrl =>
			setExtraImage(imageUrl),
		);
		const reader = new FileReader();

		reader.readAsDataURL(info.file.originFileObj);
		reader.addEventListener('load', () => {});
	};

	const preExtraImageUpload = (file) => {
		const isImage = file.type.startsWith('image/');
		if (!isImage) {
			message.error('You can only upload image files!');
			return Upload.LIST_IGNORE;
		}

		const isLt5MB = file.size / 1024 / 1024 < 5;
		if (!isLt5MB) {
			message.error('Image must be smaller than 5MB!');
			return Upload.LIST_IGNORE;
		}

		return true;
	};

	const uploadButton = (
		<button style={{border: 0, background: 'none'}} type="button">
			<PlusOutlined />
			<div style={{marginTop: 8}}>Upload</div>
		</button>
	);

	// Handle price change and update form state
	const handlePriceChange = (value) => {
		let numericValue = value.replace(/[^\d.]/g, '');

		// Set state and update form field
		setPrice(numericValue);
		form.setFieldsValue({price: numericValue});
	};

	const handleExtraPriceChange = (value) => {
		let numericValue = value.replace(/[^\d.]/g, '');

		// Set state and update form field
		setExtraPrice(numericValue);
		extraForm.setFieldsValue({price: numericValue});
	};

	// Handle discount change and update form state
	const handleDiscountChange = (value) => {
		let numericValue = value.replace(/[^\d.]/g, '');

		// Set state and update form field
		setDiscount(numericValue);
		form.setFieldsValue({discount: numericValue});
	};

	// Optional: Handle formatting to two decimal places on blur
	const handlePriceBlur = () => {
		if (price) {
			const formattedPrice = parseFloat(price).toFixed(2);
			setPrice(formattedPrice);
			form.setFieldsValue({price: formattedPrice});
		}
	};

	const handleExtraPriceBlur = () => {
		if (extraPrice) {
			const formattedPrice = parseFloat(extraPrice).toFixed(2);
			setExtraPrice(formattedPrice);
			extraForm.setFieldsValue({price: formattedPrice});
		}
	};

	const handleDiscountBlur = () => {
		if (discount) {
			const formattedDiscount = parseFloat(discount).toFixed(2);
			setDiscount(formattedDiscount);
			form.setFieldsValue({discount: formattedDiscount});
		}
	};

	// const checkCategoriesTag = (tag, checked) => {
	// 	if (checked) {
	// 		setSelectedCategoriesTags(tag);
	// 		form.setFieldsValue({categories: tag});
	// 	} else {
	// 		setSelectedCategoriesTags([]);
	// 		form.setFieldsValue({categories: []});
	// 	}
	// };

	const handleExtraSelect = (extraId, checked) => {
		const nextSelectedExtras = checked
			? [...selectedExtras, extraId]
			: selectedExtras.filter((id) => id !== extraId);
		setSelectedExtras(nextSelectedExtras);
		form.setFieldsValue({extras: nextSelectedExtras});
	};

	const onFinish = async (values) => {
		try {
			setAddingMenu(true)
			const data = {
				images: fileList.filter(file => file.thumbUrl).map(file => file.thumbUrl) || [''],
				name: values.menuName,
				description: values.description || '',
				price: +values.price,
				discount: +values.discount || 0.00,
				categories: values.categories ? [values.categories] : [],
				extras: values.extras || [''],
				qty_available: values.qty_available || '1'
			}

			let response;
			if (selectedMenu) {
				response = await _update_menu(selectedMenu.id, data);
				openNotificationWithIcon('success', response?.data?.title, response?.data?.message);
				dispatch(update_selected_menu(null));
			} else {
				response = await _add_menu(data);  // Assuming _add_menu is your create API function
				openNotificationWithIcon('success', response?.data?.title, response?.data?.message);
			}
			form.resetFields();
			drawerVisibility();
			setUpdateCount(updateCount + 1)
		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setAddingMenu(false)
		}
	};

	const onFinishExtra = async (values) => {
		const data = {
			image: extraImage || '',
			name: values.name,
			description: values.description || '',
			price: +values.price,
			qty_available: values.qty_available,
		};
		try {
			setAddingExtra(true);
			let response;

			if (selectedExtra) {
				response = await _update_extra(selectedExtra.id, data);
				openNotificationWithIcon('success', response?.data?.title, response?.data?.message);
				setSelectedExtra(null);
			} else {
				response = await _add_extra(data);
				openNotificationWithIcon('success', response?.data?.title, response?.data?.message);
			}
			extraForm.resetFields();
			extraDrawerVisibility();
		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setAddingExtra(false);
		}
	};

	const getExtras = async () => {
		try {
			setSpinnerLoading(true);
			const extras = await _get_extras({search: '', page: extraPage, per_page: extraPageSize})
			setExtras(extras.data.data)
			setTotalExtra(extras.data.meta.total)
		}
		catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setSpinnerLoading(false);
		}
	}

	const getMenus = async () => {
		try {
			setSpinnerLoading(true);
			const get_menus = await _get_menu({search, page, per_page})
			setMenus(get_menus.data.data)
			setMenuTotal(get_menus.data.meta.total)
		}
		catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setSpinnerLoading(false);
		}
	}

	const handlePageChange = (page, pageSize) => {
		setPage(page);
		setPerPage(pageSize);
	};

	const onShowSizeChange = (current, size) => {
		setPerPage(size);
	};

	const handleExtraPageChange = (page, pageSize) => {
		setExtraPage(page);
		setExtraPageSize(pageSize);
	};

	const onShowExtraSizeChange = (current, size) => {
		setExtraPageSize(size);
	};

	const handleAvailabilityChange = (value) => {
		setAvailabilityFilter(value);
	};

	const handleRemoveImage = async (file) => {
		if (selectedMenu) {
			try {
				const imageId = file.uid;
				const delete_menu_image = await _delete_menu_image(selectedMenu.id, imageId)
				openNotificationWithIcon('success', delete_menu_image.data.data.title, delete_menu_image.data.data.message)
				setUpdateCount(updateCount + 1)
			} catch (err) {
				if (err.response) {
					if (err.response.data.message === "Unauthorized") {
						localStorage.removeItem('konnect_token')
						navigate('/signin')
					}
					openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
				} else {
					openNotificationWithIcon('error', err.message)
				}
			}
		}
	};

	// When loading a menu for editing, map its images to the fileList format
	useEffect(() => {
		if (selectedMenu && (selectedMenu?.images?.length > 0)) {
			const mappedFileList = selectedMenu.images.map((url, index) => ({
				uid: `${url?.id}`,
				name: `${url?.name}`,
				status: 'done',
				thumbUrl: url?.url,
				url: url?.url,
			}));

			setFileList(mappedFileList);

			form.setFieldsValue({
				menuName: selectedMenu.name,
				price: selectedMenu.price,
				discount: selectedMenu.discount,
				categories: (selectedMenu.category && (selectedMenu.category.length > 0)) ? selectedMenu.category[0].category_id : '',
				description: selectedMenu.description,
				qty_available: selectedMenu.qty_available,
			});
		} else if (selectedMenu && selectedMenu.images.length === 0) {
			setFileList([]);

			form.setFieldsValue({
				menuName: selectedMenu.name,
				price: selectedMenu.price,
				discount: selectedMenu.discount,
				categories: (selectedMenu.category && (selectedMenu.category.length > 0)) ? selectedMenu.category[0].category_id : '',
				description: selectedMenu.description,
				qty_available: selectedMenu.qty_available,
			});
		}
	}, [selectedMenu]);

	useEffect(() => {
		getExtras()
	}, [extraPage, extraPageSize])

	useEffect(() => {
		getMenus()
	}, [page, per_page, updateCount])

	return (
		<div className="userprofile">
			<Spin spinning={spinnerLoading} indicator={newAntIcon}>
				<div className="profile-content">
					<Navbar />
					<div className="gray_bg_profile">
						<div className="profile_to_left">
							<div className="">
								<div className="profile_nav">
									<RestaurantSideNav />
								</div>
							</div>
						</div>
						<div className="profile_to_right">
							<div className="contain">
								<div className="main_container">
									<MobileDashTabs />
									<div className="main_container_title_div">
										<div className="add-menu-button-container">
											<h5 className='mt-0'>My Menu</h5>
											<div className='flex gap-4 items-center'>
												{/* <Button type="primary" onClick={extraDrawerVisibility} className="add-menu-button" icon={<PlusOutlined />} shape="round">Add Extras</Button> */}
												<Button type="primary" onClick={drawerVisibility} className="add-menu-button" icon={<PlusOutlined />} shape="round">Add Menu</Button>
											</div>
										</div>
										<Divider style={{margin: '0px 0 5px'}} />
									</div>
									{menuTotal === 0 ? (
										<Empty description="No menu items available. Create a new menu.">
											<Button type="primary" onClick={drawerVisibility} className="add-menu-button mx-auto rounded-xl" icon={<PlusOutlined />}>Create Menu</Button>
										</Empty>
									) : (
										<>
											<div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
												<div className="w-full md:w-1/3 mb-2 md:mb-0">
													<Select
														defaultValue="all"
														onChange={handleAvailabilityChange}
													>
														<Select.Option value="all">All</Select.Option>
														<Select.Option value="available">Available</Select.Option>
														<Select.Option value="not_available">Not Available</Select.Option>
													</Select>
												</div>
												<Input
													placeholder="Search menu"
													prefix={<SearchOutlined />}
													value={search}
													onChange={(e) => setSearch(e.target.value)}
													className="w-full md:w-1/3 py-3"
												/>
											</div>

											<Row gutter={[16, 16]}>
												{menus.map((menu, index) => (
													<Col xs={12} sm={12} md={8} lg={6} key={index}>
														<FoodCard food={menu} />
													</Col>
												))}
											</Row>
											<Pagination
												showSizeChanger
												onShowSizeChange={onShowSizeChange}
												defaultCurrent={1}
												total={menuTotal}
												current={page}
												pageSize={per_page}
												onChange={handlePageChange}
												className='mt-4'
											/>
										</>
									)}
									{/* <TabPane tab="Extras" key="2">
											{extras.length === 0 ? (
												<Empty description="No extras available. Create a new extra.">
													<Button type="primary" onClick={extraDrawerVisibility} className="add-menu-button mx-auto rounded-xl" icon={<PlusOutlined />}>Create Extra</Button>
												</Empty>
											) : (
												<>
													<Table
														columns={extraColumns}
														dataSource={extras}
														pagination={false}
														rowKey="id"
													/>
													<Pagination
														showSizeChanger
														onShowSizeChange={onShowExtraSizeChange}
														defaultCurrent={1}
														total={totalExtra}
														current={extraPage}
														pageSize={extraPageSize}
														onChange={handleExtraPageChange}
													/>
												</>
											)}
										</TabPane> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Spin >

			{/* Extra Drawer */}
			{/* <Drawer title={selectedExtra ? "Edit Extra" : "New Extra"} placement="right" onClose={extraDrawerVisibility} open={openExtraDrawer} width={500} extra={<Space><CloseOutlined onClick={extraDrawerVisibility} /></Space>}>
				<div className="new-menu-drawer">
					<Form form={extraForm} layout="vertical" onFinish={onFinishExtra}
						initialValues={{
							name: selectedExtra?.name || '',
							description: selectedExtra?.description || '',
							price: selectedExtra?.price || '',
							qty_available: selectedExtra?.qty_available || 1,
						}}>
						<Form.Item label="Upload Extra Image">
							<Upload name="avatar" listType="picture-card" showUploadList={false} multiple={false} onChange={changeExtra} beforeUpload={preExtraImageUpload} >
								{extraImage ? <img src={extraImage} alt="avatar" style={{height: '100%', objectFit: 'cover'}} className="adminCourseUpload" /> : uploadButton}
							</Upload>
						</Form.Item>

						<Form.Item label="Name" name="name" rules={[{required: true, message: "Please fill input is required"}]}>
							<Input placeholder="Enter extra name" />
						</Form.Item>

						<Form.Item label="Quantity Available" name="qty_available" rules={[{required: true, message: "Please fill input is required"}]}>
							<InputNumber min={1} className='w-full' placeholder="Enter extra quantity available" />
						</Form.Item>

						<Form.Item label="Price" name="price" rules={[{required: true, message: "Please fill input is required"}]}>
							<Input prefix="NGN" value={price} onChange={(e) => handleExtraPriceChange(e.target.value)} onBlur={handleExtraPriceBlur} placeholder="Enter price" />
						</Form.Item>

						<Form.Item label="Description" name="description">
							<Input.TextArea placeholder="Enter Description" rows={4} />
						</Form.Item>

						<Form.Item>
							{addingExtra ?
								<button className="menu-update-btn bg-gray-300 hover:bg-gray-300 hover:text-gray-500 text-gray-500 cursor-not-allowed border-none px-10 py-3 rounded-xl flex items-center justify-center tracking-wider transition-colors" disabled >{selectedExtra ? 'Editing Extra' : 'Adding Extra'}.<Spin style={{marginLeft: '10px'}} indicator={newAntIcon} /> </button> :
								<button type="submit" className="menu-update-btn bg-blue-500 text-white hover:bg-blue-600 border-none px-10 py-3 rounded-xl tracking-wider transition-colors">{selectedExtra ? 'Edit Extra' : 'Add Extra'}</button>
							}
						</Form.Item>
					</Form>
				</div>
			</Drawer> */}
			{/* Menu Drawer */}
			<Drawer title={selectedMenu ? "Edit Menu" : "New Menu"} placement="right" onClose={drawerVisibility} open={visible} width={500} extra={<Space><CloseOutlined onClick={drawerVisibility} /></Space>}>
				<div className="new-menu-drawer">
					{/* Upload Menu Images */}
					<Form form={form} layout="vertical" onFinish={onFinish}>
						<Form.Item label="Upload Menu Images">
							<Upload
								action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
								listType="picture-card"
								fileList={fileList}
								onPreview={handlePreview}
								onChange={changeImage}
								onRemove={(file) => handleRemoveImage(file)}
							>
								{fileList.length >= 8 ? null : uploadButton}
							</Upload>
							{previewImage && (
								<Image
									wrapperStyle={{display: 'none'}}
									preview={{
										visible: previewOpen,
										onVisibleChange: (visible) => setPreviewOpen(visible),
										afterOpenChange: (visible) => !visible && setPreviewImage(''),
									}}
									src={previewImage}
								/>
							)}
						</Form.Item>

						{/* Select Category */}
						<Form.Item label="Select Categories" name="categories" rules={[{required: true, message: 'Please select the categories that need to be attached!'}]}>
							{/* Make this tag select only one instead of multiple */}
							{/* {categoriesTags.map((tag) => (
								<Tag.CheckableTag
									key={tag}
									checked={selectedCategoriesTags === tag}
									onChange={(checked) => checkCategoriesTag(tag, checked)}
									className={`m-2 p-3 rounded-xl ${selectedCategoriesTags === tag ? 'bg-blue-500 text-white' : 'bg-gray-100 text-black hover:bg-gray-200'}`}
								>
									{tag}
								</Tag.CheckableTag>

							))} */}

							<Select
								placeholder="Select a category"
								value={selectedCategoriesTags}
								onChange={setSelectedCategoriesTags}  // Update the selected category
								options={categoriesTags.map((tag) => ({label: tag, value: tag}))} // Map categories to Select options
								style={{width: '100%'}}  // Make it full-width if needed
							/>
						</Form.Item>

						{/* Menu Name */}
						<Form.Item label="Menu Name" name="menuName" rules={[{required: true, message: "Please fill input is required"}]}>
							<Input placeholder="Enter menu name" />
						</Form.Item>

						<Form.Item label="Quantity Available" name="qty_available" rules={[{required: true, message: "Please fill input is required"}]}>
							<InputNumber min={0} className='w-full' placeholder="Enter extra quantity available" />
						</Form.Item>

						{/* Price and Discount */}
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Price" name="price" rules={[{required: true, message: "Please fill input is required"}]}>
									<Input prefix="NGN" value={price} onChange={(e) => handlePriceChange(e.target.value)} onBlur={handlePriceBlur} placeholder="Enter price" />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Discount %" name="discount">
									<Input suffix="%" value={discount} onChange={(e) => handleDiscountChange(e.target.value)} onBlur={handleDiscountBlur} placeholder="Enter discount" />
								</Form.Item>
							</Col>
						</Row>

						{/* Extras Section */}
						{/* <Form.Item label="Extras" name="extras">
							{extras.map((extra) => (
								<Tag.CheckableTag
									key={extra.id}
									checked={selectedExtras.includes(extra.id)}  // Logic to check if selected
									onChange={(checked) => handleExtraSelect(extra.id, checked)}
									className={`m-2 p-3 rounded-xl ${selectedExtras.includes(extra.id)
										? 'bg-blue-500 text-white'
										: 'bg-gray-100 text-black hover:bg-gray-200'
										}`}
								>
									<img src={extra.image} alt={extra.name} className='w-10 mr-3' />
									{extra.name} - NGN {extra.price}
								</Tag.CheckableTag>
							))}
						</Form.Item> */}

						{/* Description */}
						<Form.Item label="Description" name="description">
							<Input.TextArea placeholder="Description" rows={3} />
						</Form.Item>

						{/* Submit Button */}
						<Form.Item>
							{addingMenu ?
								<button className="menu-update-btn bg-gray-300 hover:bg-gray-300 hover:text-gray-500 text-gray-500 cursor-not-allowed border-none px-10 py-3 rounded-xl flex items-center justify-center tracking-wider transition-colors" disabled >{selectedMenu ? "Editing Menu" : "Adding Menu"} <Spin style={{marginLeft: '10px'}} indicator={newAntIcon} /> </button> :
								<button type="submit" className="menu-update-btn bg-blue-500 text-white hover:bg-blue-600 border-none px-10 py-3 rounded-xl tracking-wider transition-colors">{selectedMenu ? "Edit Menu" : "Add Menu"}</button>
							}
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div >
	)
}

export default RestaurantMenu
