import '../assets/css/form.css';

import { Link } from 'react-router-dom';
import { ReactComponent as Checkmark } from '../assets/images/checkmark.svg';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const SignupSuccessful = () => {
    return (
        <div className="form-pages">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages center-text emailverify">
                    <div className="">
                        <div className="form auth">
                            <div className="form-text">
                                <Checkmark />
                                <h4>Welcome to konnect, the wealthy place.</h4>
                                <p>Congrats!!! Your account has been verified signin.</p>
                                <Link
                                    style={{
                                        display: 'block', margin: '0px auto', fontSize: '1.4rem', width: 'max-content',
                                        marginTop: 30
                                    }}
                                    to="/signin">Signin</Link>
                            </div>
                            <div style={{ marginBottom: '20px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages center-text emailverify">
                        <div className="div_bg_white">
                            <div className="form aut">
                                <div className="form-text">
                                    <Checkmark />
                                    <h4>Welcome to konnect, the wealthy place.</h4>
                                    <p>Congrats!!! Your account has been verified signin.</p>
                                    <Link
                                        style={{
                                            display: 'block', margin: '0px auto', fontSize: '1.5rem', width: 'max-content',
                                            marginTop: 30
                                        }}
                                        to="/signin">Signin</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SignupSuccessful;