import {createSlice} from "@reduxjs/toolkit";

// slice to save slected menu item
export const restaurantSlice = createSlice({
	name: "restaurant",
	initialState: {
		selectedMenu: null,
		visible: false,
	},
	reducers: {
		update_selected_menu: (state, action) => {
			state.selectedMenu = action.payload;
		},
		update_visibility: (state, action) => {
			state.visible = action.payload;
		},
	},
});

// Export the action
export const {update_selected_menu, update_visibility} = restaurantSlice.actions;

// Export the reducer to use in the store
export default restaurantSlice.reducer;