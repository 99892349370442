import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import { ArrowDownOutlined } from '@ant-design/icons';


const BecomeRestaurant = () => {
    const navigate = useNavigate();

  return (
    <div className='bg-[#FDF4D7] py-10 px-10 md:px-28  w-full '>
        <span >
            <Link to={`/`}>
                <img src={Logo}  className="logo w-[70%] md:h-1/5 md:w-1/5 " alt="konnect logo" />
            </Link>
        </span>
        <p className='my-4 mt-5  font-bold text-5xl'>
            Welcome to Konnect restaurant, powered by Consukon Limited.
        </p>
        <p className='my-4'>
            Please complete the onboarding process and we aim to approve your restaurant in 3 working days.
        </p>
        <p className='my-4'>
            Our approach is to market your menu to our customers to optimise sales and achieve on-time delivery or pickup.
        </p>
        <p className='my-4'>
            Our goal is to grow your food business as well as satisfy our customers.
        </p>
        <p className='my-4'>
            We will provide you with a dedicated Support Manager that will support your growth to meet monthly sales of above NGN 3M.
        </p>
        <p>To start click profile button below <ArrowDownOutlined style={{ color: "#258635" }}  /> </p>
        <div className="my-10">

            <button className='rounded-full bg-transparent py-3 px-24 border-2 border-black text-black text-2xl' onClick={() => navigate("/restaurant/profile")} >
                Profile
            </button>

        </div>

    </div>
  )
}

export default BecomeRestaurant