import React from 'react'
import Slider from "react-slick";
import NumberFormat from 'react-number-format';

// images
import {InvoiceIcon, MessageIcon, PendingOrder, VolumeUpIcon} from '../../../common/utils/Icons'

const InfoSlider = () => {
	const [currency,] = React.useState("NGN")

	let settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		swipeToSlide: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
					centerMode: true,
					focusOnSelect: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 1,
					centerMode: true,
					focusOnSelect: true
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					focusOnSelect: true
				}
			}
		]
	};

	return (
		<div className="main_container_inner_div">
			<div className="profile_container">
				<div className="slider_div_to_top">
					<Slider {...settings}>
						<div key={1} className="item grid-4-box colored color_5 loyalty_card">
							<div className="slick-div">
								<div className="slick-box">
									<div className="icon-box">
										<img src={PendingOrder} alt="Pending Order" />
									</div>
									<div className="slider-main-content">
										<p>Pending Order</p>
										<h2>
											<NumberFormat thousandSeparator={true} displayType={'text'} value={5} />
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div key={2} className="item grid-4-box colored color_2 loyalty_card">
							<div className="slick-div">
								<div className="slick-box">
									<div className="icon-box">
										<img src={InvoiceIcon} alt="Invoice Icon" />
									</div>
									<div className="slider-main-content">
										<div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
											<p>Today</p>
										</div>
										<h2>
											<NumberFormat thousandSeparator={true} displayType={'text'} value={24} />
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div key={3} className="item grid-4-box colored color_3 loyalty_card">
							<div className="slick-div">
								<div className="slick-box">
									<div className="icon-box">
										<img src={VolumeUpIcon} alt="Volume Icon" />
									</div>
									<div className="slider-main-content">
										<div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
											<p>Today {currency}</p>
										</div>
										<h2>
											<span style={{marginRight: "3px"}} className="currency">₦</span>
											<NumberFormat thousandSeparator={true} displayType={'text'} value={53000} />
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div key={4} className="item grid-4-box colored color_1 loyalty_card">
							<div className="slick-div">
								<div className="slick-box">
									<div className="icon-box">
										<img src={MessageIcon} alt="Volume Icon" />
									</div>
									<div className="slider-main-content">
										<div style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
											<p>Sales till date {currency}</p>
										</div>
										<h2>
											<span style={{marginRight: "3px"}} className="currency">₦</span>
											<NumberFormat thousandSeparator={true} displayType={'text'} value={1234500} />
										</h2>
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</div>
		</div>
	)
}

export default InfoSlider