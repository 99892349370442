import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Steps,
  notification,
} from "antd";
import moment from "moment";
import NaijaStates from "naija-state-local-government";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  _calc_commission,
  _get_profile,
  _get_user_wallet_account,
  _update_profile,
  _verfy_account_datas,
  _verify_user_info,
} from "../common/axios_services";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import AlertModal from "../components/modals/AlertModal";
import { fetchData, profileData } from "../slices/profileSlice";
import Profile from "../components/dashboard/side_nav";
import PhoneInput from "react-phone-input-2";

const CompleteKyc = () => {
  const [form] = Form.useForm();
  const [docsform] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileInfo = useSelector((state) => state.userProfile.dataFetched);

  const [countryCode, setCountryCode] = useState(
    profileInfo?.phone_code || profileInfo?.user?.phone_code
  );
  const [phoneNumber, setPhoneNumber] = useState(
    profileInfo?.phone_number || profileInfo?.user?.phone_number
  );

  const [updateCount, setUpdateCount] = useState(0);
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [profileErr, setProfileErr] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [verifyAccount, setVerifyAccount] = useState(false);
  const [allLgas, setAllLgas] = useState(
    profileInfo?.state ? NaijaStates.lgas(`${profileInfo?.state}`).lgas : []
  );
  const [allStates] = useState(NaijaStates.states() || []);

  const [seconds, setSeconds] = useState(10);
  const [isActive, setIsActive] = useState(false);
  const [errorModal, setErrorModal] = useState(
    profileInfo?.kyc_status === "error"
  );

  const [timeLeft, setTimeLeft] = useState(
    profileInfo?.kyc_status === "rejected" ? "0 second" : false
  );
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [tierCharge, setTierCharge] = useState(null);
  const [walletBalance, setWalletBalance] = useState(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (profileInfo?.kyc_status === "rejected") {
        const targetTime = moment(profileInfo?.email_token_timestamp);
        const currentTime = moment();
        const duration = moment.duration(targetTime.diff(currentTime));

        if (duration.asMilliseconds() <= 0) {
          setTimeLeft(false);
          return;
        }

        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let timeLeftString = "";

        if (hours > 0) {
          timeLeftString += `${hours} hour${hours > 1 ? "s" : ""} `;
        }
        if (minutes > 0) {
          timeLeftString += `${minutes} minute${minutes > 1 ? "s" : ""} `;
        }
        if (seconds > 0) {
          timeLeftString += `${seconds} second${seconds > 1 ? "s" : ""}`;
        }

        setTimeLeft(timeLeftString.trim());
      }
    };

    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(intervalId);
  }, [profileInfo]);

  useEffect(() => {
    let interval = null;
    if (profileInfo?.kyc_status === "pending") {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);

      if (seconds === 0) {
        setIsActive(true);
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);
  }, [seconds, profileInfo]);

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      placement: "bottomRight",
      message: title || "",
      description: message || "",
    });
  };

  const onChangeState = (value) => {
    if (value) {
      form.setFieldsValue({ lga: "" });
      const allLGAsinAState = NaijaStates.lgas(`${value}`);
      setAllLgas(allLGAsinAState.lgas);
    }
  };

  const fetchUser = async () => {
    try {
      const user_profile = await _get_profile();
      dispatch(fetchData(true));
      dispatch(profileData(user_profile?.data?.data));
      if (user_profile?.data?.data?.tier === "tier_2") {
        navigate("/profile/loyalty");
      }
      setImageUrl(user_profile?.data?.data?.image);
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          navigate(`/signin?redir=loyalty`);
        }
        dispatch(profileData(false));
        dispatch(fetchData(false));
      } else {
        openNotificationWithIcon("error", "Something went wrong", err.message);
      }
    }
  };

  const removeCountryCode = (phoneCode, phoneNumber) => {
    if (phoneNumber.startsWith(phoneCode))
      return phoneNumber.slice(phoneCode.length).trim();
    else return phoneNumber;
  };

  useEffect(() => {
    fetchUser();
  }, [updateCount]);

  const updateUserInfo = async (values) => {
    try {
      const data = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        middle_name: "",
        email: values?.emailAddress,
        phone_code: countryCode,
        mobile_number: removeCountryCode(countryCode, values?.phoneNumber),
        home_address: values?.addressLine_1,
        city: values?.city,
        state: values?.state,
        lga: values?.lga,
        postalCode: `${values?.postalCode}`,
        country: "NG",
        dob: values?.dob,
        gender: values?.gender,
        user_image: imageUrl,
      };
      await _verify_user_info(data);
      setCurrentStep(1);
      setUpdatingProfile(false);
      setProfileErr(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      setUpdatingProfile(false);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          navigate("/signin");
          setProfileErr(false);
        } else {
          openNotificationWithIcon(
            "error",
            err?.response?.data?.title,
            err?.response?.data?.message
          );
          setProfileErr(err.response.data.message);
        }
      } else {
        openNotificationWithIcon("error", "Something went wrong", err.message);
      }
    }
  };

  const updateDocumentInfo = async (values) => {
    setUpdatingProfile(true);
    try {
      const verifyAccount = await _verfy_account_datas({
        bvn: `${values?.bvn}`,
      });
      setUpdatingProfile(false);
      setProfileErr(false);
      openNotificationWithIcon(
        "success",
        verifyAccount?.data?.title,
        verifyAccount?.data?.message
      );
      setVerifyAccount(verifyAccount?.data?.data);
      setUpdateCount(updateCount + 1);
    } catch (err) {
      setUpdatingProfile(false);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          navigate("/signin");
          setProfileErr(false);
        } else {
          openNotificationWithIcon(
            "error",
            err?.response?.data?.title,
            err?.response?.data?.message
          );
          setProfileErr(err.response.data.message);
        }
      } else {
        openNotificationWithIcon("error", "Something went wrong", err.message);
      }
    }
  };

  const handlePhoneChange = (phone, country) => {
    const dialCode = `${country.dialCode}`;
    setCountryCode(dialCode);

    // Remove the country code from the start of the phone number
    const phoneWithoutCode = phone.startsWith(dialCode)
      ? phone.slice(dialCode.length).trim()
      : phone;
    setPhoneNumber(phoneWithoutCode);
  };

  const chargeSetting = async () => {
    setTierCharge(null);
    setWalletBalance(null);
    try {
      const fetchAccounts = await _get_user_wallet_account();
      if (fetchAccounts?.data?.data?.available_balance > 0) {
        const charge_setting = await _calc_commission({
          settings_id: "22",
          amount: `${fetchAccounts?.data?.data?.available_balance}`,
          account_number: "",
        });
        setWalletBalance(charge_setting?.data?.amount);
        setTierCharge(charge_setting?.data?.charges);
      } else {
        setWalletBalance(0);
        setTierCharge(0);
      }
    } catch (err) {
      setWalletBalance(null);
      setTierCharge(null);
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          navigate(`/signin?redir=loyalty`);
        }
      } else {
        openNotificationWithIcon("error", "Something went wrong", err.message);
      }
    }
  };

  useEffect(() => {
    setIsModalVisible(true);
    chargeSetting();
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="userprofile">
      <div className="profile-content">
        <Navbar />
        <div className="gray_bg_profile">
          <div className="profile_to_left">
            <div className="">
              <div className="profile_nav">
                <Profile />
              </div>
            </div>
          </div>
          <div className="profile_to_right">
            <div className="">
              <div className="contain">
                <div className="main_container">
                  {(profileInfo?.kyc_status === "pending" ||
                    verifyAccount?.kyc_status === "pending") && (
                      <div style={{ height: "70vh", display: "flex" }}>
                        <div
                          className="main_container"
                          style={{ width: "100%", margin: "auto" }}
                        >
                          <AlertModal
                            title="KYC Verification update"
                            desc="click the refresh button to activate your account"
                          >
                            <Button
                              onClick={() => window.location.reload()}
                              disabled={!isActive}
                            >
                              {isActive ? "Refresh" : `Refresh (${seconds}s)`}
                            </Button>
                          </AlertModal>
                        </div>
                      </div>
                    )}
                  {profileInfo?.kyc_status === "rejected" && timeLeft && (
                    <div style={{ height: "70vh", display: "flex" }}>
                      <div
                        className="main_container"
                        style={{ width: "100%", margin: "auto" }}
                      >
                        <AlertModal
                          title="KYC Verification update"
                          desc={`A slight issue, your KYC verification is rejected. Please try again in ${timeLeft} with valid BVN information for the following. First name, Last name, Date of birth, Phone number.`}
                        >
                          <></>
                        </AlertModal>
                      </div>
                    </div>
                  )}
                  {profileInfo?.kyc_status === "error" && errorModal && (
                    <div style={{ height: "70vh", display: "flex" }}>
                      <div
                        className="main_container"
                        style={{ width: "100%", margin: "auto" }}
                      >
                        <AlertModal
                          title="KYC Verification update"
                          desc="A slight issue, your KYC verification is rejected. Please try again in now or in 30 minutes with valid BVN information for the following. First name, Last name, Date of birth, Phone number."
                        >
                          <Button onClick={() => setErrorModal(false)}>
                            Close
                          </Button>
                        </AlertModal>
                      </div>
                    </div>
                  )}
                  {profileInfo?.kyc_status !== "pending" &&
                    !errorModal &&
                    !timeLeft && (
                      <div className="main_container">
                        <div className="main_container_title_div">
                          <div>
                            <h1 style={{ marginBottom: 0 }}>
                              KYC Verification
                            </h1>
                          </div>
                          <Divider style={{ margin: "0px 0 5px" }} />
                          {(profileInfo?.kyc_status === "rejected" ||
                            profileInfo?.kyc_status === "error") && (
                              <p style={{ color: "red" }}>
                                A slight issue, your KYC verification is rejected.
                                Please try again with{" "}
                                <b>valid BVN information for the following.</b>{" "}
                                First name, Last name, Date of birth, Phone
                                number.
                              </p>
                            )}
                          {profileInfo?.kyc_status !== "rejected" &&
                            profileInfo?.kyc_status !== "error" && (
                              <>
                                <p>
                                  To verify your KYC, please fill the following
                                  correctly with{" "}
                                  <b>information you have for your BVN.</b>
                                </p>
                                <p>
                                  First name, Last name, Date of birth, Phone
                                  number.
                                </p>
                              </>
                            )}
                        </div>
                        <div className="main_container_inner_div">
                          <div className="profile_container">
                            <div className="slider_div_to_top">
                              <div>
                                <div className="grid-flex-bias">
                                  <div className="tab_block">
                                    <div className="short_width">
                                      <div style={{ marginTop: "20px" }}></div>
                                      <div className="desktop">
                                        <Steps
                                          current={currentStep}
                                          items={[
                                            {
                                              title: "Personal",
                                            },
                                            {
                                              title: "Document",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div className="mobile">
                                        <Steps
                                          current={currentStep}
                                          direction="vertical"
                                          items={[
                                            {
                                              title: "Personal",
                                            },
                                            {
                                              title: "Document",
                                            },
                                          ]}
                                        />
                                      </div>
                                      <div style={{ marginTop: "20px" }}></div>
                                      {profileErr && (
                                        <p className="error-message">
                                          {profileErr}
                                        </p>
                                      )}
                                      <div style={{ marginTop: "10px" }}></div>
                                      {currentStep === 0 && (
                                        <Form
                                          layout="vertical"
                                          form={form}
                                          onFinish={updateUserInfo}
                                          autoComplete="off"
                                          initialValues={{
                                            emailAddress: profileInfo?.email
                                              ? profileInfo?.email
                                              : profileInfo?.user?.email,
                                            dob: profileInfo?.dob
                                              ? profileInfo?.dob
                                              : profileInfo?.user?.dob,
                                            gender: profileInfo?.gender
                                              ? profileInfo?.gender
                                              : profileInfo?.user?.gender,
                                            userName: profileInfo?.userName
                                              ? profileInfo?.userName
                                              : profileInfo?.user?.username,
                                            password: "",
                                            firstName: profileInfo?.first_name
                                              ? profileInfo?.first_name
                                              : profileInfo?.user?.first_name,
                                            lastName: profileInfo?.last_name
                                              ? profileInfo?.last_name
                                              : profileInfo?.user?.last_name,
                                            phoneNumber:
                                              profileInfo?.phone_number
                                                ? profileInfo?.phone_number
                                                : profileInfo?.user
                                                  ?.phone_number,
                                            addressLine_1:
                                              profileInfo?.home_address
                                                ? profileInfo?.home_address
                                                : profileInfo?.user
                                                  ?.home_address,
                                            state: profileInfo?.state
                                              ? profileInfo?.state
                                              : profileInfo?.user?.state,
                                          }}
                                        >
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="firstName"
                                              label="First Name"
                                              className="form-group space"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="First Name"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              name="lastName"
                                              label="Last Name"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Last Name"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="emailAddress"
                                              label="Email Address"
                                              className="form-group space"
                                            >
                                              <Input
                                                disabled
                                                placeholder="example@mail.com"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              name="dob"
                                              label="Date Of Birth"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                type="date"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="phoneNumber"
                                              label="Phone Number"
                                              className="form-group space"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <PhoneInput
                                                onChange={handlePhoneChange}
                                                containerStyle={{
                                                  width: "100%",
                                                }}
                                                inputStyle={{
                                                  height: "5rem",
                                                  width: "100%",
                                                }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              name="gender"
                                              label="Gender"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select Gender"
                                                style={{ height: "5rem" }}
                                              >
                                                <Select.Option value="Male">
                                                  Male
                                                </Select.Option>
                                                <Select.Option value="Female">
                                                  Female
                                                </Select.Option>
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="addressLine_1"
                                              label="Home Address"
                                              className="form-group space"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Home Address"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                            <Form.Item
                                              name="postalCode"
                                              label="Postal Code"
                                              className="form-group space"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <InputNumber
                                                controls={false}
                                                placeholder="Postal Code"
                                                size="large"
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                            <div></div>
                                            <div
                                              style={{ marginBottom: "1.5rem" }}
                                            >
                                              <a
                                                href="https://www.nigeriapostcode.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Look up your postal code
                                              </a>
                                            </div>
                                          </div>
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="state"
                                              label="State"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Select
                                                onChange={onChangeState}
                                                placeholder="Select State"
                                                name="state"
                                                style={{
                                                  width: "100%",
                                                  height: "5rem",
                                                }}
                                              >
                                                {allStates.map((state) => (
                                                  <Select.Option
                                                    key={state}
                                                    value={state}
                                                  >
                                                    {state}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                            <Form.Item
                                              name="city"
                                              label="City"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="City"
                                                style={{ height: "5rem" }}
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="form-flex-2">
                                            <Form.Item
                                              name="lga"
                                              label="LGA"
                                              className="form-group"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill input is required",
                                                },
                                              ]}
                                            >
                                              <Select
                                                placeholder="Select LGA"
                                                name="state"
                                                style={{
                                                  width: "100%",
                                                  height: "5rem",
                                                }}
                                              >
                                                {allLgas.map((lga) => (
                                                  <Select.Option
                                                    key={lga}
                                                    value={lga}
                                                  >
                                                    {lga}
                                                  </Select.Option>
                                                ))}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="space_between">
                                            <Button
                                              type="primary"
                                              htmlType="submit"
                                              style={{ marginLeft: "auto" }}
                                            >
                                              Proceeed
                                            </Button>
                                          </div>
                                        </Form>
                                      )}
                                      {currentStep === 1 && (
                                        <Form
                                          layout="vertical"
                                          form={docsform}
                                          onFinish={updateDocumentInfo}
                                          autoComplete="off"
                                        >
                                          <Form.Item
                                            name="bvn"
                                            label="BVN"
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please fill input is required",
                                              },
                                            ]}
                                          >
                                            <InputNumber
                                              controls={false}
                                              placeholder="BVN"
                                              size="large"
                                              style={{ width: "100%" }}
                                            />
                                          </Form.Item>
                                          <div className="space_between">
                                            <Button
                                              onClick={() => setCurrentStep(0)}
                                            >
                                              Back
                                            </Button>
                                            {updatingProfile ? (
                                              <Button
                                                type="primary"
                                                loading
                                                style={{ marginLeft: "auto" }}
                                              >
                                                Verifying...
                                              </Button>
                                            ) : (
                                              <Button
                                                type="primary"
                                                htmlType="submit"
                                              >
                                                Verify Account
                                              </Button>
                                            )}
                                          </div>
                                        </Form>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="profile mobile">
          <Footer />
        </div>
      </div>

      <Modal
        title="Important Notice"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        centered
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={handleOk}
            disabled={!(walletBalance != null && tierCharge != null)}
          >
            OK
          </Button>,
        ]}
      >
        {walletBalance != null && tierCharge != null ? (
          <>
            <p>
              An upgrade fee of <b>NGN {tierCharge.toLocaleString()}</b> will be
              applied for you to upgrade to Tier 2.
            </p>
            <p style={{ marginTop: "2rem", fontWeight: "600" }}>
              Please note that after a successful upgrade:
            </p>
            <p style={{ marginTop: "1rem" }}>
              <b>1.</b> You will have a new dedicated NUBAN account details.
            </p>
            <p style={{ marginTop: "1rem" }}>
              <b>2.</b> Your new Tier 2 wallet balance will be{" "}
              <b style={{ wordWrap: "keep-all", whiteSpace: "noWrap" }}>
                NGN {walletBalance.toLocaleString()}
              </b>.
            </p>
          </>
        ) : (
          <Spin />
        )}
      </Modal>
    </div>
  );
};

export default CompleteKyc;
