import '../assets/css/mini.css';

import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';


const FAQ = () => {
    return (
        <div className="terms minor">
            <Navbar />
            <div className="minor-header">
                <div className="contain">
                    <div className="header-text">
                        <h3>Frequently asked questions</h3>
                    </div>
                </div>
            </div>
            <div className="contain">
                <div style={{ marginTop: '5%' }}></div>
                <div className="faq_container">
                    <h5>What is konnect?</h5>
                    <p>Konnect is a customer centric app that provides wholesale products to retail users. It makes life easy for shoppers
                        and provides consistent rewards for shopping and referrals.
                    </p>

                    <h5>What are the requirements to use the services?</h5>
                    <p>To register on this platform, you must: register with us on the Website or via the app, provide all information
                        requested by us, such as your name, email address, mobile device number, create your unique secured pin which is
                        required for you to transfer funds from your loyalty account to friends on the platform to any bank account.</p>
                    <p>We may require you to provide and/or confirm information and documentation that will allow us to identify you. The
                        information you provide us is subject to our Privacy Policy.</p>
                    <h5>What is Wise 9ja?</h5>
                    <p>
                        Wise9ja is how we reward users as they support our shopping concept. It allows them to voluntary top-up an amount,
                        while they earn instant cash and monthly cash rewards. Their cash rewards and top-up amount are paid into their
                        loyalty account.
                    </p>
                    <h5>How safe and secure are my payment details?</h5>
                    <p>All financial information is encrypted and stored to PCIDSS Level 1 compliant standards. PCIDSS Level 1 compliance is
                        a set of rules stated by credit card companies and audited by an independent third party</p>
                    <p>It is the highest possible rating one can get in the electronic payment processing industry. Additionally, all
                        transmission to our banking partner and on our site is via an encrypted 256-bit HTTPS SSL connection</p>
                    <p>Your security is our major priority, so we work with a PCIDSS-compliant payment processor to handle your card
                        details. Your card details are extremely safe and are warehoused where they can never be compromised</p>
                    <h5>What to do if you lose your devise or your account is hacked</h5>
                    <p>We have made a quick fix if you have issues with your account. Your account is secured with multi-layers of
                        authentications which makes it difficult for it to be comprised except if all your details are revealed</p>
                    <b>1. If your mobile device is lost or stolen</b>
                    <p>Reset your password and check if your account history has not been compromised. If you discover any transaction not
                        related to you, change your security setting and can also  give us a shout</p>

                    <b>2. If you suspect someone has gained unauthorized access to your account</b>
                    <p>Reset your password and security information immediately or chat with us or send an email to info@konnect.ws for us
                        to help to resolve any known concern</p>

                    <b>3. If you forgot your password or your login details</b>
                    <p>Click the password reset button at sign-in and follow the process to generate a new password</p>
                    <h5>What is the referral process?</h5>
                    <p>
                        Konnect user base is expected to grow organically via the referral process. We reward users for successful referral.
                        A successful referral is a made when the referral does a successful deposit into their loyalty card. A referral
                        commission is paid into the referee’s loyalty account upon successful referral. The platform also provides cashback
                        amount for shopping successful done. Cashback amounts are shared between the referee and the referral.
                    </p>

                    <h5>What type of security am I assured for fund transfer?</h5>
                    <p>
                        We have two-factor authentication (2FA) system to enable complete access to transfer funds from your loyalty account
                        to friends and bank account. Transactions will require your secure pin code and answer to of your memorable
                        question.
                    </p>

                    <h4 className="minor-title">Shopping</h4>
                    <h5>Will all the items in my order arrive in a single package?</h5>

                    <p>Yes, our process aggregates your order into one basket even if the items
                        is from more than one seller.</p>

                    <h5>Why do I see different prices for the same product?</h5>
                    <p>Konnect is your trusted online marketplace that supports Nigerian entrepreneurs, and we have many different sellers
                        competing for business. Therefore, you may see the same product offered by different sellers at different prices. We
                        believe that by supporting these 'third-party' sellers, we can offer you a wider product selection, more choice,
                        increased convenience, and better pricing.</p>
                    <h5>What is the return policy?</h5>
                    <p>We offer all our customers ultimate peace of mind. Your items must be confirmed by you when the delivery arrives and
                        before the delivery man delivers it to you. Thus, we encourage buyers to reject defects items at delivery.</p>
                    <h5>What is the estimated delivery time?</h5>
                    <p>Konnect delivers products to customers all over Nigeria. The delivery date is picked by the buyer at checkout, and we
                        work to deliver to the schedule date.</p>

                    <h5>How do you source for products?</h5>
                    <p>Products are gotten from people registered and approved as vendors. These include manufacturers, wholesalers, and
                        retailers of any product you can think about.</p>

                    <h5>Do you verify your vendors?</h5>
                    <p>Yes. We do a thorough check on them before approval. We visit their physical offices, shops or outlets and vet the quality of their products.
                    </p>
                    <h5>Can I just refer people to earn without shopping?</h5>
                    <p>You can choose to do that if you want. However, shopping and opening a loyalty account gives you a more robust experience and easier lifestyle.
                    </p>
                    <h5>Can I shop and pay later? How does that work?</h5>
                    <p>When you shop, you can choose to pay when the products are delivered. Also, you can build your cash base by inviting people to sign up for a referral bonus. Your cumulative reward can then be used for shopping.
                    </p>
                    <h5>I have no money to start. Can I still earn?
                    </h5>
                    <p>Yes, you can build your cash base by inviting people to sign up for a referral bonus.
                    </p>
                    <h4 className="minor-title">My Loyalty Account
                    </h4>
                    <h5>Why loyalty account?</h5>
                    <p>Konnect is customer centric, family friendly & provides a disruptive approach to shopping. Our concept of shopping
                        allows us to provide wholesale prices to retail buyers. Our loyalty users support our drive to achieve our wholesale
                        prices, thus loyalty provides cash reward to appreciate the support received from loyalty customers.
                    </p>

                    <h5>Are my payment details safe?</h5>
                    <p><p>All financial information is encrypted and stored to PCIDSS Level 1 compliant standards. PCIDSS Level 1 compliance is
                        a set of rules stated by credit card companies and audited by an independent third party. <br /> It is the highest
                        possible rating one can get in the electronic payment processing industry. Additionally, all transmission to our
                        banking partner and on our site is via an encrypted 256-bit HTTPS SSL connection.</p>
                    </p>
                    <h5>Can I transfer my cash reward?</h5>
                    <p><p>Cash rewards are paid automatically into your loyalty account, and you can transfer to friends on the konnect
                        platform. Transfer to a Nigerian bank account will attract a transfer fee.</p></p>
                    <h4 className="minor-title">Pay Bills
                    </h4>
                    <h5>What is Wise 9ja?
                    </h5>
                    <p>We delight in the prosperity of our users, thus Wise 9ja is a user that recharge for free their monthly bills as they
                        top-up their loyalty account monthly. This approach allows the user to still earn their loyalty reward, receive back
                        their top-up amount and enjoy free recharge.
                    </p>
                    <h5>How do I enjoy my Wise 9ja reward?
                    </h5>
                    <p>Interested user will top-up their loyalty account with the minimum amount to enjoy the following – earn a monthly
                        cash reward paid every 30 days into your loyalty account, receive their top-up amount after the set period, enjoy
                        extra cash amount in their Wise 9ja balance. The Wise 9ja freebie is credited instantly for your recharge and to pay
                        bills payment.
                    </p>
                    <h5>How can I top-up and pay my bills?
                    </h5>
                    <p>To start visit the website or download the app to create an account. Click pay bills and click visit Wise 9ja button
                        and follow the process to enjoy free recharge.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FAQ;