import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Card, Input, Typography} from 'antd';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {update_selected_menu, update_visibility} from '../../../slices/restaurantSlice';
import {_update_menu_qty} from '../../../common/axios_services.js';
import {openNotificationWithIcon} from '../../../common/utils/general.jsx';
import {useNavigate} from 'react-router-dom';

const {Title, Text} = Typography;

const FoodCard = ({food}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {name, description, price, qty_available, discount, images} = food;
	const [quantity, setQuantity] = useState(qty_available);
	const [loading, setLoading] = useState(false); // Add loading state to prevent multiple requests

	// Helper function to truncate description text
	const truncateText = (text, maxLength = 20) => {
		if (text.length > maxLength) {
			return `${text.substring(0, maxLength)}...`;
		}
		return text;
	};

	// Function to handle API call to update quantity
	const updateQuantity = async (newQuantity) => {
		setLoading(true);
		try {
			await _update_menu_qty(food.id, newQuantity);
			setQuantity(newQuantity); // Update quantity state after successful API call
		} catch (err) {
			if (err.response) {
				if (err.response.data.message === "Unauthorized") {
					localStorage.removeItem('konnect_token')
					navigate('/signin')
				}
				openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
			} else {
				openNotificationWithIcon('error', err.message)
			}
		} finally {
			setLoading(false);
		}
	};

	// Handlers for incrementing and decrementing quantity
	const increment = () => {
		const newQuantity = quantity + 1;
		updateQuantity(newQuantity); // Call API and update state
	};

	const decrement = () => {
		if (quantity > 1) {
			const newQuantity = quantity - 1;
			updateQuantity(newQuantity); // Call API and update state
		}
	};

	// Handler for input change (pressing Enter)
	const handleInputChange = (e) => {
		const newQuantity = Number(e.target.value);
		setQuantity(newQuantity);
	};

	// Handler for when Enter key is pressed in the input field
	const handleInputPressEnter = (e) => {
		const newQuantity = Number(e.target.value);
		if (newQuantity > 0) {
			updateQuantity(newQuantity); // Call API and update state
		}
	};

	const handleMenuClick = (menu) => {
		dispatch(update_selected_menu(menu));
		dispatch(update_visibility(true));
	};

	const menuImage = images[0]?.url ? images[0]?.url : 'https://dummyimage.com/240/000000/FFFFFF'

	return (
		<Card className="food-card" cover={<img alt={name} src={menuImage} />} hoverable>
			<div className="food-info" onClick={() => handleMenuClick(food)} style={{paddingBottom: '10px'}}>
				<Title level={5}>{name}</Title>
				<Text type="secondary">{truncateText(description)}</Text>
				<div className="price-section">
					{discount > 0 && (
						<div>
							<Text delete>NGN {price}</Text>
						</div>
					)}
					<Text strong style={{marginLeft: discount > 0 ? '10px' : '0'}}>
						NGN {price - (price * discount) / 100}
					</Text>
				</div>
			</div>
			{/* <div className="food-actions" style={{display: 'flex', alignItems: 'center'}}>
				<Button
					icon={<MinusOutlined />}
					onClick={decrement}
					disabled={quantity <= 1 || loading} // Disable when loading or quantity is 1
				/>
				<Input
					value={quantity}
					onChange={handleInputChange}
					onPressEnter={handleInputPressEnter}
					style={{width: 50, textAlign: 'center', margin: '0 10px'}}
					disabled={loading} // Disable when loading
				/>
				<Button
					icon={<PlusOutlined />}
					onClick={increment}
					disabled={loading} // Disable when loading
				/>
			</div> */}
		</Card>
	);
};

export default FoodCard;
