import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
    <TawkMessengerReact
      propertyId={process.env.REACT_APP_TAWK_CHAT_ID}
      widgetId="default" />
  </>
);
reportWebVitals();
