import { Card, Col, Row, Switch } from 'antd';
import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import restaurant_food from '../../assets/images/homepage/restaurant_food.png';
import giftIcon from '../../assets/images/restaurant/gift.svg';
import map from '../../assets/images/restaurant/Map_of_Assyria.png';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import info_circle from '../../assets/images/restaurant/-Info-circle.svg';
import { useNavigate } from 'react-router-dom';
import { CheckoutDrawer } from '../../components/Restaurant/CheckoutDrawer';
import { UnderConstructionModal } from '../../components/UnderConstructionModal';




const ReviewCheckout = () => {
    const navigate = useNavigate()
    const [wantsCutlery, setWantsCutlery] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);

    const showDrawer = () => {
        setVisibleDrawer(true);
    }

    const closeDrawer = () => {
        setVisibleDrawer(false);
    };


    const handleSwitch = (checked) =>{
		setWantsCutlery(checked)
	}
  return (
    <>
          {/*  UNDER CONSTRUCTION POPUP */}
          <UnderConstructionModal />
        <div>
            <Navbar noShadow={true} adjustNavSize={true} showCart={true} restaurantCart={true} />
            <div className="px-10 py-20">
                <Row justify={'center'}>
                    <Col xs={24}> 
                        <h2 className='lg:text-6xl text-5xl'>Review your order from Havey’s Restaurant</h2>
                    </Col>
                </Row>        
            </div>
            <div className="px-10 ">
                <Row justify={'center'} gutter={{ lg:48 }} >
                    <Col xs={24} lg={16}>
                        <div className="payment">
                            <h2 className='text-3xl'>Payment</h2>
                            <Card bordered={false} className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                <div className="flex justify-between items-center">
                                    <div className="">
                                        <input type="radio" name="" id="" />
                                        <label className='ml-3 text-3xl' htmlFor="">Wallet</label>
                                    </div>
                                    <div className="">
                                        <p className='font-semibold' >₦10000</p>
                                        <p className='text-[#959595]    ' >Balance</p>
                                    </div>
                                </div>
                            </Card>
                            <div>
                                <Card bordered={false} className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                    <div className="flex justify-between items-center">
                                        <div className="">
                                            <input type="radio" name="" id="" />
                                            <label className='ml-3 text-3xl' htmlFor="">Wallet</label>
                                        </div>
                                        <div className="">
                                            <p className='font-semibold' >₦10000</p>
                                            <p className='text-[#959595]' >Balance</p>
                                        </div>
                                    </div>
                                </Card>   
                            </div>
                        </div>
                        <div className="delivery my-5">
                            <h2 className='text-3xl'>Delivery</h2>
                            <Card bordered={false} className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                <div className="divide-y-2">
                                    <div className="flex gap-3">
                                        <input type="radio" name="" id="" />
                                        <div className="flex flex-col">
                                            <p className='text-3xl'>Standard Delivery</p>
                                            <p className='text-lg text-[#959595] ' >20 -35min</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between mt-8 pt-5">
                                        <div className="flex gap-3">
                                            <input type="radio" name="" id="" />
                                            <div className="flex flex-col">
                                                <p className='text-3xl'>Priority Delivery</p>
                                                <p className='text-lg  text-[#959595] ' >20 -35min</p>
                                            </div>                      
                                        </div>
                                        <p className='font-bold'> +₦500</p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="delivery_location my-5">
                            <div className="flex justify-between">
                                <h2 className='text-3xl'>Delivery Address</h2>
                                <span className='text-[#44843F] cursor-pointer font-bold  text-3xl' onClick={showDrawer} >Change</span>
                            </div>
                            {visibleDrawer && (
                                <CheckoutDrawer visible={visibleDrawer} onClose={closeDrawer} />
                            )}

                            <Card bordered={false} className="px-10 mt-3  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                <div className="flex justify-between">
                                    <div className="flex gap-3">
                                        <input type="radio" name="" id="" />
                                        <p className='text-3xl'>Send as gift</p>
                                    </div>
                                    <span>
                                        <img src={giftIcon} alt="" className='w-10 h-10'  />
                                    </span>
                                </div>
                                <div className="w-full mt-5">
                                    <img src={map} className='h-72 w-full  ' alt="" />
                                </div>
                                <div className="flex flex-wrap  gap-3 text-black mt-3">
                                    <p className='text-2xl'>John Doe</p>
                                    <p className='text-2xl font-semibold'>+234 800 123 4567</p>
                                    <p className='text-2xl'>John@gmail.com</p>
                                </div>
                                <div className="flex items-center gap-3 text-black mt-3">
                                    <span>
                                        <img src={map_locationIcon} alt="" className='w-10 h-10'  />
                                    </span>
                                    <p className='text-2xl font-bold'>No 17, 1st Floor, Queens Tower, Lekki Phase 1, Lagos</p>
                                </div>
                            </Card>
                        </div>
                        {/* Last Card */}
                        <div className="delivery my-5">
                            <Card bordered={false} className="px-10 mt-5  py-5 bg-[#fff] rounded-2xl border-2 border-black">
                                <div className="flex justify-between">
                                    <h2>Need Cutlery</h2>
                                    <Switch className={` ${wantsCutlery ? 'bg-[#258635]' : 'bg-[grey]'}`} onChange={handleSwitch}  />
                                </div>
                            </Card>
                        </div>
                        <button onClick={() => {navigate('/restaurant/1/checkout_successful')}} className="bg-[#44843F] text-white flex justify-center w-full rounded-3xl border-2 border-black px-10 my-10 py-3">
                            <p>Complete Order</p>
                        </button>
                    </Col>
                    {/* CHECKOUT DETAILS */}
                    <Col xs={24} lg={8} >
                        <Card bordered={false} className="flex-col px-10 py-20 lg:mt-28 items-center bg-[#f5fff5] rounded-[32px] border-2 border-black">
                            <div className="divide-y-2">
                                <div className="rest_header">
                                    <h2 className='text-4xl text-black' >Basket</h2>
                                </div>
                                <div className="order_item my-10">
                                    <div className="flex items-center gap-4 py-3 my-3  ">
                                        <img src={restaurant_food} alt="restaurant_logo" className="w-28 h-28 rounded-full" />
                                        <div>
                                        <p className="text-2xl text-black font-semibold">Havey’s Restaurant - Lekki</p>
                                        <p className="text-xl text-[#959595]">2 Items •  ₦ 6000</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-2">
                                        <p className="text-3xl">2x</p>
                                        <p className="text-3xl text-wrap">Fried Rice & Chicken - 1 portion and half chicken</p>
                                        <p className=' text-[#44843F] '>₦6000</p>
                                    </div>
                                    <div className="flex justify-between mt-5">
                                        <div className="flex-col">
                                            <p>Subtotal</p>
                                            <p className='text-[#959595] text-xl' >10% discount</p>
                                        </div>
                                        <p className=' text-[#44843F]'>₦6000</p>
                                    </div>
                                    
                                    {/* Your Selections */}
                                    <div className="bg-[#E4E4E4] rounded-3xl p-5 my-5  border border-black ">
                                        <div className="divide-[#959595] divide-y">
                                            <div className="flex justify-between text-[#000] text-2xl mt-1 pb-3">
                                                <span>Service Fee</span>
                                                <span className='flex items-center gap-2' >
                                                    <img src={info_circle} className='w-10 h-10' alt="" />
                                                    ₦50
                                                </span>
                                            </div>
                                            <div className="flex justify-between text-[#000] text-2xl pt-3">
                                                <span>Delivery Fee</span>
                                                <span className='flex items-center gap-2' >
                                                    <img src={info_circle} className='w-10 h-10' alt="" />
                                                    <p></p>
                                                     ₦200
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                                <div className="my-10">
                                    <div className="flex justify-between py-2">
                                        <p>Total</p>
                                        <p>₦6250</p>
                                    </div>
                                </div>

                            </div>
                            
                        </Card>
                    </Col>
                </Row>        
            </div>

            
        </div>
    </>
  )
}
export default ReviewCheckout;