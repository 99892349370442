import React from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";

import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

const Category = ({ param, categoryData }) => {
    const responsive = {
        0: {
            items: 3,
            nav: false,
            margin: 10,
            stagePadding: 20
        },
        600: {
            items: 3,
            nav: false,
            margin: 20,
            stagePadding: 20
        },
        1000: {
            items: 6,
            nav: false,
            margin: 10,
            stagePadding: 20,
            loop: false
        }
    }
    let skeleton = [];
    for (let i = 0; i < 6; i++) {
        skeleton.push(<Skeleton active />)
    }
    return (
        <div>
            <div className="category-data carousel-with-margi">
                <div className="contain">
                    <div className="grid-flex">
                        <h3 className="tile-header">{param}</h3>
                    </div>
                    {categoryData.length ?
                        <>
                            <div className="">
                                <OwlCarousel className="owl-theme" lazyLoad={true} responsive={responsive} responsiveClass={true} loop margin={10} nav>
                                    {
                                        categoryData.map((category, index) => (
                                            <div className="item" key={index}>
                                                <Link to={`/categories/${category.id}`}>
                                                    <div className="categories">
                                                        <div className="image">
                                                            <div className={`category-image ${category.category_name}`}>
                                                                <img src={category.category_image} alt="product" />
                                                            </div>
                                                            <p className="caption">{category.category_name}</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel>
                            </div>
                        </>
                        :
                        <div className="grid-6">
                            {skeleton.map((placeHolder, index) => (
                                <div className="item" key={index}>
                                    {placeHolder}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </div >
        </div >
    )
}

export default Category