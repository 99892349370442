import "../../assets/css/landing.css";
import "../../assets/css/home.css";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import side_banner5 from "../../assets/images/homepage/side_banner_4.png";
import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Modal } from "antd";
import topHeader1 from "../../assets/images/homepage/topHeader1.png";
import appImg from "../../assets/images/homepage/appImg.png";
import chef from "../../assets/images/homepage/chef.png";
import bannerMeal from "../../assets/images/homepage/bannerMeal.png";
import SearchRestaurant from "../../components/SearchRestaurant";
import DealsCard from "../../components/DealsCard";
import MobileDownloadStores from "../../components/MobileDownloadStores";
import {_fetch_user_wallet_data, _get_profile} from '../../common/axios_services';
import { useNavigate } from "react-router-dom";
import { PinActivation } from "../../components/PinActivation";








const HomePage = () => {
  let navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);
  const [vendorPartnerModalOpen, setVendorPartnerModalOpen] = useState(false);
  const [appModalOpen, setAppModalOpen] = useState(false);
  const [isVendor, setIsVendor] = useState(false)
  const [isRestaurant, setIsRestaurant] = useState(false)
  const [userSignedIn, setUserSignedIn] = useState(false)
  const [userloginPinSet, setUserLoginPinSet] = useState(false)
  const [accountId, setAccountId] = useState("")
  


  const getUserProfile = async () => {
    try{
      let result = await _get_profile();
      setIsVendor(result?.data?.data.is_so);
      setIsRestaurant(result?.data?.data.is_restaurant);
      setAccountId(result?.data?.data.account_id)
      // ACTIVATE PIN
      setUserLoginPinSet(result?.data?.data.login_pin_set);
      setUserSignedIn(true)
    }
    catch(e){
      if (e.response) {
        if (e.response.data.message === "Unauthorized") {
         setUserSignedIn(false)
          localStorage.removeItem('konnect_token')
        } 
    }
    }
  }

  // JOIN NOW MODAL
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


 /* EXPERIENCE APP MODAL */
  const showAppModal = () => {
    setAppModalOpen(true); 
  };
  const handleAppCancel = () => {
    setAppModalOpen(false);
  };

  /* PARTNERSHIP MODAL */
  const showPartnerModal = () => {
    if (userSignedIn && !isVendor) {
      setPartnerModalOpen(true); //signed in but not a vendor
    } else if((isVendor && !isRestaurant) || !userSignedIn){
      setVendorPartnerModalOpen(true); // signed in and a vendor  but not a restaurant || is not signed in
    } else if (isVendor && isRestaurant) {
      navigate('/restaurant/dashboard') // is Restaurant
    }
  };
  // is not vendor
  const handlePartnerCancel = () => {
    setPartnerModalOpen(false);
  };
 /* Is vendor but not restaurant*/
  const handleVendorPartnerCancel = () => {
    setVendorPartnerModalOpen(false);
  };






  useEffect(() => {
    getUserProfile()
  }, []);

  useEffect(() => {
    // Check if the URL contains the #partnerWithUs hash
    if (window.location.hash === "#partnerWithUs") {
      const element = document.getElementById("partnerWithUs");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, []); // Empty dependency array ensures this runs once on component mount

  
  return (
    <div>
      {
        (userSignedIn && !userloginPinSet)
         && (
            <PinActivation 
            open={!userloginPinSet} 
            toClose={setUserLoginPinSet} 
            accountId={accountId}
          />
        )
        }
      <Navbar noShadow={true} adjustNavSize={true}  />
      {/* Hero Section */}
       <div>
            <Row
            align={"middle"}
            justify={"center"}
            className="h-screen min-h-screen max-h-screen w-full px-12 "
            gutter={{ sm: 4, md: 24, lg: 48 }}
            >
            <Col xs={24} md={12}>
              <div className="">
                <img className="bg-[#f5f5f5] w-full" src={topHeader1} alt="hero_image" />
              </div>
            </Col>
            <Col xs={24} md={12}>
                <div className=" max-md:text-center max-[767px]:mt-20  text_h3 gap-y-2 flex flex-col">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Family & Friends
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Eats Cheaper
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        With <span className="text-[#44843F] font-bold">Konnect.</span>
                    </p>
                </div>
                <div className="lg:mt-44 max-md:my-20 " >
                    <SearchRestaurant />
                </div>
            </Col>
            </Row>
            {/*  */}
      </div>

      {/* Second Section */}
      <div className="h-auto max-[767px]:mt-52  ">
            <Row
            align={"middle"}
            justify={"center"}
            className="h-auto w-full px-12 my-36 "
            >
                <Col xs={24} md={12}>
                    <div className=" max-md:text-center text_h3 gap-y-2 flex flex-col">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Experience
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        The App
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Get 30% Discount
                    </p>
                    </div>
                    <div className="flex items-center gap-3 max-md:mt-5 mt-3  max-md:justify-center">
                        <button onClick={showAppModal} className="rounded-button focus:outline-none ">
                            Get Started
                        </button>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="w-full flex justify-center">
                    <img src={appImg} alt="_image" />
                    </div>
                </Col>
            </Row>
      </div>

      {/* Third Section */}
       <div id="partnerWithUs" >
            <Row
            align={"middle"}
            justify={"center"}
            className="h-auto w-full px-12 my-36 "
            >
                <Col xs={24} md={12}>
                    <div className="">
                        <img src={chef} alt="chef_image" />
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="text_h3 gap-y-2 w-full max-sm:mt-7 text-center flex justify-center flex-col">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                     You cook, we sell..
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                    We'll grow your
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                    Food business
                    </p>
                    </div>
                    <div className="w-full flex justify-center items-center max-md:mt-5 mt-3  max-md:justify-center">
                        <button
                            onClick={showPartnerModal}
                            className="flex justify-end rounded-button focus:outline-none "
                        >
                            Partner with us now!
                        </button>
                    </div>
                </Col>
            </Row>
            {/*  */}
       </div>

      {/* Fourth Section  */}
      <DealsCard />


      {/* Fifth Section */}
       <div>
            <Row
            align={"middle"}
            justify={"center"}
            className="h-auto w-full px-12 my-36"
            >
                <Col xs={24} md={12}>
                    <div className=" text_h3 gap-y-2 flex flex-col">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Join the
                        <span className="text-[#44843F] font-bold"> Konnect</span> 
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Marketing team
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Earn monthly
                    </p>
                    </div>
                    <div className="flex items-center gap-3 max-md:mt-5 mt-3  mb-3 max-md:justify-center">
                      {
                        !userSignedIn ? (  
                        <a 
                          href="/signin" 
                          className="rounded-button"
                        >Join Now</a>
                        ) : 
                        (
                          <button onClick={showModal} className="rounded-button focus:outline-none ">
                            Join Now
                         </button>
                        )
                      }
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="w-full flex justify-center">
                    <img src={side_banner5} alt="_image" />
                    </div>
                </Col>

            </Row>
      </div>


      {/* Sixth Section */}
      <div>
            <Row
            align={"middle"}
            justify={"center"}
            className="h-auto w-full px-12 my-36 "
            >
                <Col xs={24} md={12}>
                    <div className=" text_h3 gap-y-2 flex flex-col">
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Think Breakfast
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Lunch & Dinner
                    </p>
                    <p className="lg:text-8xl xl:text-9xl text-6xl text_h3">
                        Think <span className="text-[#44843F] font-bold"> Konnect</span>
                    </p>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="w-full flex justify-center">
                    <img src={bannerMeal} alt="_image" />
                    </div>
                </Col>

            </Row>
      </div>

      {/* FOOTER */}
      <Footer />

      {/* MODALS */}

      {/* JOIN MARKETING TEAM MODAL */}
      <Modal
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
        className="partnerModal"
      >
        <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl" >Welcome To The Konnect Marketing Team</h2>
        <div>
          <p>You can earn more than NGN 50K monthly.</p>
          <p className="text_bold">Three Steps to Join</p>
          <ul class="numbered-list">
            <li className="marker:text-[#44843F]">Download the app</li>
            <li className="marker:text-[#44843F]">Sign-up and login</li>
            <li className="marker:text-[#44843F]">Go to Home and click Join the team</li>
          </ul>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <MobileDownloadStores />

          </div>
        </div>
      </Modal>

      {/* IS VENDOR PARTNERSHIP MODAL */}
      <Modal
        open={vendorPartnerModalOpen }
        footer={false}
        onCancel={handleVendorPartnerCancel}
        className="partnerModal"
      >
        <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">
          Become a Restaurant & Earn More
        </h2>
        <ul className="list-disc pl-5 space-y-2 mt-2">
          <li className="marker:text-[#44843F]">Only a <span className="font-bold" >Food Vendor</span> can become a restaurant </li>
          <li className="marker:text-[#44843F]">Reach more customers than ever (onsite and online)</li>
          <li className="marker:text-[#44843F]">We sell, deliver, and help you grow your business.</li>
          <li className="marker:text-[#44843F]">Restaurants can earn more than a million Naira weekly.</li>
        </ul>
        <p className="mt-4">
          Interested? <a href={ !userSignedIn ? "signin": "/restaurant/dashboard"} className="text-green-800 font-bold underline hover:text-green-900">CLICK HERE</a> and follow the process.
        </p>
      </Modal>

      {/* IS NOT VENDOR PARTNERSHIP MODAL */}
      <Modal
        open={partnerModalOpen }
        footer={false}
        onCancel={handlePartnerCancel}
        className="partnerModal"
      >
        <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">
          Welcome to Konnect Food
        </h2>
        <ul className="list-disc pl-5 space-y-2 mt-2">
        <li className="marker:text-[red]">Note, only Food vendors can apply to become a restaurant. </li>
        <li className="marker:text-[#44843F]">Interested? Create a food vendor account and follow the steps.</li>
        </ul>
        <button onClick={() => navigate('/signup')} className="rounded-button flex mx-auto my-3 focus:outline-none ">
          Sign Up Today!
       </button>
      </Modal>

      {/* EXPERIENCE APP MODAL */}
      <Modal
        open={appModalOpen}
        footer={false}
        onCancel={handleAppCancel}
        className="appModal"
      >
        <h2 className="text-[#3b823a] text-5xl my-4" >Eat Cheaper on Konnect </h2>
        <div>
          <p>
            <span role="img" aria-label="cart">🛒</span> 10% discount on 1st 3 orders
          </p>
          <p>
            <span role="img" aria-label="thumbs-up">⭐</span> 30% discount on 4th order <span className="font-bold">(App only)</span>
          </p>
          <p>
            <span role="img" aria-label="thumbs-up">✨</span>Discounts for 1st to 4th orders are repeatedly within set duration
          </p>
          <p>
            <span role="img" aria-label="gift">🎁</span> Sign-up today & get <span className="font-bold">₦ 900</span>extra discount <i>(7 days validity)</i> 
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <MobileDownloadStores  />
        </div>
      </Modal>
    </div>
  );
};

export default HomePage;
