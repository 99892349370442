import React from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from "react-owl-carousel";
import Apple from '../../assets/images/apple_store.png';
import play from '../../assets/images/play_store.png';
import rice from '../../assets/images/homepage/rice_plate.png';

const RestaurantSlider = () => {
    const responsive = {
        0: {
            items: 1,
            nav: false,
            margin: 10,
            stagePadding: 20
        },
        600: {
            items: 1,
            nav: false,
            margin: 20,
            stagePadding: 20
        },
        1000: {
            items: 1,
            nav: false,
            margin: 10,
            stagePadding: 20,
        }
    }
    return (
        <div>
            <OwlCarousel className="owl-theme" lazyLoad={true} responsive={responsive} responsiveClass={true} loop margin={20} nav>
                <div className="restaurant_slide_1">
                    <div className='grid_flex slider_content'>
                        <div>
                            <h1>Restaurants</h1>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div className="restaurant_slide_2">
                    <div className='grid_flex slider_content'>
                        <div>
                            <h1>Sign Up, Download App & ₦1k cash to buy food</h1>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '50px' }}>
                                <a target="_blank" href="https://apps.apple.com/app/konnect-ws/id6504823567">
                                    <img style={{ height: "5rem", width: "auto" }} src={Apple} className="download" alt="download on apple store" />
                                </a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wnapp.id1695026422582">
                                    <img style={{ height: "5rem", width: "auto" }} src={play} className="download" alt="download on google play store" />
                                </a>
                            </div>
                        </div>
                        <div>
                            <img className='rice' src={rice} alt="plate of rice" />
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    )
}

export default RestaurantSlider