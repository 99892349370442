import '../assets/css/form.css';

import { LoadingOutlined } from "@ant-design/icons";
import { Input, Spin, notification } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { _change_password_email, _verify_email_code, _verify_passcode } from '../common/axios_services';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const SendMail = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');
    const [sendingMessage, setSendingMessage] = useState(false);

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({ message: '', placement: 'bottomRight', title, description: message, });
    };

    const { handleSubmit, control } = useForm({
        defaultValue: {
            emailAddress: "",
            passcode: ""
        },
    });

    const changePasswordEmail = async ({ email }) => {
        try {
            const change_pass_email = await _change_password_email({ email })
            setSendingMessage(false)
            navigate('/verify-passcode')
            openNotificationWithIcon('success', change_pass_email?.data?.title, change_pass_email?.data?.message)
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                setSendingMessage(false)
                setErrorMessage(err?.response?.data?.message)
            } else {
                openNotificationWithIcon("error", "Something went wrong", err?.message)
            }
        }
    }
    const verifyEmailCode = async ({ email, passcode }) => {
        try {
            const verify_email_code = await _verify_email_code({ email, passcode })
            if (verify_email_code?.data?.status === "success") {
                setSendingMessage(false)
                const konnectNewAccountCreated = localStorage.getItem('konnectnewaccountcreated');
                if (konnectNewAccountCreated) {
                    navigate('/signupsuccessful');
                } else {
                    navigate('/signin')
                }
                openNotificationWithIcon('success', verify_email_code?.data?.title, verify_email_code?.data?.message)
            } else {
                throw new Error("Email verification failed. Please try again.");
            }
        } catch (err) {
            setSendingMessage(false)
            if (err.response && err.response.data && err.response.data.message) {
                setErrorMessage(err?.response?.data?.message)
            } else {
                openNotificationWithIcon("error", "Something went wrong", err?.message)
            }
        }
    }
    const verifyPasscode = async ({ email, passcode }) => {
        try {
            const verify_email_code = await _verify_passcode({ email, passcode, type: "periodic" })
            if (verify_email_code?.data?.status === "success") {
                setSendingMessage(false)
                navigate(`/auth/reset-password/${verify_email_code.data.data.id}/${verify_email_code.data.data.token}`);
                openNotificationWithIcon('success', verify_email_code?.data?.title, verify_email_code?.data?.message)
            } else {
                throw new Error("Pass-code verification failed. Please try again.");
            }
        } catch (err) {
            setSendingMessage(false)
            if (err.response && err.response.data && err.response.data.message) {
                setErrorMessage(err?.response?.data?.message)
            } else {
                openNotificationWithIcon("error", "Something went wrong", err?.message)
            }
        }
    }

    const submitMe = e => {
        setSendingMessage(true);
        let { emailAddress, passcode } = e
        if (location?.pathname === "/verify-email") {
            verifyEmailCode({ email: emailAddress.replace(/\s/g, ""), passcode: passcode.replace(/\s/g, "") })
        } else if (location?.pathname === "/verify-passcode") {
            verifyPasscode({ email: emailAddress.replace(/\s/g, ""), passcode: passcode.replace(/\s/g, "") })
        } else {
            changePasswordEmail({ email: emailAddress.replace(/\s/g, "") })
        }
    }

    return (
        <div className="form-pages">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form auth">
                            <div className="form-text">
                                <h3 className="noSpace">{location?.pathname === "/verify-email" ? "Verify email by passcode" : location?.pathname === "/verify-passcode" ? "Verify passcode" : "Amend your password!"}</h3>
                                <p>{location?.pathname === "/verify-email" ? "Enter your email address to verify your email. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop." : location?.pathname === "/verify-passcode" ? "" : "Enter your email address to change your password. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop."}
                                </p>
                                {errorMessage ?
                                    <p className="error-message">{errorMessage}</p> : ''
                                }
                            </div>
                            <form onSubmit={handleSubmit(submitMe)}>
                                <div className="form-group">
                                    <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                    <Controller control={control} defaultValue="" name="emailAddress" render={({ field }) => (
                                        <Input {...field} id="emailAddress" style={{ width: '100%', height: '5rem' }} type="email" />
                                    )
                                    } />
                                </div>
                                {((location?.pathname === "/verify-email") || (location?.pathname === "/verify-passcode")) && (
                                    <div className="form-group">
                                        <label htmlFor="passcode" style={{ width: '100%' }}>Passcode</label>
                                        <Controller control={control} defaultValue="" name="passcode"
                                            render={({ field }) => (
                                                <Input {...field} id="passcode" style={{ width: '100%', height: '5rem' }} />
                                            )
                                            } />
                                    </div>
                                )}
                                <div style={{ marginTop: '5%' }}></div>
                                {!sendingMessage ? (
                                    <button id="submit-form" className="bg_green" style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">{location?.pathname === "/verify-email" ? "Verify Email" : location?.pathname === "/verify-passcode" ? "Verify passcode" : "Reset Password"}</button>
                                ) : (
                                    <button className="bg_green" disabled loading style={{ width: '100%', height: '5rem', borderRadius: '3px' }}><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                )}
                            </form>
                            <div style={{ marginBottom: '20px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg_grey">
                    <div className="container form_design_pages">
                        <div className="div_bg_white">
                            <div className="form aut">
                                <div className="form-text">
                                    <h3 className="noSpace">{location?.pathname === "/verify-email" ? "Verify email by passcode" : location?.pathname === "/verify-passcode" ? "Verify passcode" : "Amend your password!"}</h3>
                                    <p>{location?.pathname === "/verify-email" ? "Enter your email address to verify your email. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop." : location?.pathname === "/verify-passcode" ? "" : "Enter your email address to change your password. Get richer each day with Wise9ja. Enjoy free delivery and cashback when you shop."}
                                    </p>
                                    {errorMessage ?
                                        <p className="error-message">{errorMessage}</p> : ''
                                    }
                                </div>
                                <form onSubmit={handleSubmit(submitMe)}>
                                    <div className="form-group">
                                        <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                        <Controller control={control} defaultValue="" name="emailAddress"
                                            render={({ field }) => (
                                                <Input {...field} id="emailAddress" style={{ width: '100%', height: '5rem' }} type="email" />
                                            )
                                            } />
                                    </div>
                                    {((location?.pathname === "/verify-email") || (location?.pathname === "/verify-passcode")) && (
                                        <div className="form-group">
                                            <label htmlFor="passcode" style={{ width: '100%' }}>Passcode</label>
                                            <Controller control={control} defaultValue="" name="passcode"
                                                render={({ field }) => (
                                                    <Input {...field} id="passcode" style={{ width: '100%', height: '5rem' }} />
                                                )
                                                } />
                                        </div>
                                    )}
                                    <div style={{ marginTop: '5%' }}></div>
                                    {!sendingMessage ? (
                                        <button id="submit-form" className="bg_green" style={{ width: '100%', height: '5rem', borderRadius: '3px' }} type="submit">{location?.pathname === "/verify-email" ? "Verify Email" : location?.pathname === "/verify-passcode" ? "Verify passcode" : "Reset Password"}</button>
                                    ) : (
                                        <button className="bg_green" disabled loading style={{ width: '100%', height: '5rem', borderRadius: '3px' }}><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                    )}
                                </form>
                                <div style={{ marginTop: '4%' }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SendMail;