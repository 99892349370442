import '../assets/css/landing.css';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Link } from 'react-router-dom';
import SideImage from '../assets/images/landing/5.png';
import HowItWorks from '../components/HowItWorks';

const VendorLanding = () => {
    const refer_vendor = {
        title: "Tell other food vendors & earn NGN 100K monthly",
        description: "Refer 10 food vendors using your referral code and you get up to NGN 100,000 monthly whenever they order in bulk. Your income is paid into your wallet",
        lists: []
    }
    return (
        <>
            <Navbar disapear={true} />
            <div className='landing_hero' style={{ width: "100%", backgroundColor: "#f1fef4", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0 2%" }} className='grid_flex'>
                    <div>
                        <h3 style={{ color: "rgba(0,0,0,.85)", fontSize: "5rem", fontFamily: "Gilroy Bold", marginBottom: "2rem" }}><span style={{ fontSize: "6rem" }}>3</span> REASONS WHY FOOD VENDORS LOVES KONNECT!!!</h3>
                    </div>
                    <div style={{ display: "block" }}>
                        <img className='sideImg' src={SideImage} alt="" style={{ margin: "0 auto", display: "block" }} />
                    </div>
                </div>
            </div>
            {/* <div style={{ width: "100%", overflow: "hidden" }} >
                <div style={{ padding: "50px" }} className='grid_card'>
                    <div className='card_info'>
                        <h4>How we help food vendors to grow their business</h4>
                        <div className='list_container'>
                            <ul> 
                                <li>Order in bulk and enjoy free delivery (Lagos & environs)</li>
                                <li>We support you with instalment payments at low interest</li>
                                <li>Get up to NGN 200,000 when you refer other food vendors</li>
                                <li>We can fund your customer with up to NGN 100,000 monthly</li>
                                <li>Our team will help you to turnaround your free capital to grow your business</li>
                            </ul>
                        </div>
                        <Link className='joinus_button' to="/signup">Sign-Up</Link>
                    </div>
                </div>
            </div> */}
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className=''>We sell at wholesale prices & deliver for free</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>You can buy and pay monthly or when we deliver</h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Tell other food vendors & earn NGN 100K monthly <span style={{ fontWeight: 'normal' }}><HowItWorks style={{ fontWeight: "normal" }} data={refer_vendor} /></span></h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Click below to create a free account</h4>
                        <Link className='joinus_button' style={{ margin: "auto" }} to="/signup">Click Here</Link>
                    </div>
                </div>
            </div>
            <Footer noSpace={true} />
        </>
    )
}

export default VendorLanding;